import React, { Fragment, useState } from 'react';

const HeightItem = ({
  height,
  ...props
}) => {
    return <Fragment>{height && (
      <li><span>Høyde</span> {height} cm</li>
    )}</Fragment>
}

export default HeightItem;
