import { gql } from "apollo-boost";

const GET_SCHEDULE_PLAN = gql`
query Assistant($year: String, $planType: String) {
	request {
		grassistant {
			assistantCourseSchedule(year: $year, planType: $planType) {
				courseScheduleId
				year
				meetingCount(planType: $planType)
				data
			}
		}
	}
}
`

const GET_SCHEDULE_PLAN_2 = gql`
query Assistant($year: String, $planType: String) {
	request {
		grassistant {
			assistantCourseSchedule(year: $year, planType: $planType) {
				courseScheduleId
				year
				meetingCount: meetingCount2(planType: $planType)
				data
			}
		}
	}
}
`

export {
	GET_SCHEDULE_PLAN,
	GET_SCHEDULE_PLAN_2
};
