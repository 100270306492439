import { gql } from "apollo-boost";

const LIST_ALTERNATIVE_COURSES = gql`
query Assistant($userId: Int, $status: String) {
	request {
		grassistant {
			assistantGetUser: assistantGetUser2(userId: $userId, status: $status) {
				listAlternativeCourses {
					edges {
						node {
							courseId
							name
							autoName
							meetingWeekday
							meetingStartTime
							meetingEndTime
							courseTypeName
							instructorName
						}
					}
				}
			}
		}
	}
}
`

export default LIST_ALTERNATIVE_COURSES;
