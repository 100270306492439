import React, { Fragment, useState, useEffect } from 'react'
import AutocompleteParticipants from '../../../atom/AutocompleteParticipants';



const SmsForm = ({
  close,
  ...props
}) => {
    const contentEditable = React.useRef()
    const {
      emailTags,
      handleEmailTags,
      smsBody,
      handleSmsBody,
      currentLength,
      totalLength,
      noMessages,
      totalMessages,
    } = props





    return (<div className="ModalBody">
      <div className="ModalBody">
        <div className="item-container">
          <AutocompleteParticipants
            emailTags={emailTags}
            handleEmailTags={handleEmailTags}
            type={props.type}
          />
        </div>
        <div className="item-container message-container">
          <div className="SmsCountContainer">
            <div className="SmsCountWrapper">
              <div className="SmsCountItem">
                <span>Tegn:</span> <span>{currentLength}</span> / <span>{totalLength}</span>
              </div>
              <div className="SmsCountItem">
                <span>Meldinger:</span> <span>{noMessages}</span> / <span>{totalMessages}</span>
              </div>
            </div>
          </div>
          <textarea className="messageText" value={smsBody} onChange={(e) => handleSmsBody(e.target.value)} placeholder={"Din sms-tekst"}/>
        </div>
      </div>
    </div>)
}

export default SmsForm;
