import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'
var _ = require('underscore');
import NotFound from '../atom/NotFound';

import Loading from '../atom/Loading';
import FileItem from '../atom/FileItem';


import ASSISTANT_FILE_MANAGER from '../query/assistant_file_manager.js';
import { ToastContainer, toast } from 'react-toastify';

const Breadcrumbs = ({
    courseId,
    courseName
}) => (
    <div className="headWrapper">
        <div className="headText">
            Filer for kursdeltakere
        </div>
        <nav className="breadcrumbs">
            <ul>
                <li><Link to="/">Hovedside</Link></li>
                <li><Link to={`/filer-for-kursdeltakere`}>Filer for kursdeltakere</Link></li>
            </ul>
        </nav>
    </div>
)

const FileManager = ({
	match,
	...props
}) => {
	const {
		loading,
        data: {
            request: {
                grassistant: {
                    assistantFileManager: {
                        edges: files = []
                    } = {}
                } = {}
            } = {}
        } = {}
	} = useQuery(ASSISTANT_FILE_MANAGER, {
		fetchPolicy: "cache-and-network"
    });


    if (!loading && files.length <= 0) {
        return <div className="pageContainer participantsContainer">
          <div className="participantsBody userCard">
            <NotFound
              children="Deltaker ikke funnet"
            />
          </div>
        </div>
    }

    return <div className="file-manager-container page-container">
        <Breadcrumbs/>
        <ToastContainer/>
        <div className="filename-details-container">
            <div className="table_head">
                <div className="file-item-container">
                    <div className="grid-item th_item">Filename</div>
                    <div className="grid-item th_item item-center">Download</div>
                    <div className="grid-item th_item item-center">Kopier</div>
                </div>
            </div>
            {
                loading ? <Loading/> : <div className="table_body">
                    {
                        files.map((obj, index) => 
                            <FileItem key={index} obj={obj.node} toast={toast}/>
                        )
                    }
                </div>
            }


        </div>
        
    </div>
}

export default FileManager;
