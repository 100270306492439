import React, { Fragment, useState } from 'react';
import {activityLevels} from '../../utils/Constants'

const ActivityLevel = ({activityLevel, ...props}) => {
    return <Fragment>
      {activityLevel && (
        <li><span>Hverdagsaktivitet</span> {activityLevels[activityLevel]}</li>
      )}
    </Fragment>
}

export default ActivityLevel;
