import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment'
import { Link } from 'react-router-dom';
var _ = require('underscore');

import Loading from '../../atom/Loading';

import GET_COURSE_PARTICIPANTS from '../../query/get_course_participants.js';
import GET_COURSE_PARTICIPANTS_OLD from '../../query/get_course_participants_old.js';

const CourseParticipants = ({
  page,
  url,
  selectItem,
  selectAll,
  userList=[],
  ...props
}) => {

  const {
		loading,
		data: {
      request: {
        grassistant:{
          assistantCourseParticipants: {
            edges = []
          }={}
        }={}
      }={}
    } = {}
	} = useQuery(page === 'kurs' ? GET_COURSE_PARTICIPANTS_OLD : GET_COURSE_PARTICIPANTS , {
    variables: {courseId: props.courseId}
  });

  const [sortNameHead, setSrotName] = useState ('asc');
  const [sortStart, setSrotStart] = useState ('asc');

  const sortByName = () => {
    setSrotName(sortNameHead == 'asc' ? 'desc' : 'asc');
    setSrotStart(false);
  }

  const  sortByDate = () => {
    setSrotName(false);
    setSrotStart(sortStart == 'asc' ? 'desc' : 'asc');
  }

  if(loading) return <Loading/>

  var result = edges;

  if(sortNameHead) {
    result = _.sortBy(result, function(obj) {
      return obj.node.name
    })

    if(sortNameHead === 'desc') result = result.reverse()
  }

  if(sortStart) {
    result = _.sortBy(result, function(obj) {
      return obj.node.userStartDate
    })

    if(sortStart === 'desc') result = result.reverse()
  }

  return <div className="cp-list-container">
    <div className="cp-headers">
      <div className="cp-header-item">
        <div className="cp-text">Kursdeltakere</div>
        <div className="cp-action-item" onClick={() => props.closeParticipants()}>Lukk</div>
      </div>
    </div>
    <div className="UserListContainer">
      <div className="participantsGrid">
        <div className="headItem firstCol">
          <input type="checkbox" name="course_type[]" className="userCheckbox regularCheckbox" onClick={(e) => selectAll(e, result)}/>
        </div>
        <div className={`headItem headArrow ${sortNameHead}`} onClick={sortByName}>Navn</div>
        <div className={`headItem headArrow ${sortStart}`} onClick={sortByDate}>Start/Stop</div>
        <div className={`headItem headArrow`} onClick={sortByDate}>Variant</div>
        <div className={"headItem headArrow"}>Status</div>
      </div>
      {result.length > 0 ? result.map((obj, index)=>
          <div className="participantsGrid" key={index}>

            <div className="bodyItem firstCol textItem">
              <input
                type="checkbox"
                name="course_type[]"
                className="userCheckbox regularCheckbox"
                onClick={(e) => selectItem(e, obj.node)}
                data-id={obj.node.userId}
                data-name={obj.node.name}
                data-email={obj.node.email}
                data-email-allowed={obj.node.emailAllowed}
                data-sms-allowed={obj.node.smsAllowed}
                data-phone={obj.node.cellPhone}
                data-is-active={obj.node.isActive}
                checked={userList.filter((x) => (x.userId == obj.node.userId)).length > 0}
                onChange={() => function() {}}
              />
            </div>
            <Link to={`/${url}/${obj.node.userId}`} className="bodyItem nameCol textItem">
              {obj.node.name}
            </Link>
            <Link to={`/${url}/${obj.node.userId}`} className="bodyItem dateCol textItem">
              {moment(obj.node.userStartDate).format("DD.MM.YY")} / {obj.node.userEndDate ? moment(obj.node.userEndDate).format('DD.MM.YY') : '-'}
            </Link>
            <Link to={`/${url}/${obj.node.userId}`} className="bodyItem textItem subscriptionCol">
              {obj.node.paymentSourceHandler}
            </Link>
            <Link to={`/${url}/${obj.node.userId}`} className={`bodyItem discountCol textItem ${obj.node.renewalStatus}`}>
              {obj.node.statusText}
            </Link>
          </div>

      ) : <div className="emptyResultSet">Ikke deltakere</div>}
    </div>
  </div>
}

export default CourseParticipants
