import React, { Fragment, useState } from 'react';

import EmailButton from '../EmailButton';
import SmsButton from '../SmsButton';
import PauseButton from '../PauseButton';
import MoveUserButton from '../MoveUserButton';
import SkipMeeting from '../SkipMeeting';
import SubstituteInstructor from '../SubstituteInstructor'

const ActionFooter = ({
  userList=[],
  showEmail=false,
  showSms=false,
  showPause=false,
  showMoveUser=false,
  showMoveMeeting=false,
  additionalClass,
  ...props
}) => {
    return <div className={`action-footer-container ${additionalClass}`}>
      <div className="action-footer-placeholder">
        {showEmail && <EmailButton
            userList={userList}
        />}
        {showSms && <SmsButton
            userList={userList}
        />}
        {
          // showPause && <PauseButton
          //   userList={userList}
          // />
        }
        {
          // showMoveUser && <MoveUserButton
          //   userList={userList}
          // />
        }
        {
          showMoveMeeting && <SkipMeeting {...props}/>
        }
        {
          showMoveMeeting && <SubstituteInstructor {...props}/>
        }



      </div>
    </div>
}

export default ActionFooter;
