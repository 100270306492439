import React from 'react';
import Table from 'react-bootstrap/Table'
import NettcourseParticipant from '../../atom/NettcourseParticipant'
import Form from 'react-bootstrap/Form'

const NettcoursTable = ({
  users,
  total,
  page,
  limit,
  selectUser,
  selectAll,
  userList,
  weekday,
  ...props
}) => {

    return <div className="nettcours-table-container">
      <div>Total: {total}</div>
      <Table striped bordered hover responsive="xl" className="nettcourse_table">
        <thead>
          <tr>
            <th className="align_center">
              <Form.Check
                type="checkbox"
                onClick={(e)=>selectAll(e)}
              />
            </th>
            <th>Navn</th>
            <th>Kurs</th>
            <th>Ukedag</th>
            <th>Status</th>
            <th>E-post</th>
          </tr>
        </thead>
        <tbody>
          {users.map((item, key) => (
            <NettcourseParticipant
              key={key}
              index={key+(page*limit)}
              user={item}
              selectUser={selectUser}
              userList={userList}
              weekday={weekday}
            />
          ))}
        </tbody>
      </Table>
    </div>
}

export default NettcoursTable;
