import React, { Fragment, useState } from 'react';
import moment from 'moment'
import { Link } from 'react-router-dom'

const DateNav = ({ page, match }) => {
  const today = moment()
  const currentDate = moment(match.params.date)
  const prevDay = currentDate.clone().add(-1, 'days')
  const nextDay = currentDate.clone().add(1, 'days')
  const prevDayDisabled = today.diff(prevDay, 'days') > 5
  const nextDayDisabled = nextDay.diff(today, 'days') > 4

  return (
    <nav className="wb-dateNav">
      <Link to={`/${page}/${prevDay.format('YYYY-MM-DD')}/`} className="prev" data-disabled={prevDayDisabled}>
        <i className="icon-arrow-2-left" />
      </Link>
      {currentDate.format('dddd. DD, MMM')}
      <Link to={`/${page}/${nextDay.format('YYYY-MM-DD')}/`} className="next" data-disabled={nextDayDisabled}>
        <i className="icon-arrow-2-right" />
      </Link>
    </nav>
  )
}

export default DateNav;
