import React, {useState, Fragment} from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import moment from 'moment'
var _ = require('underscore');

import Loading from '../atom/Loading';
import SmsButton from '../atom/SmsButton';
import NotFound from '../atom/NotFound';
import Modal from '../ui/Modal';

import GET_COURSE_MEETINGS_BY_ID from '../query/get_course_meetings_by_id.js';


const Breadcrumbs = ({ courseId }) => (
  <div className="headWrapper">
      <div className="headText">
          Møtedetaljer
      </div>
      <nav className="breadcrumbs">
        <ul>
          <li><Link to="/">Hovedside</Link></li>
          <li><Link to={`/course-calendar/`}>Kalendar</Link></li>
          <li><Link to={`/course-details/${courseId}`}>Kurs</Link></li>
          <li><Link to={`/course-details/${courseId}/course-meeting/`}>Kursmøte</Link></li>
          <li>Møtedetaljer</li>
        </ul>
      </nav>
  </div>
)


const CourseMeetingDetails = ({
  ...props
}) => {

  const  {
    params: {
      id: courseId,
      meetingId
    } = {}
  } =  props.match

  const [users, addUsers] = useState([]);
  const arr = ['stripe'];

  const {
    loading,
    refetch,
    data: {
      request: {
          grassistant: {
            assistantCourseMeeting: {
              edges=[]
            }={}
          }={}
      }={}
    }={}
  } = useQuery(GET_COURSE_MEETINGS_BY_ID, {
      variables: { courseId: courseId, meetingId: meetingId},
  });


  const {
    node: {
      course={},
      endTime,
      startTime,
      instructor={},
      courseMeetingId,
      attendanceList=[]
    }={}
  } = edges.length > 0 && edges[0];

  const selectUser = (e, obj) => {

    const data = {
      "userId": obj.userId,
      "id": obj.id,
      "phone": obj.phone,
      "name": obj.name,
      "sms_allowed": true,
      "membershipId": obj.membershipId,
      "status": obj.status,
      "skip_allowed": true //arr.indexOf(obj?.membership?.paymentSource) > -1,
    }

    if(users.filter((x) => (x.userId == data.userId)).length > 0) {
      addUsers(users.filter((x) => (x.userId != data.userId)))
    } else {
      addUsers([...users, data])
    }
  };

  const selectAll = (e, res) => {
    if(e.target.checked) {
      addUsers(_.map(res, function(obj) { return {
        "userId": obj.userId,
        "id": obj.id,
        "phone": obj.phone,
        "name": obj.name,
        "sms_allowed": true,
        "membershipId": obj.membershipId,
        "status": obj.status,
        "skip_allowed": true //arr.indexOf(obj?.membership?.paymentSource) > -1,
      }}))
    } else {
      addUsers([]);
    }
  };



  return <div className="course-meeting-container">
    <Breadcrumbs
      courseId={courseId}
    />

    {loading && <Loading/>}
    {!loading && (edges.length > 0 ? <Fragment>
        <div className="meetingBody">
          <div className="title">{course.name} {moment(startTime).format("YYYY-MM-D")} ({moment(startTime).format("HH:mm")}-{moment(endTime).format("HH:mm")})</div>
          <div className="instructorDataContainer">
            <strong>Today's course leader is:</strong> <span>{instructor.name}</span>
          </div>

          <div className="meeting_actions">
            <div className="buttonContainer">
              <div className="buttonWrapper">
                {
                  users.length > 0 ? <SmsButton
                    userList={users}
                    children={
                      <div className="buttonItem">Send sms</div>
                    }
                  />: <div className="buttonItem">Send sms</div>
                }

                <Modal
                  type={"course_meeting"}
                  from={"course_meeting"}
                  courseMeetingId={courseMeetingId}
                  startTime={startTime}
                  substituteInstructorId={instructor.instructorId}
                  substituteInstructor={instructor.name}
                  callback={refetch}
                  modalHeader="Change date for course meeting"
                >
                  <div className="buttonItem">Change meeting date</div>
                </Modal>

                {
                  moment().isSameOrBefore(moment(startTime)) && <Modal
                    users={_.filter(users, function(obj){
                      return obj.status !== 'skipped'
                    })}
                    courseMeetingId={courseMeetingId}

                    type={'skip_meeting'}
                    from={'skip_meeting'}
                    callback={refetch}
                    modalHeader={"Skip meeting"}
                  >
                    <div className="buttonItem">Skip meeting</div>
                  </Modal>
                }
              </div>
            </div>
          </div>

          <div className="attendanceList">
            <div className="gridContainer header">
              <div className="gridItem box1">
                <input type="checkbox" className="userCheckbox regularCheckbox" onClick={(e) => selectAll(e, attendanceList)}/>
              </div>
              <div className="gridItem box2">Name</div>
              <div className="gridItem box3">Status</div>
              <div className="gridItem box4">Skip allowed</div>
            </div>

            {
              attendanceList.map((obj, index) => (
                <div className="gridContainer body" key={index}>
                  <div className="gridItem box1">
                    <input
                      type="checkbox"
                      className="userCheckbox regularCheckbox listCheckbox"
                      onClick={(e) => selectUser(e, obj)}
                      checked={users.filter((x) => (x.userId == obj.userId)).length > 0}
                      onChange={() => function() {}}
                    />
                  </div>
                  <div className="gridItem box2">
                    {obj.name}
                  </div>

                  <div className="gridItem box3">
                    {obj.status}
                  </div>
                  <div className="gridItem box4">
                    {true || arr.indexOf(obj?.membership?.paymentSource) > -1 ? <i className="icon-check-circle"></i> : <i className="icon-cancel-circle"></i>}
                  </div>
                </div>
              ))
            }

          </div>
        </div>

      </Fragment> : <Fragment>
        <NotFound children="Ikke funnet"/>
      </Fragment>)}
  </div>



}

export default CourseMeetingDetails;
