import React, { Fragment, useState } from 'react';
import {parseJsonListToString} from '../../utils/Constants'

const AllergiesDetails = ({data, ...props}) => {
    const allergies = parseJsonListToString(data, 'allergiesMap')

    return <Fragment>
      {allergies && (
        <li><span>Matallergier</span> {allergies}</li>
      )}
    </Fragment>
}

export default AllergiesDetails;
