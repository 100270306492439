import React, { Fragment, useState } from 'react';
import moment from 'moment'

const ContractTo = ({contractTo, ...props}) => {
    return <Fragment> {contractTo && (
        <li><span>Membership contract to:</span> {new moment(contractTo).format('YYYY-MM-DD')}</li>
    )} </Fragment>
}

export default ContractTo;
