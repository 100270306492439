import React, { Fragment, useState } from 'react';

import Modal from '../../ui/Modal';

const SkipMeeting = ({meetingsList, ...props}) => {
    return <div className="button-item  email-button-container">
      <Modal
        courseMeetingId={meetingsList}
        type={'skip_meeting_bulk'}
        from={'skip_meeting_bulk'}
        modalHeader={"Skip meeting bulk"}
        {...props}
      >
        <div className="buttonWrapper">
          <i className="buttonIcon icon-close"></i>
          <div className="buttonText">Skip meetings</div>
        </div>
      </Modal>

    </div>
}

export default SkipMeeting;
