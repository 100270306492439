import React, { useEffect } from "react";
import moment from "moment";

export default function NextReportWeek(props) {
  const { loading, report, nextReportWeek, setNextReportWeek } = props;
  const week = nextReportWeek || report.nextReport.week;
  const currentWeek = moment(report.week).add(1, "week");
  const nextWeek = moment(week);
  const prevWeekDisabled = currentWeek.isSame(nextWeek);

  function setWeek(offset) {
    const date = moment(week).add(offset, "week").format("YYYY-MM-DD");
    setNextReportWeek(date);
  }

  return (
    <div className="next-report-week" data-loading={loading}>
      <h4>Medlems neste leveringsfrist:</h4>
      <div>
        <button
          type="button"
          disabled={prevWeekDisabled}
          onClick={() => setWeek(-1)}
        >
          &larr;
        </button>
        <span>{moment(week).format("DD.MM.YYYY")}</span>
        <button type="button" onClick={() => setWeek(1)}>
          &rarr;
        </button>
      </div>
    </div>
  );
}
