import { gql } from "apollo-boost";

const WEIGHT_DRILLDOWN_COURSE_NEW = gql`
	query Assistant(
		$courseId: Int
		$cacheDate: Date
		$category: String
		$currentDateTimeFrom: DateTime
		$currentDateTimeTo: DateTime
		$end: String
	) {
		assistant {
			assistantCourseItem: assistantGetRoedeCourse(courseId: $courseId) {
				courseId
				bookCode: autoName
				memberTotal
			}
			assistantUserList: assistantUserList2(
				courseId: $courseId
				first: 10
				after: $end
			) {
				pageInfo {
					hasNextPage
					endCursor
				}
				edges {
					node {
						userId
						name
						kcalMenu
						courseLocation
						summaryMeasurementsCached(dateTo: $currentDateTimeTo)
						weeklyDiaryCache(date: $cacheDate) {
							data
						}
						lastRegisteredWeights: diaryRegistration(category: $category, dateFrom: $currentDateTimeFrom, dateTo: $currentDateTimeTo, orderByDate: "desc") {
							edges {
								node {
									registrationId
									dateTime
									data
								}
							}
						}
					}
				}
			}
		}
	}
`

export default WEIGHT_DRILLDOWN_COURSE_NEW;
