import React, { useState } from 'react'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import Loading from '../atom/Loading'
import NotFound from '../atom/NotFound'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const weekDays = {
  1: 'Mandag',
  2: 'Tirsdag',
  3: 'Onsdag',
  4: 'Torsdag',
  5: 'Fredag',
  6: 'Lørdag',
  7: 'Søndag',
}

const GET_NETTCOURSE = gql`
  query Assistant($nettcourseInstructorId: Int!) {
    request {
      grassistant {
        assistantNettcourseInstructor(nettcourseInstructorId: $nettcourseInstructorId)
      }
    }
  }
`

const UPDATE_NUMBER_OF_SPOTS = gql`
  mutation Assistant(
    $nettcourseInstructorId: Int!
    $numberOfSpots: JSONString!
  ) {
    assistant {
      assistantUpdateNumberOfSpots(
        nettcourseInstructorId: $nettcourseInstructorId
        numberOfSpots: $numberOfSpots
      ) {
        status
        message
      }
    }
  }
`

function copyToClipboard(event) {
  event.stopPropagation()
  event.preventDefault()

  const text = event.target.parentElement.getAttribute('href')

  if (!navigator.clipboard) {
    console.error('Clipboard API not available')
    return
  }

  navigator.clipboard.writeText(text)
  toast('Kopiert')
}

function Breadcrumbs({ nettcourseInstructorId }) {
  return (
    <div className="headWrapper">
      <div className="headText">Nettkurs</div>
      <nav className="breadcrumbs">
        <ul>
          <li><Link to="/">Hovedside</Link></li>
          <li><Link to={`/course-calendar/`}>Kalendar</Link></li>
          <li><Link to={`/nettcourse-details/${nettcourseInstructorId}/`}>Nettkurs</Link></li>
        </ul>
      </nav>
    </div>
  )
}

function NumberOfSpots({ nettcourseInstructorId, numOfSpots, weekDays, updateNumOfSpots, loading }) {
  const defaultNumOfSpots = Object.keys(numOfSpots).reduce((result, value) => {
    result[`day${value}`] = parseInt(numOfSpots[value], 10)
    return result
  }, {
    'day1': 0,
    'day2': 0,
    'day3': 0,
    'day4': 0,
    'day5': 0,
    'day6': 0,
    'day7': 0,
  })

  const [inputs, setInputs] = useState(defaultNumOfSpots)

  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs(state => ({ ...state, [name]: parseInt(value, 10) }))
  }

  const handleSubmit = () => {
    const numberOfSpots = JSON.stringify(inputs).replace(/day/g, '')
    const variables = { nettcourseInstructorId, numberOfSpots }
    updateNumOfSpots({ variables })
  }

  return (
    <tr className="inputs" data-loading={loading}>
      <td>
        <button type="button" onClick={handleSubmit}>Lagre</button>
      </td>
      {Object.keys(weekDays).map((day) => (
        <td key={`spots-${day}`} align="center">
          <input type="number" name={`day${day}`} onChange={handleChange} value={inputs[`day${day}`]} min="0" max="20" />
        </td>
      ))}
    </tr>
  )
}

export default function RoedeNettcourseDetails({ match }) {
  const variables = { nettcourseInstructorId: match.params.id }
  const { loading, data } = useQuery(GET_NETTCOURSE, { variables })
  const [updateNumOfSpots, result] = useMutation(UPDATE_NUMBER_OF_SPOTS)
  const nettcourseInstructor = JSON.parse(data?.request?.grassistant?.assistantNettcourseInstructor || null)
  const { number_of_spots, participants } = nettcourseInstructor || {}

  if (loading) {
    return (
      <div className="course-container">
        <Breadcrumbs nettcourseInstructorId={match.params.id} />
        <Loading />
      </div>
    )
  }

  if (!participants) {
    return (
      <div className="course-container">
        <Breadcrumbs nettcourseInstructorId={match.params.id} />
        <NotFound>Nettcourse not found.</NotFound>
      </div>
    )
  }

  return (
    <div className="course-container">
      <ToastContainer autoClose={2500} hideProgressBar />
      <Breadcrumbs nettcourseInstructorId={match.params.id} />
      <table className="tbl">
        <thead>
          <tr>
            <th>&nbsp;</th>
            {Object.values(weekDays).map((weekDay) => (
              <th key={weekDay}>{weekDay}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="nowrap">Fase 1</td>
            {Object.keys(weekDays).map((day) => (
              <td key={`fase1-${day}`} align="center">
                <a href={`https://www.roede.com/nettkurs/${match.params.id}/${day}`} onClick={copyToClipboard}>
                  <i className="buttonIcon icon-copy-plain-text"></i>
                </a>
              </td>
            ))}
          </tr>
          <tr>
            <td className="nowrap">Fase 3</td>
            {Object.keys(weekDays).map((day) => (
              <td key={`fase3-${day}`} align="center">
                <a href={`https://www.roede.com/nettkurs/${match.params.id}/${day}/1`} onClick={copyToClipboard}>
                  <i className="buttonIcon icon-copy-plain-text"></i>
                </a>
              </td>
            ))}
          </tr>
          <NumberOfSpots
            nettcourseInstructorId={match.params.id}
            numOfSpots={number_of_spots}
            weekDays={weekDays}
            updateNumOfSpots={updateNumOfSpots}
            loading={result.loading} />
        </tbody>
        <tfoot>
          <tr>
            <td>&nbsp;</td>
            {Object.keys(weekDays).map((weekDay) => (
              <td key={weekDay}>
                <ul>
                {participants[weekDay].map((participant) => (
                  <li key={`${participant.membershipId}.${participant.userId}`}>
                    <Link to={`/nye-kursdeltakere/${participant.userId}`}>{participant.name}</Link>
                  </li>
                ))}
                </ul>
              </td>
            ))}
          </tr>
        </tfoot>
      </table>
    </div>
  )
}
