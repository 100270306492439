import React, { Fragment, useState } from 'react';
import WeightBookParticipant from '../../atom/WeightBookParticipant';
import _ from 'underscore';

const WeightBookGroupList = ({
  page,
  match,
  userList,
  callback,
  ...props
}) => {
    const [sort, setSort] = useState({
      name: 'asc',
      book_code: 'desc',
      this_weeks_weight: 'desc',
    })

    const sortKey = Object.keys(sort)[0]
    const sortDirection = sort[Object.keys(sort)[0]]

    const changeSort = (field) => {

      const sortBy = {
        [field]: null,
        ...sort,
        [field]: (sort[field] === 'asc') ? 'desc' : 'asc',
      }

      setSort(sortBy)
    }

    const sortData = () => {
      const ul = _.sortBy(userList, function (i) { return sortKey === 'this_weeks_weight' ? i.this_weeks_weight : eval(`i.${sortKey}.toLowerCase()`); })
      return sortDirection === 'asc' ? ul : ul.reverse();
    }

    return <Fragment>
      <div className="wbp-tr sortable">
        <div className="wbp-th asc">
          <span className="wbp-sort" data-sort={sort.name} data-active={Object.keys(sort)[0] === 'name'} onClick={() => changeSort('name')}>Navn</span>
        </div>
        <div className="wbp-th desc">
          <span className="wbp-sort" data-sort={sort.book_code} data-active={Object.keys(sort)[0] === 'book_code'} onClick={() => changeSort('book_code')}>Kurs</span>
        </div>
        <div className="wbp-th desc">
          <span className="wbp-sort" data-sort={sort.this_weeks_weight} data-active={Object.keys(sort)[0] === 'this_weeks_weight'} onClick={() => changeSort('this_weeks_weight')}>Registrert</span>
        </div>
      </div>
      <div className="wbp-tbiscrl">
        {
          sortData().map((obj, key) => (
            <WeightBookParticipant
              key={key}
              match={match}
              page={page}
              userId={obj.userId}
              userName={obj.name}
              book_code={obj.book_code}
              this_weeks_weight={obj.this_weeks_weight}
              courseList={obj.courseList}
              absence={obj.absence}
              comment={obj.comment}              
              callback={callback}
            />
          ))
        }
      </div>
    </Fragment>

}

export default WeightBookGroupList;
