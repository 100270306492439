import React, { Fragment, useState } from 'react';

import {
  formatAmount
} from '../../utils/GetCacheQuery'

const WeightStatsDetails = ({
  assistentGetStats,
  ...props
}) => {
    const stats = JSON.parse(assistentGetStats || '{}')

    return (<ul className="wbm-weights">
      <li>
        <span>Samlet vektnedgang fra forige veiing</span>
        <strong>{formatAmount(stats.weightDeltaWeek, 'kg')}</strong>
      </li>
      <li>
        <span>Samlet vektnedgang fra start dato</span>
        <strong>{formatAmount(stats.weightDeltaTotal, 'kg')}</strong>
      </li>
    </ul>)
}

export default WeightStatsDetails;
