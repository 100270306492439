import { gql } from "apollo-boost";

const GET_COURSE_CALENDAR = gql`
	query Assistant($haveUsers: Boolean, $courseType: [Int]) {
        request {
            grassistant {
                assistantCourseCalendar(haveUsers: $haveUsers, courseType: $courseType)
                assistantNettcourseCalendar
            }
        }
	}
`

export default GET_COURSE_CALENDAR;
