import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment'

const RoedeCourse = ({meeting}) => {
  const course = meeting.course
    return (
        <div className="roede-course-item">
          <Link to={`/course-details/${course.id}`}>
            <div className="roede-course-name">{course.autoNameShort}</div>
            <div className="roede-course-location">
              <div className="roede-course-time">{`${moment(meeting.startTime).format('HH:mm')}-${moment(meeting.endTime).format('HH:mm')}`}</div>
              <div className="roede-course-address">{course.address}</div>
              <div className="roede-course-free-spots">Deltakere: {meeting.attendanceCount}</div>
            </div>
          </Link>
        </div>
    )
}

export default RoedeCourse;
