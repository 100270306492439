import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment'

const DateNavDiaryDay = ({ date, userId, page }) => {
  const today = moment()
  const currentDate = moment(date)
  const prevDay = currentDate.clone().add(-1, 'days')
  const nextDay = currentDate.clone().add(1, 'days')

  return (
    <nav className="wb-dateNav">
      <Link to={`/${page}/${userId}/${prevDay.format('YYYY-MM-DD')}/`} className="prev">
        <i className="icon-arrow-2-left" />
      </Link>
      {currentDate.format('dddd. DD, MMM')}
      <Link to={`/${page}/${userId}/${nextDay.format('YYYY-MM-DD')}/`} className="next">
        <i className="icon-arrow-2-right" />
      </Link>
    </nav>
  )
}

export default DateNavDiaryDay;
