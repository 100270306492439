import React, { Fragment, useState } from 'react';

const InitialWeight = ({initialWeight, ...props}) => {
    return <Fragment>
      {initialWeight > 0 && (
        <li><span>Startvekt</span> {initialWeight} kg</li>
      )}
    </Fragment>
}

export default InitialWeight;
