import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'

const DiaryUserDetails = ({
  user,
  page,
  ...props
}) => {
    return <Link to={`/${page}/${user.id}`} className="diaryUser wbr-user">
      <img src={user.profilePic ? user.profilePic : "/public/no_image_user.png"} alt={user.fullName} />
      <h2>{user.fullName} <span>hq-{user.id}</span></h2>
      <p>Rabatter: {user.levelName}</p>
    </Link>
}

export default DiaryUserDetails;
