import React, { Fragment, useState } from 'react';

const TrainingMetabolismItem = ({trainingMetabolism, ...props}) => {
    return <Fragment>
      {trainingMetabolism > 0 && (
        <li><span>Daglig energibehov</span> {trainingMetabolism} kcal</li>
      )}
    </Fragment>
}

export default TrainingMetabolismItem;
