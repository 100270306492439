import { gql } from "apollo-boost";

const GET_WEIGHT_STAT2 = gql`
	query Assistant($userIdList: [Int], $courseId: Int, $dateTo: DateTime) {
		assistant {
			assistentGetStats: assistentGetStats2(userIdList:$userIdList, courseId: $courseId, dateTo: $dateTo)
		}
	}
`

export {
	GET_WEIGHT_STAT2
};
