import { gql } from "apollo-boost";

const ASSISTENT_INSTRUCTOR_SEARCH = gql`
query Assistant($search: String!) {
	request {
		grassistant {
			assistentInstructorSearch(search: $search) {
				edges {
					node {
						instructorId
						name
					}
				}
			}
		}
	}
}
`

export default ASSISTENT_INSTRUCTOR_SEARCH;
