import React, { Fragment, useState } from 'react';

const Loading = ({}) => {
    return (
        <div className="loading_container" key={0}>
            <div className="loading_wrapper">
                <div className="loading">
                    Loading 
                </div>
                <div className="dot dot_one"></div>
                <div className="dot dot_two"></div>
                <div className="dot dot_three"></div>
            </div>
        </div>
    )
}

export default Loading;
