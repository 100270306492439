import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment'

import Loading from '../../atom/Loading';
import CMMonth from './CMMonth'
var _ = require('underscore');

import { useQuery } from '@apollo/client';

import GET_COURSE_MEETINGS from '../../query/get_course_meetings.js';

const CourseMeetings = ({
  courseId,
  closeCourseMeetings,
  ...props
}) => {

  const [currentTime, setCurrentTime] = useState(moment());
  const dateFrom = currentTime.startOf("month").format("YYYY-MM-DD")
  const dateTo = currentTime.endOf("month").format("YYYY-MM-DD")
  const year = currentTime.year()

  const {
      loading,
      data: {
        request: {
          grassistant:{
            assistantCourseMeeting: {
              edges  = []
            }={}
          }={}
        }={}
      }={},
      refetch
  } = useQuery(GET_COURSE_MEETINGS, {
      variables: {
          courseId: courseId,
          dateFrom: dateFrom,
          dateTo: dateTo
      },
  });

  const showPrevious = () => {
    setCurrentTime(moment(currentTime).subtract(1, 'month'))

  }

  const showNext = () => {
    setCurrentTime(moment(currentTime).add(1, 'month'));
  }

  const showPreviousYear = () => {
    setCurrentTime(moment(currentTime).subtract(1, 'year'));
  }

  const showNextYear = () => {
    setCurrentTime(moment(currentTime).add(1, 'year'))

  }



  return (
    <div className="course-meetings">
      <div className="cm-headers">
        <div className="cm-header-item cmg-text">Kursmøter</div>
        <div className="cm-header-item close-course-meetings" onClick={() => closeCourseMeetings()}>Lukk</div>
      </div>

      <div className="year-container">
        <div className="cmp-container">
          <div className="cm-pagination-item left" onClick={() => showPreviousYear()}><i className="icon-arrow-2-left"></i></div>
          <div className="cm-pagination-item year">{year}</div>
          <div className="cm-pagination-item right" onClick={() => showNextYear()}><i className="icon-arrow-2-right"></i></div>
        </div>
      </div>

      <div className="week-map">
        <div className="week-map-item first">Every week</div>
        <div className="week-map-item second">Every other week</div>
        <div className="week-map-item third">Every four weeks</div>
      </div>
      <div className="cm-body">
        {loading && <Loading/>}
        {
          <CMMonth
            month={moment(dateFrom).format("MMMM")}
            year={moment(dateFrom).format("YYYY")}
            results={edges}
          />
        }
      </div>
      <div className="cm-pagination">
        <div className="cmp-grid">
          <div className="cmp-item left">
            <div className="cmp-container">
              <div className="cm-pagination-item" onClick={() => showPrevious()}>Prev</div>
              <div className="cm-pagination-item" onClick={() => showNext()}>Next</div>
            </div>
          </div>
          <div className="cmp-item right">
            <Link to={`/course-details/${courseId}/course-meeting/`}><div className="cm-pagination-item">Show all</div></Link>
          </div>
        </div>
      </div>
    </div>
  )

}

export default CourseMeetings
