import facegood from "!!file-loader!./images/face-good.png";
import facebad from "!!file-loader!./images/face-bad.png";
import glassempty from "!!file-loader!./images/glass-empty.png";
import glassfull from "!!file-loader!./images/glass-full.png";
import heartempty from "!!file-loader!./images/heart-empty.png";
import heartfull from "!!file-loader!./images/heart-full.png";
import vitaminempty from "!!file-loader!./images/vitamin-empty.png";
import vitaminfull from "!!file-loader!./images/vitamin-full.png";

const images = {
  facegood,
  facebad,
  glassempty,
  glassfull,
  heartempty,
  heartfull,
  vitaminempty,
  vitaminfull,
};

export default images;
