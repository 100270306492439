import { gql } from "apollo-boost";

const ASSISTENT_UPDATE_COMMENT = gql`
	mutation Assistant($userId: Int, $userComment: String) {
		assistant {
			assistentUpdateComment(userId: $userId, userComment: $userComment) {
				status
				message
			}
		}
	}
`

export default ASSISTENT_UPDATE_COMMENT;
