import React, { Fragment, useState } from 'react';

import UserName from '../../atom/UserName'
import UserActionButtons from '../../atom/UserActionButtons'
import UserCommentNew from '../../atom/UserCommentNew'
import HeightItem from '../../atom/HeightItem'
import GoalItem from '../../atom/GoalItem'
import RestingMetabolismItem from '../../atom/RestingMetabolismItem'
import TrainingMetabolismItem from '../../atom/TrainingMetabolismItem'
import CalculatedRecommendedKcal from '../../atom/CalculatedRecommendedKcal'
import KcalMenu from '../../atom/KcalMenu'
import ActivityLevel from '../../atom/ActivityLevel'
import ExerciseHabits from '../../atom/ExerciseHabits'
import HealthProblems from '../../atom/HealthProblems'
import AllergiesDetails from '../../atom/AllergiesDetails'
import BmiItem from '../../atom/BmiItem'
import WeightItem from '../../atom/WeightItem'
import InitialWeight from '../../atom/InitialWeight'
import InstrucotWeightItem from '../../atom/InstrucotWeightItem'
import CoursePhase from '../../atom/CoursePhase'
import ContractTo from '../../atom/ContractTo'

const UserDetails = ({
  user,
  callback,
  match,
  page,
  ...props
}) => {
    const [isInfoOpen, toggleInfo] = useState(true);

    return <div className="wcwr-user-info" data-open={isInfoOpen}>
      <ul>
        <li className="user">
          <UserName
            user={user}
          ></UserName>
          <button className="toggleInfo" onClick={() => toggleInfo(!isInfoOpen)}>
            <i className="buttonIcon icon-arrow-1-left"></i>
          </button>
        </li>
        <HeightItem
          height={user.height}
        />
        <InitialWeight
          initialWeight={user.startWeight}
        />
        <GoalItem
          motivation={user.motivation}
        />
        <RestingMetabolismItem
          restingMetabolism={user.restingMetabolism}
        />
        <TrainingMetabolismItem
          trainingMetabolism={user.trainingMetabolism}
        />
        <CalculatedRecommendedKcal
          recommendedKcalIn={user.recommendedKcalIn}
        />
        <KcalMenu
          kcalMenu={user.kcalMenu}
        />
        <ActivityLevel
          activityLevel={user.activityLevel}
        />
        <ExerciseHabits
          exerciseHabits={user.exerciseHabits}
        />
        <HealthProblems
          data={user.healthProblems}
        />
        <AllergiesDetails
          data={user.allergiesList}
        />
        <BmiItem
          height={user.height}
          weight={user.weight}
        />
        <WeightItem
          weight={user.weight}
        />
        <InstrucotWeightItem
          weight={user.instructorWeight}
        />
        <ContractTo
          contractTo={user?.contractTo}
        />
        <CoursePhase
          userId={user.userId}
          coursePhase={user.coursePhase}
          coursePhaseStarted={user.coursePhaseStarted}
        />
        <UserCommentNew
          userId={user.userId}
          userComment={user.comment}
          callback={callback}
        />
        <li className="actions">
          <UserActionButtons
            user={user}
            match={match}
            callback={callback}
            page={page}
          />
        </li>
      </ul>
    </div>
}

export default UserDetails;
