import React, { Fragment, useState } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

const Calendar = ({
  ...props
}) => {
  // state = {
  //   month: props.currentDate ? moment(props.currentDate).startOf('month') : moment().startOf('month'),
  //   time: props.showTime ? moment(props.currentDate, "YYYY-MM-DD HH:mm") : null
  // }

  const [month, setMonth] = useState(props.currentDate ? moment(props.currentDate).startOf('month') : moment().startOf('month'));
  const [time, setTime] = useState(props.showTime ? moment(props.currentDate, "YYYY-MM-DD HH:mm") : null);

  const setDate = (e) => {
    if (e.target.tagName === 'LI' && e.target.innerText) {
      let date = month.format('YYYY-MM-') + (`00${e.target.innerText}`).slice(-2);

      if(time) {
        date = date + "T" + time.format("HH:mm")
      }

      props.setDate(date);
      props.close();
    }
  }

  const changeHour = (e) => {
    const t1 = time ? time.hours(e.target.value) : moment().hours(e.target.value)
    setTime(t1);
  }

  const changeMinutes = (e) => {
    const t1 = time ? time.minutes(e.target.value) : moment().minutes(e.target.value)
    setTime(t1);
  }

  const getListOfDaysInMonth = () => {

    const currentMonth = month.format('YYYY-MM-');
    const selectedDate = moment(props.currentDate ? props.currentDate : props.match.params.date).format('YYYY-MM-D');
    const today = moment().format('YYYY-MM-D');


    return Array(month.daysInMonth()).fill().map((_, i) => ({
      number: i+1,
      selectedClass: (`${currentMonth}${i+1}` === selectedDate) ? 'selected' : '',
      todayClass: (`${currentMonth}${i+1}` === today) ? 'today' : ''
    }));
  }



  const week_begining = month.startOf('month').isoWeekday() - 1;
  // const { currentDate } = props;
  // const time1 = moment(currentDate)

  return (
    <Fragment>
      <div className="overlay calendar-overlay" onClick={props.close}></div>
      <div className="calendar">
        <div className="calendar-header">
          <button onClick={() => setMonth(moment(month).add(-1, 'month'))}><i className="icon-arrow-2-left"></i></button>
          <strong>{month.format('MMMM YYYY')}</strong>
          <button onClick={() => setMonth(moment(month).add(1, 'month'))}><i className="icon-arrow-2-right"></i></button>
        </div>
        <ul className="calendar-weekdays">
          <li>ma</li>
          <li>ti</li>
          <li>on</li>
          <li>to</li>
          <li>fr</li>
          <li>lø</li>
          <li>sø</li>
        </ul>
        <ul className="calendar-days" onClick={e => setDate(e)}>
          {Array(week_begining).fill().map((_, i) => (
            <li key={i} className="empty"></li>
          ))}
          {getListOfDaysInMonth().map((day, i) => (
            <li key={i} className={`${day.todayClass} ${day.selectedClass}`}>{day.number}</li>
          ))}
        </ul>
        {
          props.showTime && <div className="calendar-time">
            <div className="time-container">
              <input type="number" defaultValue={time.format("HH")} min="00" max="23" step="1" onChange={changeHour} className="time-item"/> :
              <input type="number" defaultValue={time.format("mm")} min="00" max="59" step="1" onChange={changeMinutes} className="time-item"/>
            </div>
          </div>
        }
      </div>
    </Fragment>
  )
}

export default withRouter(Calendar)
