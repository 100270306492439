import React, { Fragment, useState } from 'react';

import { Link } from 'react-router-dom'
import {weightBookCourseUri} from '../../utils/WeightBookCourseUri'

import EmptyResultSet from '../../atom/EmptyResultSet';
import WeightBookGroupHeader from '../../atom/WeightBookGroupHeader';
import WeightBookGroupList from '../WeightBookGroupList';

const WeightBookGroupDetails = ({
  usersGroup,
  page,
  match,
  callback,
  ...props
}) => {




    return <Fragment>
      {
        (Object.entries(usersGroup).length === 0 && usersGroup.constructor === Object) ? <EmptyResultSet>Ikke deltakere</EmptyResultSet> : <div className="weightBookParticpants wbp">
          {
            Object.keys(usersGroup).map((entry, index) => (
              <div className="wbp-table" key={index} data-id={index}>

                <WeightBookGroupHeader
                  usersGroup={usersGroup}
                  entry={entry}
                  page={page}
                  match={match}
                />

                <WeightBookGroupList
                  page={page}
                  match={match}
                  userList={usersGroup[entry]}
                  callback={callback}
                  {...props}
                />


              </div>
            ))
          }
          </div>
        }
    </Fragment>

}

export default WeightBookGroupDetails;
