import { gql } from "apollo-boost";

const GET_COURSE_PARTICIPANTS = gql`
	query Assistant($courseId: Int!) {
		request {
			grassistant {
				assistantCourseParticipants(courseId: $courseId) {
					edges {
						node {
							name
							userId
							email
							cellPhone
							statusText
							renewalStatus
							emailAllowed
							smsAllowed
							isActive
							userStartDate
							userEndDate
							paymentSourceHandler
						}
					}
				}
			}
		}
	}
`

export default GET_COURSE_PARTICIPANTS;
