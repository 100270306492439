import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { useMutation } from '@apollo/client'
import UPDATE_COURSE_PHASE from '../../mutation/update_course_phase.js'
import moment from 'moment'

function CoursePhase({ userId, coursePhase, coursePhaseStarted }) {
  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return (
    <li>
      <span>Fase</span>
      <button type="button" onClick={openModal} style={{ display: 'flex' }}>
        {coursePhase ? (
          <>
            <em style={{ marginRight: '6px', textDecoration: 'underline' }}>
              {coursePhase} ({moment(coursePhaseStarted).format('DD/MM-YYYY')})
            </em>
            <i className="buttonIcon icon-edit-1" />
          </>
        ) : (
          <>Ikke satt</>
        )}
      </button>
      {isOpen &&
        ReactDOM.createPortal(
          <CoursePhaseModal
            close={closeModal}
            userId={userId}
            coursePhase={coursePhase}
          />,
          document.getElementById('modal-root')
        )}
    </li>
  )
}

function CoursePhaseModal({ close, userId, coursePhase }) {
  const [phase, setPhase] = useState(coursePhase)

  const [updateCoursePhase, { loading }] = useMutation(UPDATE_COURSE_PHASE, {
    refetchQueries: ['Assistant'],
    onCompleted() {
      close()
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!loading) {
      const variables = { userId, coursePhase: parseInt(phase, 10) }
      updateCoursePhase({ variables })
    }
  }

  return (
    <form
      name="modal"
      action=""
      method="post"
      className="ModalOverlay is-open"
      onSubmit={handleSubmit}
    >
      <div className="WeightModalForm" style={{ maxWidth: '480px' }}>
        <div className="ModalHeader">
          <div className="header_item close_wrapper">
            <div className="close_button" onClick={close}>
              <i className="action-icons icon-cancel-circle"></i>
              <div className="textWrapper">Lukk</div>
            </div>
          </div>
          <div className="header_item name_wrapper">Rediger fase</div>
        </div>
        <div className="ModalBody ModalChangeMeetingDate">
          <p>Instructions here.</p>
          <select
            name="course_phase"
            required
            onChange={(e) => setPhase(e.target.value)}
            defaultValue={phase}
          >
            <option value="">- Velg fase -</option>
            {[1, 2, 3].map((value) => (
              <option key={value} value={value}>
                Fase {value}
              </option>
            ))}
          </select>
          <button
            type="submit"
            style={{
              display: 'block',
              margin: '24px 0 24px auto',
              backgroundColor: '#002538',
              color: 'white',
              borderRadius: 4,
              padding: '10px 14px',
              fontSize: '16px',
            }}
            disabled={loading}
          >
            Lagre
          </button>
        </div>
      </div>
    </form>
  )
}

export default CoursePhase
