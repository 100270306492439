import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'
var _ = require('underscore');

import Loading from '../atom/Loading';
import NotFound from '../atom/NotFound';
import DiaryUserDetails from '../atom/DiaryUserDetails';
import FeaturedWeights from '../atom/FeaturedWeights'
import StatsSection from '../atom/StatsSection';
import MeasurementsChart from '../atom/MeasurementsChart';
import MeasurementsList from '../atom/MeasurementsList';
import Modal from '../ui/Modal';

import GET_WEIGHTBOOK_PARTICIPANT2 from '../query/get_weightbook_participant2.js';

import {
	transformData,
	formatAmount
} from '../utils/GetCacheQuery'


const PAGE = 'nye-vektutvikling';



const Breadcrumbs = ({ userId, userName }) => (
	<div className="headWrapper">
		<div className="headText">
				[NY] Vektutvikling
		</div>
	  <nav className="breadcrumbs">
	    <ul>
	      <li><Link to="/">Hovedside</Link></li>
	      <li><Link to="/nye-vektbok/">[NY] Vektbok</Link></li>
	      <li><Link to={`/nye-kursdeltakere/${userId}`}>{userName}</Link></li>
	      <li>Vektutvikling</li>
	    </ul>
	  </nav>
	</div>
)

const WeightBookResultNew = ({
	match,
	...props
}) => {

		const {
			userId
		} = match.params;

		const [weeklyCacheDate, setWeeklyCacheDate] = useState(moment().format('YYYY-MM-DD'));
		const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DDT00:00:00'));
		const [dateFrom, setDateFrom] = useState(moment().add(-1, 'years').format('YYYY-MM-DD'));

		const {
			loading,
			refetch,
			data: {
				request: {
					grassistant: {
						assistantGetUser
					}= {}
				}={}
			} = {}
		} = useQuery(GET_WEIGHTBOOK_PARTICIPANT2, {
			variables: {
				userId: userId,
	      weeklyCacheDate: weeklyCacheDate,
	      dateTo: dateTo,
	      dateFrom: dateFrom,
			}
		});

		if (loading) {
			return <div className="pageContainer weightBook">
				<Loading />
			</div>
		}

		const notFound = !!assistantGetUser
		const { user, featuredWeights, weightStats, waistStats, diaryStats, measurements } = transformData(assistantGetUser || {});
		var userName = !loading ? user.name : "Kursedeltakere";

    return <div className="pageContainer weightBook">
			<div className="pageHeadWrapper">
				<Breadcrumbs userName={userName} userId={userId}/>
			</div>
			{
				notFound ? <div className="WeightBookResult wbr">
					<DiaryUserDetails
						page={'nye-kursdeltakere'}
						user={user}
					/>
					<div className="wbr-registration">
		        <p>{user.lastMeasuredWeightText} <strong>{formatAmount(user.lastMeasuredWeight, 'kg')}</strong></p>
						{
							<Modal
				        type={"weighing"}
				        from={"vektutvikling"}
				        modalHeader={`Vektbok - ${user.name}`}
				        callback={refetch}
				        user={{
				          id: user.id,
				          name: user.name
				        }}
				        page={PAGE}
				        match={match}
				      >
								<div className="modelRegistration">Rediger <i className="icon-edit-1" /></div>
							</Modal>
						}
					</div>
					<FeaturedWeights data={featuredWeights} />
					<Modal
		        type={"goals"}
		        user={{
		          id: user.id,
		          name: user.fullName,
		        }}
						callback={refetch}
					 	modalHeader={`Start vekt og mål`}
		        page={PAGE}
						match={match}
					>
						<div className="modelRegistration">
			      	<div className="wbr-button arrow">Oppdatere mål og start vekt</div>
						</div>
			    </Modal>

					{user.lastMeasuredWeight &&
		        <StatsSection title="Vektutvikling" data={weightStats} />
		      }
		      {!!waistStats.length &&
		        <StatsSection title="Midjemål" data={waistStats} />
		      }
		      <StatsSection
		        title="Kosthold og trening"
		        week={moment().add(-1, 'week').format('W')}
		        data={diaryStats}
		      />


					{!!measurements.length &&
		        <Fragment>
							<MeasurementsList measurements={measurements} callback={refetch} user={user} page={PAGE} match={match} />
							<MeasurementsChart participant={assistantGetUser} />
						</Fragment>
					}

				</div> : <NotFound>Deltaker ikke funnet</NotFound>
			}
		</div>
}

export default WeightBookResultNew;
