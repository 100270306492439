import { gql } from "apollo-boost";

const COURSE_MUTATION = gql`
mutation Assistant($courseId: Int!, $startTime: String!, $meetingWeekday: Int!) {
  assistant {
    assistantUpdateCourseScheduleTime(courseId: $courseId, startTime: $startTime, meetingWeekday: $meetingWeekday) {
      status
    }
  }
}
`

export default COURSE_MUTATION;
