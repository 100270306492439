import React, { Fragment, useState } from 'react';

const UserListPagination = ({
  pageNo,
  setPageNo,
  hasMore,
  ...props
}) => {

    const pagePrevious = () => {
      if (pageNo > 1) setPageNo(pageNo-1)
    }

    const pageNext = () => {
      if (hasMore) setPageNo(pageNo+1)
    }

    return <div className="paginationWrapper">
      <div className="paginationContainer">
        <div className={`arrowWrapper leftWrapper ${pageNo==1 && 'disable'}`} onClick={pagePrevious}></div>
        <div className="pageWrapper"><strong>{pageNo}</strong></div>
        <div className={`arrowWrapper rightWrapper ${!hasMore && 'disable'}`} onClick={pageNext}></div>
      </div>
    </div>
}

export default UserListPagination;
