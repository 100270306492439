import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment'

import Loading from '../../atom/Loading';
import WeightStatsDetails from '../../atom/WeightStatsDetails';

import {
  GET_WEIGHT_STAT2
} from '../../query/get_weight_stat2'

const WeightStatsParticipant = ({
  match,
  page,
  ...props
}) => {

  const {
		date,
		hour,
    courseId,
		participants=""
	} = match.params

  const {
  	loading,
  	data: {
  		assistant: {
  			assistentGetStats=""
  		}={}
  	}={}
  } = useQuery(GET_WEIGHT_STAT2, {
  	variables: {
  		userIdList: participants.split(',').map(function(item) {
					return parseInt(item, 10);
			}),
      courseId: courseId,
  		dateTo: moment(date).format('YYYY-MM-DDT23:59:59'),
  	}
  });

  return <Fragment>
    {
      loading ? <Loading/> : <WeightStatsDetails assistentGetStats={assistentGetStats} />
    }
  </Fragment>




}

export default WeightStatsParticipant;
