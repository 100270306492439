import React, { Fragment, useState } from 'react';

const KcalMenu = ({kcalMenu, ...props}) => {
    return <Fragment>
      {kcalMenu && (
        <li><span>Meny</span> {kcalMenu}</li>
      )}
    </Fragment>
}

export default KcalMenu;
