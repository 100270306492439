import React from 'react';
import moment from 'moment'
import { Link } from 'react-router-dom';
var _ = require('underscore');

import Loading from '../../../atom/Loading';


class CMMonth  extends React.Component {
  render() {

    const currDate = this.props.year ? moment().year(this.props.year).month(this.props.month) : moment().month(this.props.month);
    const cl = this.props.year ? moment().year(this.props.year).month(this.props.month) : moment().month(this.props.month);

    const week_begining = currDate.startOf('month').isoWeekday();
    const days_in_month = currDate.daysInMonth();

    function getWeeks(_, item) {
      return cl.date(item+1).isoWeek()
    }

    const week_range = [...new Set(Array(days_in_month).fill().map(getWeeks))]

    const results = _.groupBy(this.props.results, function(obj){
      return moment(obj.node.startTime).isoWeek()
    })

    return <div className="cm-month-container">
      <div className="cm-month-name">{this.props.month}</div>
      <div className="cm-month">
        <div className="cm-grid-result">
          <div className="cm-item-week">
            <div className="cm-month-header">Week</div>
            {week_range.map((week, i) => (
              <div key={i} className="cm-days-dimension">{week}</div>
            ))}
          </div>
          <div className="cm-item-months">
            <div className="cmm-grid cm-month-header">
              <div className="cm-month-header-item">Mon</div>
              <div className="cm-month-header-item">Tue</div>
              <div className="cm-month-header-item">Wed</div>
              <div className="cm-month-header-item">Thur</div>
              <div className="cm-month-header-item">Fri</div>
              <div className="cm-month-header-item">Sat</div>
              <div className="cm-month-header-item">Sun</div>
            </div>
            <div className="cmm-grid cm-month-body">
              {Array(week_begining-1).fill().map((_, i) => (
                <div key={i} className="cm-empty cm-days-dimension"></div>
              ))}
              {Array(days_in_month).fill().map((_, i) => (
                <CMMonthDay
                  className="cm-day cm-days-dimension"
                  key={i}
                  day={cl.date(i+1).format("YYYY-MM-DD")}
                  res={results[cl.date(i+1).isoWeek()]}
                ></CMMonthDay>
              ))}

            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

class CMMonthDay extends React.Component {
  getDataMeetingOne(obj) {
    const  data = JSON.parse(obj || '{}')
    return !data.meeting_2 && !data.meeting_4 ? true : false;
  }

  getDataMeetingTwo(obj) {
    const  data = JSON.parse(obj || '{}')
    return data.meeting_2 ? true : false
  }

  getDataMeetingFour(obj) {
    const  data = JSON.parse(obj || '{}')
    return data.meeting_4 ? true : false
  }



  render() {
    const {day, res} = this.props;
    const currDate = moment(day, 'YYYY-MM-DD')
    const results = _.filter(res, function(obj){
      return moment(obj.node.startTime, 'YYYY-MM-DD').isSame(currDate)
    })
    return <div className="cm-day cm-days-dimension" >
      <div className="cm-day-container">
        <div className="cm-day-wrapper">
          {currDate.format("D")}
        </div>
        <div className="cm-day-time-wrapper">
          {results.map((obj, index) => (
            <Link
              className="cm-day-time"
              key={index}
              data-meeting="true"
              data-meeting-one={this.getDataMeetingOne(obj.node.data)}
              data-meeting-two={this.getDataMeetingTwo(obj.node.data)}
              data-meeting-four={this.getDataMeetingFour(obj.node.data)}
              to={`/course-details/${obj?.node?.courseId}/course-meeting/${obj?.node?.courseMeetingId}`}
            >
              {moment(obj.node.startTime).format("HH:mm")} ({obj.node.attendanceCount})
            </Link>
          ))}
        </div>
      </div>
    </div>
  }
}

export default CMMonth;
