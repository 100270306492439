import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'

import Loading from '../atom/Loading';
import DiaryUserDetails from '../atom/DiaryUserDetails';
import DiaryDayDiet from '../atom/DiaryDayDiet';
import DiaryDayExercise from '../atom/DiaryDayExercise';
import DateNavDiaryDay from '../atom/DateNavDiaryDay';
import NotFound from '../atom/NotFound';

import GET_DIARY_DAY_PARTICIPANT2 from '../query/get_diary_day_participant2.js';
const PAGE = "nye-dagbok"

import {
	getUser,
	getDietData,
	getExerciseData
} from '../utils/GetCacheQuery'

const Breadcrumbs = ({
		date,
    userId,
		userName
}) => (
    <div className="headWrapper">
        <div className="headText">
            [NY] Dagbok registreringer for {moment(date).format('D. MMMM YYYY')}
        </div>
				<nav className="breadcrumbs">
			    <ul>
					<li><Link to="/">Hovedside</Link></li>
					<li><Link to={`/nye-kursdeltakere/${userId}`}>{userName}</Link></li>
					<li><Link to={`/nye-dagbok/${userId}/`}>[NY] Dagbok</Link></li>
					<li>{moment(date).format('D. MMMM YYYY')}</li>
			    </ul>
			  </nav>
    </div>
)

const DiaryDay = ({
	match,
	...props
}) => {

		const {
			userId,
			date
		} = match.params;

		const {
			loading,
			data: {
				request: {
					grassistant: {
						assistantGetUser: user
					}={}
				}={}
			} = {}
		} = useQuery(GET_DIARY_DAY_PARTICIPANT2, {
			variables: {
				userId: userId,
				date: date
			},
		});

		if(loading) {
				return <div className="diaryPage"><Loading/></div>
		}

		if(!user) {
			return <div className="pageContainer weightBook">
				<NotFound>Deltaker ikke funnet</NotFound>
			</div>
		}

		const userData = getUser(user);
		var userName = !loading ? userData.name : "Kursedeltakere";

    return <div className="pageContainer weightBook">
			<div className="pageHeadWrapper">
				<Breadcrumbs userId={userId} date={date} page={PAGE} userName={userName}/>
				<DateNavDiaryDay userId={userId} date={date} page={PAGE} />
			</div>
	   	<div className="diaryPage">
				<DiaryUserDetails
					page={'nye-kursedeltakere'}
					user={userData}
				/>
				<DiaryDayDiet data={getDietData(user)} />
				<DiaryDayExercise data={getExerciseData(user)} />
			</div>
		</div>
}

export default DiaryDay;
