import React from "react";
import Modal from "react-bootstrap/Modal";

export default function FormatHelpModal({ isVisible, close }) {
  return (
    <Modal
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => close()}
    >
      <Modal.Header closeButton>
        <Modal.Title>Formateringshjelp</Modal.Title>

      </Modal.Header>
      <Modal.Body>

      <h4>Lenke:</h4>
      <pre>
        {`
        [Link tekst](https://example.com/)
        `}
      </pre>

      <h4>Nummerert liste:</h4>
          <pre>{`
          <tall><punktum><mellomrom>
          `}</pre>
          <strong>Eksempel:</strong>
          <pre>
            {`
            1. Liste en
            2. Liste to
            `}

          </pre>
        <h4>Punktmerket liste</h4>
      <pre>
        {`
        <*><mellomrom>
        `}
      </pre>
      <strong>Eksempel:</strong>
      <pre>
        {`
        * Punkt en
        * Punkt to
        `}

      </pre>
      <h4>Kursiv</h4>
      <pre>{` 
      *This text will be italic*
      `}</pre>

      <h4>Uthevet</h4>
      <pre>{` 
      **This text will be bold**
      `}</pre>
 
 

      </Modal.Body>
    </Modal>
  );
}
