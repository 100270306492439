import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';

import Loading from '../../atom/Loading';
import {
  USERS_AUTOCOMPLETE,
  USERS_AUTOCOMPLETE2
} from '../../query/users_autocomplete'

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;
var timeout = null;

const UserAutocomplete = ({
  setUserId,
  page,
  ...props
}) => {

  const [keyword, setKeyward] = useState();
  const [popup, showPopup] = useState(false);


  const clearSearch = (e) => {
      e.preventDefault();
      setKeyward(null);
      showPopup(false);
      setUserId(null, 'active');
      $("#search").val("")
  }

  const handleChange = (e) => {
      clearTimeout(timeout);
      var val = e.target.value;
      timeout = setTimeout(function(){
        setKeyward(val);
        showPopup(true);
      }, WAIT_INTERVAL);
  }

  const handleKeyDown = (e) => {
      if (e.keyCode === ENTER_KEY) {
        clearTimeout(timeout);
        var val = e.target.value;
        setKeyward(val);
        showPopup(true);
      }
  }

  const {
		loading,
		data: {
      request: {
        grassistant: {
          assistantUsersAutocomplete
        }={}
      }={}
		} = {}
	} = useQuery(page === 'kursedeltakere' ? USERS_AUTOCOMPLETE : USERS_AUTOCOMPLETE2, {
		variables: {
			keyword: keyword ? keyword : ''
		 }
	});

  const setUser = (e, userId, status) => {
    setUserId(e, userId, status);
    showPopup(false);
  }

  return <div className="searchWrapper">
      <input
        type="text"
        placeholder="Søk etter medlem..."
        id="search"
        onChange={(e) => handleChange(e)}
        onKeyDown={(e) => handleKeyDown(e)}
        autoComplete="off"
      />
      { keyword != "" && <i className="icon-close icon-item" onClick={(e) => clearSearch(e)}></i> }
      { keyword == "" && <i className="icon-magnifier-1 icon-item"></i> }

      {popup && <div className="userSearchList">
        <div className="QuickSearchList">
          {
            loading ? <div className="LoadWrapper">
              <Loading />
            </div> : <Fragment>
                {
                  JSON.parse(assistantUsersAutocomplete || '[]').length > 0 ? JSON.parse(assistantUsersAutocomplete || '[]').sort((a, b) => (a.status > b.status) ? 1 : -1)
                  .map((obj, index)=>
                    <div className="qsItem" key={index} onClick={(e) => setUser(e, obj.userId, obj.status)}>
                      {obj.name}
                    </div>
                  ) : <div className="qsItem" onClick={(e) => clearSearch(e)}>
                    No results
                  </div>
                }

            </Fragment>
          }
        </div>
      </div>}

    </div>
}


export default UserAutocomplete;
