import React, { Fragment, useState } from 'react';

import ItemMembershipName from '../../atom/ItemMembershipName';
import NextMeeting from '../NextMeeting';
import Modal from '../Modal';

const MembershipCourseItem = ({
  courseItem,
  refetch,
  ...props
}) => {

    const nextMeeting = JSON.parse(courseItem.nextMeeting || [])

    return <Fragment>
      <ItemMembershipName
        membershipId={courseItem.membershipId}
        variant={courseItem.paymentSource}
        statusText={courseItem.statusText}
        membershipName={courseItem.name}
        courseId={courseItem.courseId}
      />
      <NextMeeting
        nextMeeting={nextMeeting}
      />
      <div className="grid-column">
        <Modal
          type={"move_membership"}
          from={"move_membership"}
          modalHeader={"Flytt medlemskap"}
          callback={refetch}
          memberships={[courseItem.membershipId]}
          userId={courseItem.userId}
        >
          <div className="buttonItem"><i className="action-icons icon-link-3"></i> <span className="button-name">Flytt medlemskap</span></div>
        </Modal>
      </div>
    </Fragment>
}

export default MembershipCourseItem;
