import React from 'react';
import axios from 'axios';
import { useQuery } from '@apollo/client';

import USER_QUERY from '../query/user_query'

const showLogin = () => {
    document.querySelector('.ModalOverlay').classList.add('is-open');
    document.querySelector('.LoginModal').classList.add('is-open');
}

const logout = () => {

    axios.get('/api/logout')
        .then(function (response) {
            window.location.href = '/';
        })
        .catch(function (error) {
            window.location.href = '/';
        });
}

const user = {
    user: null,
    error: "",
    activeInstructor: false,

    isAuthenticated() {        
      return Boolean(this.user) && Boolean(this.activeInstructor) &&  this.error === undefined;
    },

    get() {
        return this.user;
    },

    set(user, activeInstructor, error) {
        this.user = user;
        this.activeInstructor = activeInstructor;
        this.error = error
    }
}

const Auth = ({children}) => {

	const {
		loading,
		error,
		data
	} = useQuery(USER_QUERY);

    const userData = data?.request?.user?.userData
    const activeInstructor = data?.request?.user?.activeInstructor

	user.set(userData, activeInstructor, error);
	return !loading && React.createElement(
		'div',
		{},
		React.Children.map(children, (
			child => React.cloneElement(child, { user: userData } )
		))
	)


}

export {
    logout,
    showLogin,
    user
};

export default Auth;
