import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';

import { Link } from 'react-router-dom'
import moment from 'moment'

import MembershipCourseItem from '../MembershipCourseItem';
import MembershipNettcourseItems from '../MembershipNettcourseItems';


const UserMembershipsDetails = ({
  user,
  memberships,
  match,
  refetch,
  ...props
}) => {

    const [isInfoOpen, toggleInfo] = useState(true);

    function nettcourse(obj) {
      return <MembershipNettcourseItems
        nettcourseItem={obj}
        refetch={refetch}
      />
    }

    function course(obj) {
      return <MembershipCourseItem
        courseItem={obj}
        refetch={refetch}
      />
    }

    function membership_type(obj) {      
				
      if (obj.roedeCourseId) {
        return course(obj)
      }

      if (obj.nettcourseInstructorId) {
        return nettcourse(obj)
      }
      
      return <div>{obj.membershipId} Not found</div>
    }

    const courseType = {
      'nettcourse': nettcourse,
      'course': course,
      'membership': membership_type,
    }


    return <div className="user-memberships-details-container" data-open={isInfoOpen}>
      <div className="membership-header-container">
        <div className="membership-header">Medlemskap</div>
        <div className="toggle-wrapper">
          <button className="toggleInfo" onClick={() => toggleInfo(!isInfoOpen)}>
            <i className="buttonIcon icon-arrow-1-left"></i>
          </button>
        </div>
      </div>
      <div className="membership-list-container">
        <div className="grid-container grid-head">
          <div className="grid-column">#</div>
          <div className="grid-column">Kurs</div>
          <div className="grid-column">Variant</div>
          <div className="grid-column">Status</div>
          <div className="grid-column">Dato</div>
          <div className="grid-column"></div>
        </div>
        {
          memberships.map((obj, index) => <div className="grid-container" key={index}>{
            courseType[obj.membershipType](obj)
          }</div>)
        }
      </div>
    </div>
}

export default UserMembershipsDetails;
