import { gql } from "apollo-boost";

const GET_FILTER_DATA = gql`
query Assistant($status: String) {
  request {
    grassistant {
      assistantUsersFilter(status: $status)
    }
  }
}
`;

const GET_FILTER_DATA2 = gql`
query Assistant($status: String) {
  request {
    grassistant {
      assistantUsersFilter: assistantUsersFilter2(status: $status)
    }
  }
}
`;

export {
	GET_FILTER_DATA,
	GET_FILTER_DATA2
};
