import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'
var _ = require('underscore');
import InfiniteScroll from 'react-infinite-scroller'

import Loading from '../atom/Loading';
import WeightDrilldownHeader from '../atom/WeightDrilldownHeader';
import ParticipantItem from '../atom/ParticipantItem';
import WeightStatsParticipant from '../ui/WeightStatsParticipant';

import WEIGHT_DRILLDOWN_USER_NEW from '../query/weight_drilldown_user_new.js';


import {
	getParticipants,
	sortParticipants,
} from '../utils/GetCacheQuery';

import {
	formatHour,
	INITIAL_SORT_ORDER
} from '../utils/GetCacheQuery';

const PAGE="nye-vektbok";


const Breadcrumbs = ({ match: { params: { date, hour } } }) => (
	<div className="headWrapper">
		<div className="headText">
				[NY] Detaljoversikt
		</div>
	  <nav className="breadcrumbs">
	    <ul>
	      <li><Link to="/">Hovedside</Link></li>
	      <li><Link to={`/${PAGE}/`}>[NY] Vektbok</Link></li>
	      <li>
	        <Link to={`/${PAGE}/${date}/`}>
	          {moment(date).format('D, MMMM')} - {formatHour(hour)}
	        </Link>
	      </li>
	      <li>[NY] Detaljoversikt</li>
	    </ul>
	  </nav>
	</div>
)

const WeightDrilldownUserNew = ({
	match,
	...props
}) => {

	const {
		date,
		hour,
		participants
	} = match.params

		const [sortOrder, setSortOrder] = useState(INITIAL_SORT_ORDER)

		const setSort = field => {
			const sortMe = {
				[field]: null,
				...sortOrder,
				[field]: (sortOrder[field] === 'asc') ? 'desc' : 'asc',
			}

			setSortOrder(sortMe);
		}

		var variables = {
			userIdList: participants.split(',').map(function(item) {
					return parseInt(item, 10);
			}),
			cacheDate: moment(date).format('YYYY-MM-DD'),
			summaryDateTime: moment(date).format('YYYY-MM-DDT23:59:59'),
			category: 'courseType',
			currentDateTimeFrom: moment(date).startOf('week').add(-7, 'weeks').format('YYYY-MM-DDT00:00:00'),
			currentDateTimeTo: moment(date).format('YYYY-MM-DDT23:59:59'),
		}

		const {
			loading,
			fetchMore,
			data: {
				assistant: {
					assistantMemberTotal: total=0,
					assistantUserList: {
						edges,
						pageInfo: {
				      hasNextPage: hasMore,
				      endCursor
				    }={}
					}={}
				}={}
			} = {}
		} = useQuery(WEIGHT_DRILLDOWN_USER_NEW, {
			variables: variables
		});





		const loadMoreEntries = () => {
			variables['end'] = endCursor

			fetchMore({
				query: WEIGHT_DRILLDOWN_USER_NEW,
				variables: variables,
				updateQuery: (previousResult, { fetchMoreResult }) => {
					if (!fetchMoreResult) { return previousResult; }
					const prevRequest = previousResult.assistant;
					const prevAssistantUserList = previousResult.assistant.assistantUserList;
					const prevEdge = previousResult.assistant.assistantUserList.edges;
					const nextEdge = fetchMoreResult.assistant.assistantUserList.edges;
					const pageInfo = fetchMoreResult.assistant.assistantUserList.pageInfo;

					return {
						assistant: {
							...prevRequest,
							assistantUserList: {
								__typename: fetchMoreResult.assistant.assistantUserList.__typename,
								edges: [...prevEdge, ...nextEdge],
								pageInfo,
							}
						}
					}
				}
			})
		}


    return <div className="pageContainer weightBook">
			<div className="pageHeadWrapper">
				<Breadcrumbs match={match}/>
			</div>
			<div className="wbm">
				<div className="wbm-count">{total} deltakare</div>
				<div id="scrollableDiv" className="participantsListWrapper participantsItem">
					<table>
						<WeightDrilldownHeader sortOrder={sortOrder} setSort={setSort} match={match}/>
						<InfiniteScroll
							loadMore={() => loadMoreEntries()}
							hasMore={hasMore}
							loader={<tr key="0"><td colSpan="7"><Loading/></td><td colSpan="9"></td></tr>}
							element="tbody"
						>
							{loading ? <tr key="0"><td colSpan="7"><Loading/></td><td colSpan="9"></td></tr> : sortParticipants(getParticipants(edges), sortOrder).map((participant, index) =>
									<ParticipantItem participant={participant} key={index} index={index}/>
							)}
						</InfiniteScroll>
					</table>
				</div>
				<WeightStatsParticipant match={match} page={PAGE}/>
			</div>


		</div>
}

//

export default WeightDrilldownUserNew;
