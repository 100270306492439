import React from 'react';
import moment from 'moment'

import {
  formatAmount
} from '../../utils/GetCacheQuery'

const FeaturedWeights = ({ data, ...props }) => (
  <ul className="wbr-weights">
    {Object.keys(data).map(key => (
      <li key={key}>
        <span>{data[key].text}</span>
        <strong>{formatAmount(data[key].weight, 'kg')}</strong>
        {data[key].date &&
          <time dateTime={data[key].date}>
            {moment(data[key].date).format('D. MMMM YYYY')}
          </time>
        }
      </li>
    ))}
  </ul>
)

export default FeaturedWeights;
