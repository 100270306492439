import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment'

const RoedeCourse = ({course, ...props}) => {
    return (
        <div className="roede-course-item">
          <Link to={`/course-details/${course.id}`}>
            <div className="roede-course-name">{course.name}</div>
            <div className="roede-course-location">
              <div className="roede-course-time">{`${moment(course.meeting_start_time, 'HH:mm:ss').format('HH:mm')}-${moment(course.meeting_end_time, 'HH:mm:ss').format('HH:mm')}`}</div>
              <div className="roede-course-address">{course.address}</div>
              <div className="roede-course-free-spots">Free spots: {course.free_spots_as_text_internal}</div>
            </div>
          </Link>
        </div>
    )
}

export default RoedeCourse;
