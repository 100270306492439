import React, { Fragment, useState } from 'react'
import moment from 'moment'
import { useMutation } from '@apollo/client';
import loadingImg from '../../SysIons/loading.svg';
import NoResults from '../NoResults'
var _ = require('underscore');

import ASSISTANT_SKIP_MEETING from '../../../mutation/assistant_skip_meeting.js';

const Loading = () => (
  <div className="pageContainer weightBook">
    <div className="loadingData" />
  </div>
)

const SkipMeetingNotification = ({
  close,
  ...props
}) => {

  const {
    users,
    courseMeetingId,
    callback,
  } = props

  const [saveFunction, { data, loading }] = useMutation(ASSISTANT_SKIP_MEETING, {
    onCompleted() {
      close();
    }
  });

  const submit = () => {




    try {
      const arr = _.map(_.filter(users, function(obj){
        return obj.skip_allowed
      }), function(obj) { return parseInt(obj.membershipId) });

      const variables = {
        'membershipId': arr,
        'courseMeetingId': courseMeetingId,
      }

      const res = saveFunction({ variables: variables});

      res.then(({ data }) => {
      }).catch(function(err) {
        alert((err && err.message) || 'An error ocurred while processing the request')
      })
    } catch(err) {
      alert(err)
    }
  }


  return <Fragment>
    {users.length > 0 ? <div className="NotificationWrapper">
      <div className="textWrapper">You will skip meetings for following participants</div>
      <div className="ListContainer">
        {
          _.filter(users, function(obj){
            return obj.skip_allowed
          }).map((obj, index) => (
            <div key={index} className="ListItem">{obj.name}</div>
          ))
        }
      </div>
      {
        _.filter(users, function(obj){
          return !obj.skip_allowed
        }).length > 0 && <h5 className="skip_allowed_warning">Skip Not Allowed</h5>
      }
      <div className="ListContainer skip_allowed_warning">
        {
          _.filter(users, function(obj){
            return !obj.skip_allowed
          }).map((obj, index) => (
            <div key={index} className="ListItem">{obj.name}</div>
          ))
        }

      </div>
    </div> : <NoResults>
      <div>Du valgte ingen aktive deltakere.</div>
    </NoResults>}

    {
      users.length > 0 && <footer>
        <div className="buttonContainer pullRight">
          <div className="buttonWrapper">
            {
              loading ? <img src={loadingImg} alt="Processing" className="processing_image"/> :
                <button type="submit" className="buttonItem save" onClick={() => submit()}>Lagre</button>
            }
          </div>
        </div>
      </footer>
    }
  </Fragment>

}


export default SkipMeetingNotification
