import React, { Fragment, useState } from 'react';
import moment from 'moment'

const CommissionInstructorItem = ({
  obj,
  ...props
}) => {

    const url = {
      "development": "http://stage.roede.com",
      "stage": "https://stage.roede.com",
      "production": "https://api.roede.com"
    }

    return <div className="commission-instructor-item-container">
      <div className="commission-instructor-item-details">{moment(obj.commissionDate).format("M/YYYY")}</div>
      <div className="commission-instructor-item-details">{obj.total}</div>
      <div className="commission-instructor-item-details">
        <a href={`${url[process.env.NODE_ENV]}${obj.downloadUrl}`}>Oppsummering</a> &mdash; <a href={`${url[process.env.NODE_ENV]}${obj.detailedDownloadUrl}`}>Detaljer</a>
      </div>
    </div>
}

export default CommissionInstructorItem;
