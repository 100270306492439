import { gql } from 'apollo-boost'

export default gql`
  query Assistant(
    $page: Int!
    $limit: Int!
    $userId: Int
    $weekday: Int
    $status: String
    $emailAllowed: Boolean
  ) {
    request {
      grassistant {
        assistantUsersNettcourse(
          page: $page
          limit: $limit
          userId: $userId
          weekday: $weekday
          status: $status
          emailAllowed: $emailAllowed
        )
      }
    }
  }
`
