import { gql } from "apollo-boost";

const GET_WEIGHT_BOOK_GROUP_NEW = gql`
query Assistant($dateTo: String) {
  request {
    grassistant {
      assistantGetWeightbookData2(dateTo: $dateTo)
    }
  }
}
`

export default GET_WEIGHT_BOOK_GROUP_NEW;
