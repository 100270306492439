import React from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { dayOfWeek } from '../../utils/Constants'

export default function NettcourseListFilter({
  weekday,
  setWeekday,
  emailAllowed,
  setEmailAllowed,
  status,
  setStatus,
}) {
  return (
    <Form.Group className="participants_filter">
      <Row>
        <Col>
          <Form.Label as="legend">Samtykket til epost</Form.Label>
          <Col>
            <Form.Check
              type="switch"
              id="email-allowed"
              label="Samtykket til epost"
              size="lg"
              checked={emailAllowed}
              onChange={() => setEmailAllowed(!emailAllowed)}
            />
          </Col>
        </Col>
        <Col>
          <Form.Label as="legend">Status</Form.Label>
          <Col>
            <Form.Check
              type="radio"
              label="Aktive medlemmer"
              name="formHorizontalRadios"
              id="formHorizontalRadios1"
              checked={status == 'active'}
              onChange={() => setStatus('active')}
            />
            <Form.Check
              type="radio"
              label="Avsluttede medlemmer"
              name="formHorizontalRadios"
              id="formHorizontalRadios2"
              checked={status !== 'active'}
              onChange={() => setStatus('inactive')}
            />
          </Col>
        </Col>
        <Col>
          <Form.Label as="legend">Ukedag</Form.Label>
          <Col>
            <Form.Control
              as="select"
              size="sm"
              defaultValue={weekday}
              onChange={(e) => setWeekday(Number(e.target.value))}
            >
              <option value="">- Velg -</option>
              {Object.entries(dayOfWeek).map(([ index, day ]) => (
                <option key={index} value={index}>
                  {day}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Col>
      </Row>
    </Form.Group>
  )
}
