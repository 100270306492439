import React, { Fragment, useState } from 'react'
import { useMutation } from '@apollo/client';
import loadingImg from '../../SysIons/loading.svg';
import NoResults from '../NoResults';

import SKIP_MEETING_BULK from '../../../mutation/skip_meeting_bulk.js';

import moment from 'moment'

const MeetingItem = ({item, ...props}) => {
  return <tr>
    <td>{item?.courseMeetingId}</td>
    <td>{item?.course?.autoNameShort}</td>
    <td>{moment(item?.startTime).format('YYYYWW')}</td>
    <td>{moment(item?.startTime).format('HH:mm')}</td>
    <td>{moment(item?.endTime).format('HH:mm')}</td>
    <td>{item?.instructor?.name}</td>
  </tr>
}

const SkipMeetingBulk = ({
  close,
  ...props
}) => {

    const {
      listData,
      courseMeetingId,
      callback,
    } = props

    const [saveFunction, { data, loading }] = useMutation(SKIP_MEETING_BULK, {
      onCompleted() {
        close();
      }
    });

    const submit = () => {
      try {
        if(courseMeetingId.length <= 0) {
          throw Error("You didn't select any meetings");
        }

        saveFunction({ variables: {
          courseMeetingId: courseMeetingId
        }});
      } catch(err) {
        alert(err)
      }
    }

    return (<div className="ModalBody">
      <div className="ModalBody">
        <div className="heading-container">Following meetings will be skipped:</div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Course name</th>
                <th>Date</th>
                <th>Start</th>
                <th>End</th>
                <th>Instructor</th>
              </tr>
            </thead>
            <tbody>
              {
                listData.map((obj, index) => (
                  <MeetingItem key={index} item={obj.node} />
                ))
              }
            </tbody>
          </table>
        </div>

      </div>

      <footer>
        <div className="buttonContainer pullRight">
          <div className="buttonWrapper">
            {
              loading ? <img src={loadingImg} alt="Processing" className="processing_image"/> :
                <button type="submit" className="buttonItem save" onClick={() => submit()}>Lagre</button>
            }
          </div>
        </div>
      </footer>
    </div>)
}

export default SkipMeetingBulk;
