import { gql } from "apollo-boost";

const COURSE_MEETING_DATE = gql`
	mutation Assistant($courseMeetingId: Int!, $startTime: DateTime!, $substituteId: Int) {
	  assistant {
	    assistantCourseMeetingDate(courseMeetingId: $courseMeetingId, startTime: $startTime, substituteId: $substituteId) {
	      status
	      message
	    }
	  }
	}
`

export default COURSE_MEETING_DATE;
