import React from "react";
import { ProgressBar, ProgressImage, ProgressFlag } from "./ProgressBars";

export default function Nutrition({ week, dietScore }) {
  return (
    <section className="widget-section widget-food" data-featured="false">
      <h4>
        Næring <span>(snitt)</span>
      </h4>
      <ul>
        <ProgressBar
          value={week.diet?.totalEatenFruits}
          recommended={dietScore.fruit.recommendedGrams}
          min={dietScore.fruit.minGrams}
          max={dietScore.fruit.maxGrams}
          title="Frukt"
          unit="g"
          inline
        />
        <ProgressBar
          value={week.diet?.totalEatenVegetables}
          recommended={dietScore.vegetables.recommendedGrams}
          min={dietScore.vegetables.minGrams}
          max={dietScore.vegetables.maxGrams}
          title="Grønnsaker"
          unit="g"
          inline
        />
        <ProgressBar
          value={week.diet?.totalEatenCalcium}
          recommended={dietScore.calcium.recommendedMilligrams}
          min={dietScore.calcium.minMilligrams}
          max={dietScore.calcium.maxMilligrams}
          title="Kalsium"
          unit="mg"
          inline
        />
        <ProgressBar
          value={week.diet?.totalEatenFiber}
          recommended={dietScore.fiber.recommendedGrams}
          min={dietScore.fiber.minGrams}
          max={dietScore.fiber.maxGrams}
          title="Fiber"
          unit="g"
          inline
        />
        <ProgressBar
          value={week.diet?.totalEatenNuts}
          recommended={dietScore.nuts.recommendedGrams}
          min={dietScore.nuts.minGrams}
          max={dietScore.nuts.maxGrams}
          title="Nøtter"
          unit="g"
          inline
        />
        <ProgressFlag
          value={week.diet?.supplementPillWasTaken}
          recommended={dietScore.supplementPillTaken.minNumberOf}
          title="Tran/multivitamin"
          image="vitamin"
        />
        <ProgressImage
          value={week.diet?.numEntriesHeartFriendly}
          recommended={dietScore.heartFriendly.minNumberOf}
          title="Hjertevennlig"
          unit="stykk"
          image="heart"
        />
        <ProgressImage
          value={week.diet?.numGlassOfWater}
          recommended={dietScore.water.recommendedNumGlasses}
          title="Vann"
          unit="glass"
          image="glass"
        />
        <ProgressBar
          value={week.diet?.voucherMealKcal}
          recommended={0}
          min={0}
          max={25 * dietScore.vouchers.daily.recommendedNumberOf}
          title="Din utfording"
          unit="kcal"
          inline
        />
      </ul>
    </section>
  );
}
