import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'
var _ = require('underscore');

import Loading from '../atom/Loading';
import DiaryUserDetails from '../atom/DiaryUserDetails';
import DiaryMeasurements from '../atom/DiaryMeasurements';
import DiaryDietAndExercise from '../atom/DiaryDietAndExercise';
import DiaryCalculation from '../atom/DiaryCalculation';
import NotFound from '../atom/NotFound';

import GET_PARTICIPANT2 from '../query/get_participant2.js';
const PAGE = 'nye-dagbok'

import {
	getUser,
	getMeasurementsData,
	getDietAndExerciseData,
} from '../utils/GetCacheQuery'


const Breadcrumbs = ({
    user
}) => (
    <div className="headWrapper">
        <div className="headText">
            [NY] Dagbok oversikt
        </div>
				<nav className="breadcrumbs">
			    <ul>
			      <li><Link to="/">Hovedside</Link></li>
						<li><Link to={`/nye-kursdeltakere/${user.id}/`}>{user.name}</Link></li>
			      <li>[NY] Dagbok</li>
			    </ul>
			  </nav>
    </div>
)

const DiaryNew = ({
	match,
	...props
}) => {
	const currentDate = moment().startOf('week').add(1, 'day').format('YYYY-MM-DDT00:00:00')

	const {
		loading,
		data: {
			assistant: {
				assistantGetUser
			} = {}
		} = {}
	} = useQuery(GET_PARTICIPANT2, {
		variables: {
			userId: match.params.userId,
			currentDate: currentDate
		},
	});

	if (loading) {
		return <div className="pageContainer weightBook">
			<div className="diaryPage">
				<Loading />
			</div>
		</div>
	}

	if(!assistantGetUser) {
		return <div className="pageContainer weightBook">
			<div className="diaryPage">
				<NotFound
					children="Deltaker ikke funnet"
				/>
			</div>
		</div>
	}

	const user = getUser(assistantGetUser)
	const measurementsData = getMeasurementsData(assistantGetUser)
	const dietAndExerciseData = getDietAndExerciseData(assistantGetUser)

  return <div className="pageContainer weightBook">
		<Breadcrumbs
			user={user}
		/>
		<div className="diaryPage">
			<DiaryUserDetails
				page={'nye-kursedeltakere'}
				user={user}
			/>
			<DiaryMeasurements data={measurementsData} />
			<DiaryDietAndExercise user={user} data={dietAndExerciseData} page={PAGE}/>
			<DiaryCalculation
        exerciseHabits={user.exerciseHabits}
        activityLevel={user.activityLevel}
        activity={user.activityLevel}
        dietType={user.dietType}
        kcalMenu={user.kcalMenu}
        restingMetabolism={user.restingMetabolism}
        calculatedEnergyBeforeExercise={user.calculatedEnergyBeforeExercise}
        calculatedEnergyAfterExercise={user.calculatedEnergyAfterExercise}
        recommendedKcalIn={user.recommendedKcalIn}
      />
		</div>
	</div>
}

export default DiaryNew;
