import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Loading from '../atom/Loading';
import { useQuery } from '@apollo/client';
import moment from 'moment'
var _ = require('underscore');

import CMMonth from '../ui/CourseMeetings/CMMonth';
import GET_COURSE_MEETINGS from '../query/get_course_meetings.js';

const breadcrumbsLinks = []

const Breadcrumbs = ({ courseId }) => (
  <nav className="breadcrumbs">
    <ul>
      <li><Link to="/">Hovedside</Link></li>
      <li><Link to={`/course-calendar/`}>Kalendar</Link></li>
      <li><Link to={`/course-details/${courseId}`}>Kurs</Link></li>
      <li>Kursmøte</li>
    </ul>
  </nav>
)

const CourseMeetings = ({
  ...props
}) => {
  const [year, setYear] = useState(moment())

  const showPrevious = () => {
    setYear(moment(year).subtract(1, 'year'))
  }

  const showNext = () => {
    setYear(moment(year).add(1, 'year'))
  }

  const dateFrom = moment().date(1).month(0).year(year.format("YYYY")).format("YYYY-MM-D")
  const dateTo = moment().date(31).month(11).year(year.format("YYYY")).format("YYYY-MM-D")
  const courseId = props.match.params.id
  const {
      loading,
      data: {
        request: {
          grassistant:{
            assistantCourseMeeting: {
              edges  = []
            }={}
          }={}
        }={}
      }={},
      refetch
  } = useQuery(GET_COURSE_MEETINGS, {
      variables: {
          courseId: courseId,
          dateFrom: dateFrom,
          dateTo: dateTo
      },
  });

  const meetings = _.groupBy(edges, function(obj) {
    return moment(obj.node.startTime).format("MMMM")
  });

  return <div className="course-meeting-container">
      <h1 className="pageTitle">Kursmøte</h1>

      <Breadcrumbs courseId={courseId} />

      <div className="year-container">
        <div className="cmp-container">
          <div className="cm-pagination-item left" onClick={() => showPrevious()}><i className="icon-arrow-2-left"></i></div>
          <div className="cm-pagination-item year">{year.format("YYYY")}</div>
          <div className="cm-pagination-item right" onClick={() => showNext()}><i className="icon-arrow-2-right"></i></div>
        </div>
      </div>

      <div className="week-map">
        <div className="week-map-item first">Every week</div>
        <div className="week-map-item second">Every other week</div>
        <div className="week-map-item third">Every four weeks</div>
      </div>


      {loading && <Loading/>}
      {
          moment.months().map((month, index) => (
            <CMMonth key={index} month={month} year={moment(year).year()} results={meetings[month]}/>
          ))
      }



    </div>
}

export default CourseMeetings;
