import { gql } from "apollo-boost";

const SALES_STATS = gql`
query Assistant($region: String, $currentDate: String) {
      request {
          grassistant {
              assistantSalesStats(region:$region, currentDate: $currentDate)
          }
      }
}
`

export default SALES_STATS;
