import React, {Fragment, useState, useEffect} from 'react'
import { useQuery, useMutation } from '@apollo/client';
import Loading from '../atom/Loading';
import NotFound from '../atom/NotFound';
import { Link, Prompt } from 'react-router-dom';
import moment from 'moment'
var _ = require('underscore');
import queryString from 'query-string';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loadingImg from '../ui/SysIons/loading.svg';

import SAVE_SCHEDULE_PLAN from '../mutation/save_schedule_plan.js'
import { GET_SCHEDULE_PLAN_2 } from '../query/get_schedule_plan.js'

import CalendarTabs from '../atom/CalendarTabs';

const Breadcrumbs = ({ courseId }) => (
  <nav className="breadcrumbs">
    <ul>
      <li><Link to="/">Hovedside</Link></li>
      <li>Planlegger</li>
    </ul>
  </nav>
)

const NetCoursePlanner = ({
  ...props
}) => {
  const year = queryString.parse(props.location.search).year ? queryString.parse(props.location.search).year : moment().format("YYYY");
  const planType = 'nettcourse';

  const {
		loading,
    refetch,
		data: {
      request: {
        grassistant: {
          assistantCourseSchedule = {}
        } = {}
      }={}
    } = {}
	} = useQuery(GET_SCHEDULE_PLAN_2, {
    variables: {
      year: queryString.parse(props.location.search).year ? queryString.parse(props.location.search).year : moment().format("YYYY"),
      planType: planType
    },
    fetchPolicy: "cache-and-network"
  });

  const [saveFunction, { data: m_data, loading: m_loading, error: m_error }] = useMutation(SAVE_SCHEDULE_PLAN, {
    onCompleted() {
      toast("All saved");
      setChanged(false)
      refetch();
    },
    onError() {
      toast(m_error);
    }
  });

  const {
    meetingCount="",
    data=""
  } = assistantCourseSchedule ? assistantCourseSchedule : {};

  const mCount = JSON.parse(meetingCount || '{}')
  const dataSet = JSON.parse(data || '{}')

  const {
    schedule=[]
  } = dataSet;

  const [day, setDay] = useState({});
  const [changed, setChanged] = useState(false)


  const setInitDay = async () => {
    const day = {}
    schedule.forEach(function(obj) {
      obj['meetingCount'] = mCount[obj.date] ? mCount[obj.date] : false
      day[obj['date']] = obj;
    });

    setDay(day)
    setChanged(false)
  }

  useEffect( () => {
     setInitDay(loading)
   }, [ loading ]
  );

  const selectDay = (e, selectedDay, data) => {
    e.preventDefault()
    e.stopPropagation();


    if(data) {
      if(!data['meetingCount']) {
        e.currentTarget.dataset.blacklisted = e.currentTarget.dataset.blacklisted === 'true' ? 'false' : 'true';
        day[selectedDay]['blacklisted'] = e.currentTarget.dataset.blacklisted === 'true';

        setDay(day)
        setChanged(true)
      } else {
        toast("You already have meeting on that day!");
      }
    }

  }

  const saveChanges = () => {
    const blacklist = Object.keys(day).filter(function(i) {
      if (day[i].blacklisted) {
        return i
      }
    });

    const variables={
      "year": year,
      "blacklist": JSON.stringify(blacklist),
      "planType": planType
    }

    saveFunction({ variables: variables});
  }

  return <>
    <Prompt
      when={changed}
      message='You have unsaved changes, are you sure you want to leave the page?'
    />
    <div className="PageContainer course-meeting-container">
      <ToastContainer />
      <div className="headWrapper">
        <div className="headText">Planlegger</div>
        <Breadcrumbs></Breadcrumbs>
      </div>
      <div className="PageBody">


        <CalendarTabs
          year={year}
          planType={planType}
        />

        <div className="NavigationGrid">
          <div className="NavigationItem">
            <Link to={`/nettcourse-planner?year=${moment(year, 'YYYY').subtract(1, 'years').format("YYYY")}`}>
              <i className="icon-arrow-2-left"></i>
            </Link>
          </div>
          <div className="NavigationItem YearItem">
            {year}
          </div>
          <div className="NavigationItem">
            <Link to={`/nettcourse-planner?year=${moment(year, 'YYYY').add(1, 'years').format("YYYY")}`}>
              <i className="icon-arrow-2-right"></i>
            </Link>
          </div>
        </div>


        {loading && <div className="loading_wrapper"><Loading/></div>}

        <div className="MapGrid">
          <div className="MapItem info-meeting-one">Every week</div>
          <div className="MapItem info-meeting-two">Every other week</div>
          <div className="MapItem info-meeting-four">Every four weeks</div>
          <div className="MapItem info-blacklist">No meetings</div>
        </div>

        {!loading && schedule.length === 0 ? <Fragment>
            <NotFound>The course schedule for this year is not currently available.</NotFound>
          </Fragment> : <Fragment>
        {
          moment.months().map((month, index) => (

            <div key={index} className="MonthContainer">
              <div className="MonthName">{month}</div>
              <Month
                year={year}
                month={month}
                day={day}
                selectDay={selectDay}
              />
            </div>
          ))
        }
        </Fragment>}

      </div>
      {m_loading ? <div className="loadingWrapper">
        <img src={loadingImg} alt="Processing" />
      </div> : <div className="saveButton" onClick={()=>saveChanges()}>
        <i className="buttonIcon icon-check"></i>
      </div>}
    </div>
  </>
}


const Month = ({month, year, day, selectDay}) => {

  const currDate = moment().year(year).month(month);
  const cl = moment().year(year).month(month);

  const week_begining = currDate.startOf('month').isoWeekday();
  const days_in_month = currDate.daysInMonth();

  function getWeeks(_, item) {
    return cl.date(item+1).isoWeek()
  }

  const week_range = [...new Set(Array(days_in_month).fill().map(getWeeks))]

  return <div className="month-container">
    <div className="cm-grid-result">
      <div className="cm-item-week">
        <div className="cm-month-header">Week</div>
        {week_range.map((week, i) => (
          <div key={i} className="cm-days-dimension">{week}</div>
        ))}
      </div>
      <div className="cm-item-months">
        <div className="cmm-grid cm-month-header">
          <div className="cm-month-header-item">Mon</div>
          <div className="cm-month-header-item">Tue</div>
          <div className="cm-month-header-item">Wed</div>
          <div className="cm-month-header-item">Thur</div>
          <div className="cm-month-header-item">Fri</div>
          <div className="cm-month-header-item">Sat</div>
          <div className="cm-month-header-item">Sun</div>
        </div>
        <div className="cmm-grid cm-month-body">
          {Array(week_begining-1).fill().map((_, i) => (
            <div key={i} className="cm-empty cm-days-dimension"></div>
          ))}
          {Array(days_in_month).fill().map((_, i) => (
            <div
              key={i}
              className={`cm-days-dimension date-field`}

              onClick={(e) => selectDay(e, cl.date(i+1).format("YYYY-MM-DD"), day[cl.date(i+1).format("YYYY-MM-DD")])}
              data-meeting-one={day[cl.date(i+1).format("YYYY-MM-DD")] && (day[cl.date(i+1).format("YYYY-MM-DD")]['meeting'] && !day[cl.date(i+1).format("YYYY-MM-DD")]['meeting_2'] && !day[cl.date(i+1).format("YYYY-MM-DD")]['meeting_4'])}
              data-meeting={day[cl.date(i+1).format("YYYY-MM-DD")] && day[cl.date(i+1).format("YYYY-MM-DD")]['meeting']}
              data-meeting-two={day[cl.date(i+1).format("YYYY-MM-DD")] && day[cl.date(i+1).format("YYYY-MM-DD")]['meeting_2']}
              data-meeting-four={day[cl.date(i+1).format("YYYY-MM-DD")] && day[cl.date(i+1).format("YYYY-MM-DD")]['meeting_4']}
              data-blacklisted={day[cl.date(i+1).format("YYYY-MM-DD")] && day[cl.date(i+1).format("YYYY-MM-DD")]['blacklisted']}
              data-havemeeting={day[cl.date(i+1).format("YYYY-MM-DD")] && day[cl.date(i+1).format("YYYY-MM-DD")]['meetingCount'] > 0}
            >
              <div className="day-no" >
                {cl.date(i+1).format("DD")}
              </div>

              {day[cl.date(i+1).format("YYYY-MM-DD")] && day[cl.date(i+1).format("YYYY-MM-DD")]['meetingCount'] > 0 && <div className="meetin-no">
                {day[cl.date(i+1).format("YYYY-MM-DD")] && day[cl.date(i+1).format("YYYY-MM-DD")]['meetingCount']}
              </div>}
            </div>
          ))}

        </div>
      </div>
    </div>


  </div>
}

export default NetCoursePlanner
