import { gql } from "apollo-boost";

const REPORT_SUBMIT = gql`
	mutation Assistant($id: Int!) {
	  assistant {
	    assistantReportSubmit(id: $id) {
	      status
				message
	    }
	  }
	}
`

export default REPORT_SUBMIT;
