import { gql } from "apollo-boost";

const REGISTER_WEIGHT = gql`
mutation Assistant(
	$registrationId: Int
	$userId: Int
	$dateTime: DateTime
	$data: JSONString
) {
	assistant {
		assistentRegisterWeight(
			registrationId: $registrationId
			userId: $userId
			dateTime: $dateTime
			data: $data
		) {
			status
		}
	}
}
`

export default REGISTER_WEIGHT;
