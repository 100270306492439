import React, { Fragment, useState } from 'react'
import { useMutation } from '@apollo/client';
import loadingImg from '../../SysIons/loading.svg';
import NoResults from '../NoResults';
import moment from 'moment'

import COURSE_MUTATION from '../../../mutation/course_mutation.js';

const RoedeCourseTime = ({
  close,
  ...props
}) => {

    const {
      courseId,
      meetingStartTime,
      meetingWeekday,
    } = props

    const [meetingStartTime1, setMeetingTime] = useState(meetingStartTime);
    const [meetingWeekday1, setWeekday] = useState(meetingWeekday)

    const [saveFunction, { data, loading }] = useMutation(COURSE_MUTATION, {
      onCompleted() {
        close();
      }
    });

    const handleWeekday = (e) => {
      const {
        target: {
          value
        }
      } = e;

      setWeekday(value)
    }

    const hendleStartTime = (e) => {
      const {
        target: {
          value
        }
      } = e;

      setMeetingTime(moment(value, 'HH:mm'));
    }

    const submit = () => {
      try {
        if(!meetingWeekday1 || meetingWeekday1 === '' || parseInt(meetingWeekday) <= 0) {
          throw Error('You need to set day of the week.');
        }
        if(!meetingStartTime1 || meetingStartTime1 === '') {
          throw Error('You need to set start hour of meeting.');
        }

        saveFunction({ variables: {
          'startTime': meetingStartTime1.format('HH:mm'),
          'meetingWeekday': meetingWeekday1,
          'courseId': courseId,
        }});
      } catch(err) {
        alert(err)
      }
    }

    return (<div className="ModalBody">
      <div className="ModalBody">
        <div className="Fieldset">
          <label>Weekday</label>
          <select name="meeting_weekday" onChange={handleWeekday} value={meetingWeekday1}>
            <option value={1} value="1">Mandag</option>
            <option value={2} value="2">Tirsdag</option>
            <option value={3} value="3">Onsdag</option>
            <option value={4} value="4">Torsdag</option>
            <option value={5} value="5">Fredag</option>
            <option value={6} value="6">Lørdag</option>
            <option value={7} value="7">Søndag</option>
          </select>
        </div>



        <div className="Fieldset">
          <label>Start time</label>
          <input type="time" value={meetingStartTime1.format('HH:mm')} onChange={(e) => hendleStartTime(e)} />
        </div>
      </div>

      <footer>
        <div className="buttonContainer pullRight">
          <div className="buttonWrapper">
            {
              loading ? <img src={loadingImg} alt="Processing" className="processing_image"/> :
                <button type="submit" className="buttonItem save" onClick={() => submit()}>Lagre</button>
            }
          </div>
        </div>
      </footer>
    </div>)
}

export default RoedeCourseTime;
