import React, { Fragment, useState } from 'react';
import moment from 'moment'
import { Link } from 'react-router-dom'

import NoUsersMembership from '../../atom/NoUsersMembership';

const NextReport = ({
  nextMeeting,
  ...props
}) => {

    return <div className="grid-column">
      {nextMeeting.length > 0 ? <NextReportList nextMeeting={nextMeeting} /> : <NoUsersMembership>Ingen ny planlagt veiledning</NoUsersMembership>}
    </div>
}

const NextReportList = ({
  nextMeeting,
  ...props
}) => {



  return <div className="list-container">
    <div className="list-item">
      {
        nextMeeting.map((obj, index) =>
          <div className="url-wrapper" key={index}>
            Neste veiledning: <Link to={`/nettkursukerapport/${moment(obj.week).format('YYYYW')}/${obj.report_id}/`}>{obj.week}</Link>
          </div>
        )
      }
    </div>
  </div>
}

// assistant/nettkursukerapport/202044/522/
// <div className="url-wrapper" key={index}>
//
//   <Link to={`/nettkursukerapport/${obj.week}/${obj.report_id}/`}>{obj.week}</Link>
// </div>

export default NextReport;
