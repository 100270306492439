import { gql } from "apollo-boost";

const GET_COURSE_MEETINGS = gql`
query Assistant($courseId: Int, $dateFrom: String, $dateTo: String) {
  request {
    grassistant {
      assistantCourseMeeting(courseId: $courseId, dateFrom: $dateFrom, dateTo: $dateTo) {
        edges {
          node {
            id
            courseMeetingId
            type
            data
            startTime
            endTime
            attendanceCount
            courseId
            course{
              id: courseId
              autoNameShort
              address
            }
          }
        }
      }
    }
  }
}
`

export default GET_COURSE_MEETINGS;
