import React, { Fragment, useState, useEffect } from 'react';
import Modal from '../../ui/Modal';
import _ from 'underscore';
import { useMutation } from '@apollo/client';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import sanitizeHtml from "sanitize-html";
import { user as instructor } from '../../container/Auth';

import ASSISTANT_SEND_EMAIL from '../../mutation/assistant_send_email.js';

const EmailButton = ({
  userList,
  children=null,
  disabled=false,
  ...props
}) => {

    const sanitizeConf = {
      allowedTags: ["b", "i", "em", "strong", "a", "p", "h1", "br", "ul", "ol", "li"],
      allowedAttributes: { a: ["href"] }
    };

    const [emailBody, handleEmailBody] = useState("");
    const [subject, handleSubject] = useState("");
    const [emailTags, handleEmailTags] = useState([]);
    const [attachment, setAttachment] = useState([]);
    const [totalSize, setTotalSize] = useState(0);

    const [saveFunction, { data, loading, error: mutationError }] = useMutation(ASSISTANT_SEND_EMAIL);

    const getUserList = () => {
      var userId = _.map(_.filter(emailTags, function(obj) { return obj.emailAllowed || obj.is_active}), function(obj) {
        return obj.userId;
      });

      return ['production', 'stage'].includes(process.env.NODE_ENV) ? userId : [4900976];
    }

    const getEmailText = () => {
      return sanitizeHtml(emailBody, {
        allowedTags: [],
        allowedAttributes: {}
      });
    }

    const getEmailHtml = () => {
      return sanitizeHtml(emailBody, sanitizeConf)
    }

    const sendEmail = (close) => {
      try {
        const variables = {
          userId: getUserList(),
          subject: subject,
          emailText: getEmailText(),
          emailHtml: getEmailHtml(),
          emailAttachment: _.map(attachment, function(x) { return JSON.stringify(x)}),
        }

        if (getUserList().length <= 0) {
            throw "You need to add emails of users."
        }

        const res = saveFunction({
          variables:variables
        })

        res.then(({ data }) => {
          close()
        }).catch(function(err) {
          const {
            message
          } = err
          toast(message)
        })
      } catch(err) {
        toast(err);
        throw err
      }
    }

    useEffect(() => {
      handleEmailTags(userList)
    }, [userList])

    const attachmentHandler = (e) => {
      e.preventDefault();

      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
        var sizeOfFiles = totalSize + file.size

        if(sizeOfFiles <= 26214400) {
          setAttachment([...attachment, {
            "type": file.type,
            "name": file.name,
            "content": reader.result.split(',')[1]
          }])

          setTotalSize(sizeOfFiles)
        } else {
          toast("Wrong file size");
        }
      }

      reader.readAsDataURL(file);
    }

    const removeAttachment = (obj) => {
        const arr = attachment.filter(a => a.name !== obj.name);
        setAttachment(arr);
    }

    return <div className="button-item  email-button-container">
        <Modal
          type={"email_form"}
          modalHeader={`Ny E-post`}
          sendHandler={sendEmail}
          callback={() => function() {}}
          disabled={disabled}

          emailTags={emailTags}
          handleEmailTags={handleEmailTags}
          emailBody={emailBody}
          handleEmailBody={handleEmailBody}
          subject={subject}
          handleSubject={handleSubject}
          attachment={attachment}
          attachmentHandler={attachmentHandler}
          removeAttachment={removeAttachment}
        >
          {
            children ? children : <div className="buttonWrapper">
              <i className="buttonIcon icon-email-send"></i>
              <div className="buttonText">E-post</div>
            </div>
          }
        </Modal>



    </div>
}

export default EmailButton;
