import { gql } from "apollo-boost";

const ASSISTANT_SKIP_MEETING = gql`
	mutation Assistant($membershipId: [Int]!, $courseMeetingId: Int!) {
	  assistant {
	    assistantSkipMeeting(membershipId: $membershipId, courseMeetingId: $courseMeetingId) {
	      status
	      message
	    }
	  }
	}
`

export default ASSISTANT_SKIP_MEETING;
