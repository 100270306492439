import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { activity } from '../../../config';

const DiaryCalculation = ({...props}) => {
    const getActivityLevel = (activityLevel) => {
      try {
        return activity.activities[activityLevel];
      }
      catch(err) {
        return  "n/a";
      }
    }

    const getExerciseHabits = (exerciseHabits) => {
      try {
        return activity.exerciseHabits[exerciseHabits];
      }
      catch(err) {
        return  "n/a";
      }
    }

    const round5 = (x) => {
      return (x % 5) >= 2.5 ? parseInt(x / 5) * 5 + 5 : parseInt(x / 5) * 5;
    }

    return <div className="calculations-container">
      <div className="diarySectionTitle">Beregning</div>
      <div className="diarycalculationSection">
        <ul className="diaryMeasurementsOverview">
          <li>
            Hvilestoffskifte: <span><strong>{props.restingMetabolism}</strong> kcal</span>
          </li>
          <li>
            Daglig energibehov: <span><strong>{props.calculatedEnergyAfterExercise}</strong> kcal</span>
          </li>
          <li>
            Anbefalt energiinntak: <span><strong>{props.recommendedKcalIn}</strong> kcal</span>
          </li>
          <li>
            Meny: <span><strong>{props.kcalMenu}</strong></span>
          </li>
          <li>
            Hverdagsaktivitet: <span><strong>{getActivityLevel(props.activityLevel)}</strong></span>
          </li>
          <li>
            Mosjonsvaner/​treningsvaner: <span><strong>{getExerciseHabits(props.exerciseHabits)}</strong></span>
          </li>
        </ul>
      </div>
    </div>
}

export default DiaryCalculation;
