import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import GET_COURSE from '../query/get_course.js'

import Loading from '../atom/Loading'
import ActionFooter from '../atom/ActionFooter'
import CourseMeetings from '../ui/CourseMeetings'
import CourseParticipants from '../ui/CourseParticipants'
import NotFound from '../atom/NotFound'

import moment from 'moment'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'

import Modal from '../ui/Modal'
const PAGE = 'course-details'

const Breadcrumbs = ({ courseId, courseName }) => (
  <div className="headWrapper">
    <div className="headText">Kurs</div>
    <nav className="breadcrumbs">
      <ul>
        <li>
          <Link to="/">Hovedside</Link>
        </li>
        <li>
          <Link to={`/course-calendar/`}>Kalendar</Link>
        </li>
        <li>
          <Link to={`/course-details/${courseId}`}>{courseName}</Link>
        </li>
      </ul>
    </nav>
  </div>
)

const dayOfWeek = {
  1: 'Mandag',
  2: 'Tirsdag',
  3: 'Onsdag',
  4: 'Torsdag',
  5: 'Fredag',
  6: 'Lørdag',
  7: 'Søndag',
}

const RoedeCourseDetails = ({ ...props }) => {
  const [copied, setCopied] = useState(false)
  const [showCourseParticipants, setShowCourseParticipants] = useState(false)
  const [showCourseMeetings, setShowCourseMeetings] = useState(false)
  const [userList, setUserList] = useState([])

  const selectItem = (e, obj) => {
    if (userList.filter((x) => x.userId == obj.userId).length > 0) {
      setUserList(userList.filter((x) => x.userId != obj.userId))
    } else {
      setUserList([...userList, obj])
    }
  }

  const selectAll = (e, obj) => {
    const participantsList = _.map(obj, function (x) {
      return x.node
    })

    if (e.target.checked) {
      setUserList(participantsList)
    } else {
      setUserList([])
    }
  }

  const courseId = props.match.params.id

  const {
    loading,
    data: {
      request: { grassistant: { assistantGetRoedeCourse: course } = {} } = {},
    } = {},
    refetch,
  } = useQuery(GET_COURSE, {
    variables: {
      courseId: courseId,
    },
  })

  if (copied) {
    setTimeout(
      function () {
        setCopied(false)
      }.bind(this),
      1600
    )
  }
  if (loading) {
    return <Loading />
  }
  if (!course) {
    return
    ;<div className="course-container">
      <Breadcrumbs
        courseId={courseId}
        courseName={!!course && course.name ? course.name : courseId}
      />
      <NotFound>Cannot find the course.</NotFound>
    </div>
  }
  console.log('course', course)
  const instructorSlug =
    course?.instructor?.slug || '<HAR IKKE SLUG KONTAKT SUPPORT>'
  const url =
    'https://www.roede.com/veiledere/' + instructorSlug + '/kurs/' + courseId
  const noContractUrl = url + '?membershipTypeId=18'

  const courseTypeId = course.courseTypeId
  const hasNoContractUrl =
    [3, 21, 22, 23, 24, 46, 48, 52, 61, 62, 63].indexOf(courseTypeId) !== -1
  return (
    <div className="course-container">
      <Breadcrumbs
        courseId={courseId}
        courseName={!!course && course.name ? course.name : courseId}
      />
      <>
        <div className="course-header">
          <div className="ch-wrapper">
            <div className="ch-item course-name">
              <span className="strong">Name:</span> {course.name}
            </div>
            <div className="ch-item course-type">
              <span className="strong">Course type:</span> {course.autoName}
            </div>
            <div className="ch-item course-address">
              <span className="strong">Address:</span> {course.address}
            </div>
          </div>
          <div className="ch-wrapper">
            <div className="ch-item course-num-participant">
              <span className="strong">Status:</span>{' '}
              {course.active ? 'active' : 'disabled'}
            </div>
            <div className="ch-item course-num-participant ch-grid">
              <div className="ch-grid-item position-left">
                <span className="strong">Maximum number of particiapnts:</span>{' '}
                {course.maxNumParticipants}
              </div>
              <div className="ch-grid-item position-right">
                <Modal
                  type={'participant_no'}
                  from={'participant_no'}
                  courseId={course.courseId}
                  maxNumParticipants={course.maxNumParticipants}
                  callback={refetch}
                  modalHeader="Change Maximum Number of Participants"
                >
                  <div className="courseActionItem">
                    <div className="buttonWrapper">
                      <i className="buttonIcon buttonIcon icon-edit-1"></i>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
            <div className="ch-item course-num-participant ch-grid">
              <div className="ch-grid-item position-left">
                <span className="strong">Time:</span>{' '}
                {dayOfWeek[course.meetingWeekday]} {course.meetingStartTime} -{' '}
                {course.meetingEndTime}
              </div>
              <div className="ch-grid-item position-right">
                {
                  <Modal
                    type={'meeting_time_change'}
                    from={'meeting_time_change'}
                    courseId={course.courseId}
                    meetingWeekday={course.meetingWeekday}
                    meetingStartTime={moment(
                      course.meetingStartTime,
                      'HH:mm:ss'
                    )}
                    modalHeader="Edit course start time"
                    callback={refetch}
                  >
                    <div className="buttonWrapper">
                      <i className="buttonIcon icon-edit-1"></i>
                    </div>
                  </Modal>
                }
              </div>
            </div>

            <div className="ch-item course-num-participant">
              <span className="strong">Free spots:</span>{' '}
              {course.freeSpotsAsTextInternal}
            </div>
            <div className="ch-item course-sale-from">
              <span className="strong">Sales status:</span> {course.forSaleText}
            </div>
          </div>
          <div className="ch-wrapper">
            <div className="ch-item course-copy">
              <div className="linkText">
                <a href={url} target="_blank">
                  <strong>Salgsside: </strong>
                  {url}
                </a>
              </div>
              <div className="copyLink">
                <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
                  <div className="buttonWrapper">
                    <div className="buttonText">kopier lenke</div>
                    <i className="buttonIcon icon-copy-plain-text"></i>
                  </div>
                </CopyToClipboard>
              </div>
              {copied ? <div className="copied">Kopiert</div> : null}
            </div>
            {hasNoContractUrl && (
              <div className="ch-item course-copy">
                <div className="linkText">
                  <a href={noContractUrl} target="_blank">
                    Salgsside ingen binding: {noContractUrl}
                  </a>
                </div>
                <div className="copyLink">
                  <CopyToClipboard
                    text={noContractUrl}
                    onCopy={() => setCopied(true)}
                  >
                    <div className="buttonWrapper">
                      <div className="buttonText">kopier lenke</div>
                      <i className="buttonIcon icon-copy-plain-text"></i>
                    </div>
                  </CopyToClipboard>
                </div>
                {copied ? <div className="copied">Kopiert</div> : null}
              </div>
            )}
          </div>
        </div>

        <div className="course-action-container">
          <div className="course-action-wrapper">
            <div className="courseActionItem">
              <Link
                className="buttonWrapper"
                to={`/kurs/${courseId}/nye-vektbok/`}
              >
                <i className="buttonIcon icon-account-book-1"></i>
                <div className="buttonText">Vektbok</div>
              </Link>
            </div>
          </div>
        </div>

        <div className="course-participant-list">
          {!showCourseParticipants ? (
            <div className="cp-headers">
              <div className="cp-header-item">
                <div className="cp-text">Kursdeltakere</div>
                <div
                  className="cp-action-item"
                  onClick={() =>
                    setShowCourseParticipants(!showCourseParticipants)
                  }
                >
                  Vis kursdeltakere
                </div>
              </div>
            </div>
          ) : (
            <CourseParticipants
              courseId={course.courseId}
              closeParticipants={setShowCourseParticipants}
              userList={userList}
              selectItem={selectItem}
              selectAll={selectAll}
              page={PAGE}
              url={'nye-kursdeltakere'}
            ></CourseParticipants>
          )}
        </div>

        <div className="course-meetings">
          {!showCourseMeetings ? (
            <div className="cm-headers">
              <div className="cm-header-item cmg-text">Kursmøter</div>
              <div
                className="cm-header-item show-course-meetings"
                onClick={() => setShowCourseMeetings(!showCourseMeetings)}
              >
                Vis kursmøter
              </div>
            </div>
          ) : (
            <CourseMeetings
              courseId={course.courseId}
              closeCourseMeetings={() => setShowCourseMeetings(false)}
            ></CourseMeetings>
          )}
        </div>

        {userList.length > 0 && (
          <ActionFooter
            userList={userList}
            showEmail={true}
            showSms={true}
            showPause={true}
            showMoveUser={true}
          />
        )}
      </>
    </div>
  )
}

export default RoedeCourseDetails
