import React, { useState, Fragment, useRef } from 'react';
import ReactDOM from 'react-dom';

import ChangeParticipantNo from './ChangeParticipantNo';
import RoedeCourseTime from './RoedeCourseTime';
import ChangeDate from './ChangeDate';
import SkipMeetingNotification from './SkipMeetingNotification';
import SkipMeetingBulk from './SkipMeetingBulk';
import SubstituteInstructorBulk from './SubstituteInstructorBulk'
import WeightForm from './WeightForm';
import WeightBookGoals from './WeightBookGoals';
// import MoveMembership from './MoveMembership'
import MoveMembership2 from './MoveMembership2'


import EmailForm from './EmailForm';
import SmsForm from './SmsForm';


const Modal = ({
  disabled=false,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const childRef = useRef();

  const showModal = () => {
    console.log(disabled);

    if (disabled) {
      return
    }

    setShow(!show);
  }

  const closeModal = () => {
    const {
      callback
    } = props;

    setShow(false);
    callback();
  }

  const  closeAndSave = () => {
    if (type === 'weighing') {
      childRef.current.saveAndClose()
    }

    closeModal();
  }

  const {
    type
  } = props

  return (
    <Fragment>
      <div
        className={props.classButton}
        onClick={showModal}
      >{props.children}</div>

      {
        show  && type === 'participant_no' &&
          ReactDOM.createPortal(
            <ModalContainer headingText={props.modalHeader} close={closeModal} type='participant_no'>
              <ChangeParticipantNo
                {...props}
                close={closeModal}
              ></ChangeParticipantNo>
            </ModalContainer>,
            document.getElementById('modal-root')
          )
      }

      {
        show  && type === 'meeting_time_change' &&
          ReactDOM.createPortal(
            <ModalContainer headingText={props.modalHeader} close={closeModal} type='meeting_time_change'>
              <RoedeCourseTime
                {...props}
                close={closeModal}
              ></RoedeCourseTime>
            </ModalContainer>,
            document.getElementById('modal-root')
          )
      }

      {
          show  && type === 'course_meeting' && ReactDOM.createPortal(
            <ModalContainer headingText={props.modalHeader} close={closeModal} type='course_meeting'>
              <ChangeDate
                {...props}
                close={closeModal}
              ></ChangeDate>
            </ModalContainer>,
            document.getElementById('modal-root')
          )

      }

      {
        show  && type === 'skip_meeting' &&
          ReactDOM.createPortal(
            <ModalContainer headingText={props.modalHeader} close={closeModal} type='skip_meeting'>
              <SkipMeetingNotification {...props} close={closeModal}></SkipMeetingNotification>
            </ModalContainer>,
            document.getElementById('modal-root')
          )
      }
      {
        show  && type === 'skip_meeting_bulk' &&
          ReactDOM.createPortal(
            <ModalContainer headingText={props.modalHeader} close={closeModal} type='skip_meeting'>
              <SkipMeetingBulk {...props} close={closeModal}></SkipMeetingBulk>
            </ModalContainer>,
            document.getElementById('modal-root')
          )
      }
      {
        show  && type === 'substitute_instructor_bulk' &&
          ReactDOM.createPortal(
            <ModalContainer headingText={props.modalHeader} close={closeModal} type='skip_meeting'>
              <SubstituteInstructorBulk {...props} close={closeModal}></SubstituteInstructorBulk>
            </ModalContainer>,
            document.getElementById('modal-root')
          )
      }


      {
        show  && type === 'weighing' &&
          ReactDOM.createPortal(
            <ModalContainer headingText={props.modalHeader} close={closeModal} type='weighing' closeAndSave={closeAndSave}>
              <WeightForm
                {...props}
                close={closeModal}
                ref={childRef}
              ></WeightForm>
            </ModalContainer>,
            document.getElementById('modal-root')
          )
      }

      {
        show  && type === 'goals' &&
          ReactDOM.createPortal(
            <ModalContainer headingText={props.modalHeader} close={closeModal} type='goals'>
              <WeightBookGoals
                {...props}
                close={closeModal}
              ></WeightBookGoals>
            </ModalContainer>,
            document.getElementById('modal-root')
          )
      }

      {
        show  && type === 'email_form' &&
          ReactDOM.createPortal(
            <MessageContainer close={closeModal} headingText={props.modalHeader} {...props}>
              <EmailForm
                {...props}
                close={closeModal}
              />
            </MessageContainer>,
            document.getElementById('modal-root')
          )
      }

      {
        show  && type === 'sms_form' &&
          ReactDOM.createPortal(
            <MessageContainer close={closeModal} headingText={props.modalHeader} {...props}>
              <SmsForm
                {...props}
                close={closeModal}
              />
            </MessageContainer>,
            document.getElementById('modal-root')
          )
      }

      {
        show  && type === 'move_membership' &&
          ReactDOM.createPortal(
            <ModalContainer headingText={props.modalHeader} close={closeModal} type='move_membership'>
              <MoveMembership2
                {...props}
                close={closeModal}
              ></MoveMembership2>
            </ModalContainer>,
            document.getElementById('modal-root')
          )
      }


    </Fragment>
  )

}






const ModalContainer = ({
  ...props
}) => {


  return (
    <div className="ModalOverlay is-open">
      <div className="WeightModalForm">
        <div className={`ModalHeader ${props.type}`}>
          <div className="header_item close_wrapper">
            {
              props.type === 'weighing' ? <div className="close_button" onClick={props.closeAndSave}>
                  <i className="action-icons icon-cancel-circle"></i>
                  <div className="textWrapper">Lagre og lukk</div>
                </div> : <div className="close_button" onClick={props.close}>
                <i className="action-icons icon-cancel-circle"></i>
                <div className="textWrapper">Lukk</div>
              </div>
            }
          </div>
          <div className="header_item name_wrapper">
            {props.headingText}
          </div>

          {
            props.type === 'weighing' && <div className="header_item cancel_wrapper">
              <div className="cancel_button close_button" onClick={props.close}>
                <i className="action-icons icon-cancel-circle"></i>
                <div className="textWrapper">Avbryt</div>
              </div>
            </div>
          }
        </div>
        <div className="ModalBody ModalChangeMeetingDate">
          {props.children}
        </div>
      </div>
    </div>
  )
}

const MessageContainer = ({
  ...props
}) => {

  const sendAction = () => {
    try {
      props.sendHandler(props.close);
    } catch(err) {
      console.log(err);
    }
  }

  return (
    <div className="ModalOverlay is-open">
      <div className="MessagesModalForm">
        <div className="ModalHeader">
          <div className="header_item close_wrapper">
            <div className="close_button buttonWrapper" onClick={props.close}>
              <i className="action-icons icon-cancel-circle"></i>
              <div className="textWrapper margin_left">Lukk</div>
            </div>
          </div>
          <div className="header_item name_wrapper">
            {props.headingText}
          </div>
          <div className="header_item send_action">
            <div className="buttonWrapper sendButton" onClick={sendAction}>
              <div className="textWrapper margin_right">Send</div>
              <i className={`action-icons ${props.type === 'email_form' ? 'icon-email-send' : 'icon-message-1'}`}></i>
            </div>
          </div>
        </div>
        <div className="ModalBody ModalChangeMeetingDate">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default Modal;
