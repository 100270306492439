import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment'

const DROP_IN_SLUG = 'drop-in'
const DROP_IN_TEXT = 'Drop in'

import {weightBookCourseUri} from '../../utils/WeightBookCourseUri'

const WeightBookGroupHeader = ({
  usersGroup,
  entry,
  page,
  match,
  ...props
}) => {
    return <Link title="Oppsummering" to={weightBookCourseUri(page, match.params.date, entry != 0 ? `${moment(entry, "HH:mm:ss").format("HH:mm")}` : DROP_IN_SLUG, usersGroup[entry])} className="wbp-tr wbp-tr-link">
      <div className="wbp-th">{entry !== DROP_IN_TEXT ? `${moment(entry, "HH:mm:ss").format("HH:mm")}` : `${DROP_IN_TEXT}`}</div>
      <div className="wbp-th">{usersGroup[entry].length} deltakere</div>
      <div className="wbp-th arrow" />
    </Link>
}

export default WeightBookGroupHeader;
