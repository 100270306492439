import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../../ui/Modal';
import moment from 'moment'

import {
  sortMeasurements,
  formatAmount
} from '../../utils/GetCacheQuery'

const MeasurementsList = ({
  measurements,
  callback,
  user,
  page,
  match,
  ...props
}) => {
    const parsedMeasurements = measurements.map((measurement) => ({
      ...measurement,
      week: +measurement.week,
      weight: +measurement.weight || 0,
    }));

    const pageSize = 6;
    const [count, setCount] = useState(pageSize);
    const [showMore, setShowMore] = useState(parsedMeasurements.length > pageSize);

    const [sort, setSort] = useState({
      date: 'desc',
      weight: 'asc',
      weekPursuit: 'asc',
    });

    const callShowMore = () => {
      setCount(count+pageSize);
      setShowMore(parsedMeasurements.length > count+pageSize)
    }

    const sortBy = field => {
      const sortMe = {
        [field]: null,
        ...sort,
        [field]: (sort[field] === 'asc') ? 'desc' : 'asc',
      }

      setSort(sortMe);
    }

    return <section className="wbr-stats">
      <h3>Ukentlig utvikling</h3>
      <div className="wbr-table">
        <div className="wbr-tr">
          <div className="wbr-th" data-sort={sort.week} data-active={Object.keys(sort)[0] === 'week'}>
            <button onClick={() => sortBy('date')}>Uke</button>
          </div>
          <div className="wbr-th" data-sort={sort.weight} data-active={Object.keys(sort)[0] === 'weight'}>
            <button onClick={() => sortBy('weight')}>Vekt</button>
          </div>
          <div className="wbr-th" data-sort={sort.weekPursuit} data-active={Object.keys(sort)[0] === 'weekPursuit'}>
            <button onClick={() => sortBy('weekPursuit')}>Trivsel</button>
          </div>
          <div className="wbr-th">Kommentar</div>
          <div className="wbr-th">&nbsp;</div>
        </div>
        {sortMeasurements(parsedMeasurements, sort).slice(0, count).map(measurement => (
          <div key={measurement.id} className="wbr-tr">
            <div className="wbr-td">{moment(measurement.date).format("YYYY/W")}</div>
            <div className="wbr-td">
              {(measurement.absence && measurement.weight === 0) && "fravær"}
              {(!measurement.absence && measurement.weight === 0) && 'n/a'}
              {(measurement.weight > 0) && formatAmount(measurement.weight)}
            </div>
            <div className="wbr-td">{measurement.weekPursuit}</div>
            <div className="wbr-td">              
              <Link to="#" target="_self">
                <span className="inline-text">{measurement.comment ? measurement.comment : ""}</span>
              </Link>
            </div>
            <div className="wbr-td">
              <Modal
				        type={"weighing"}
				        from={"vektutvikling"}
                initDate={measurement.date}
				        modalHeader={`Vektbok - ${user.name}`}
				        callback={callback}
				        user={{
				          id: user.id,
				          name: user.name
				        }}
				        page={page}
                match={match}
				      >
                <span className="icon-edit-1"></span>
              </Modal>
            </div>

          </div>
        ))}
      </div>
      {showMore &&
        <button className="wbr-loadMore" onClick={callShowMore}>Se flere veiinger</button>
      }
    </section>
}

export default MeasurementsList;
