import React, { Fragment } from "react";
import moment from "moment";

export default function WeightItem({ weight, date = null, start = false }) {
  const dateFormatted = moment(date).format("D.M.Y");
  return (
    <Fragment>
      {weight && (
        <li>
          <span>
            {start && `Start`}Vekt
            {date && ` (${dateFormatted})`}
          </span>
          {` `}
          {weight}
        </li>
      )}
    </Fragment>
  );
}
