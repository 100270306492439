import { gql } from "apollo-boost";

const SUBSTITUTE_INSTRUCTOR_BULK = gql`
	mutation Assistant($courseMeetingId: [Int]!, $substituteId: Int) {
	  assistant {
	    assistantCourseMeetingSubstituteBulk(courseMeetingId: $courseMeetingId, substituteId: $substituteId) {
	      status
				message
	    }
	  }
	}
`

export default SUBSTITUTE_INSTRUCTOR_BULK;
