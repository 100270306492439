import React, { Fragment, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'

import NoResults from '../NoResults';
import loadingImg from '../../SysIons/loading.svg';
import Loading from '../../../atom/Loading';

import {
  UPDATE_GOAL,
  UPDATE_GOAL2
} from '../../../mutation/update_goals.js';

import {  
  GET_WEIGHT_BOOK_USER2
} from '../../../query/get_goals_data.js';

const WeightBookGoals = ({
  close,
  ...props
}) => {
    const {
      user,
      page,
      match,
      from
    } = props

    const [saveFunction, { data, loading }] = useMutation(page === 'vektutvikling' ? UPDATE_GOAL : UPDATE_GOAL2, {
      onCompleted() {
        close();
      }
    });

    const {
      loading: dataLoading,
      data: {
        request: {
            grassistant: {
              assistantGetUser: {
                name: userName,
                userId,
                startWeight: userStartWeight,
                weightGoal: userWeightGoal,
                intermediateWeightGoal: userIntermWeightGoal,
              } = {}
            }={}
        }={}
      }={}
    } = useQuery(GET_WEIGHT_BOOK_USER2, {
        variables: {
          userId: user.id
        },
        onCompleted() {
          setName(userName);
          setStartWeight(userStartWeight);
          setWeightGoal(userWeightGoal);
          setIntermediateWeightGoal(userIntermWeightGoal);
        },
        fetchPolicy: "cache-and-network"
    });

    const [name, setName] = useState();
    const [startWeight, setStartWeight] = useState();
    const [weightGoal, setWeightGoal] = useState();
    const [intermediateWeightGoal, setIntermediateWeightGoal] = useState();

    const submit = () => {
      try {
        const variables = {
          'userId': userId,
          'startWeight': startWeight,
          'weightGoal': weightGoal,
          'intermediateWeightGoal': intermediateWeightGoal
        }

        saveFunction({ variables: variables });
      } catch(err) {
        alert(err)
      }
    }

    return (<div className="ModalBody">
      <Fragment>
        {dataLoading && <Loading/>}

        {!dataLoading && !userId && <NoResults>Deltaker ikke funnet</NoResults>}

        {
          !dataLoading && <div className="wbf form_set">
            <header>
              <h2>{name}</h2>
            </header>
            <div className="wbf-field">
              <label>Start vekt</label>
              <input
                type="number"
                min="0"
                step="0.1"
                name="startWeight"
                defaultValue={startWeight ? startWeight : ''}
                onChange={(e) => setStartWeight(e.target.value ? e.target.value.replace(',', '.') : null)}
              />
            </div>
            <div className="wbf-field">
              <label>Mål</label>
              <input
                type="number"
                min="0"
                step="0.1"
                name="weightGoal"
                defaultValue={weightGoal ? weightGoal : ''}
                onChange={(e) => setWeightGoal(e.target.value ? e.target.value.replace(',', '.') : null)}
              />
            </div>
            <div className="wbf-field">
              <label>Delmål</label>
              <input
                type="number"
                min="0"
                step="0.1"
                name="intermediateWeightGoal"
                defaultValue={intermediateWeightGoal ? intermediateWeightGoal : ''}
                onChange={(e) => setIntermediateWeightGoal(e.target.value ? e.target.value.replace(',', '.') : null)}
              />
            </div>
          </div>
        }
      </Fragment>

      <footer className='display-flex'>
        <div className="buttonContainer pullLeft">
          <div className="openWeight">
            <Link  to="#" onClick={close} className="seeDevelopment arrow">
              Tilbake til vektutvikling
            </Link>
          </div>
        </div>
        <div className="buttonContainer pullRight">
          <div className="buttonWrapper">
            {
              loading ? <img src={loadingImg} alt="Processing" className="processing_image"/> :
                <button type="submit" className="buttonItem save" onClick={() => submit()}>Lagre</button>
            }
          </div>
        </div>
      </footer>
    </div>)
}

export default WeightBookGoals;
