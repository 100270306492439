import React, { Fragment, useState } from 'react';

const DiaryComment = ({
  text,
  ...props
}) => {
    const [isShow, showComments] = useState(false)

    return <Fragment>
      <div className="diaryTableComments" onClick={() => showComments(!isShow)}>
        {text}
      </div>
      {isShow && (
      <div className="diaryComments">
        <section>
          <header>
            <h2>Kommentar</h2>
            <button onClick={() => showComments(false)} aria-label="Lukk">
              <i className="icon-close" />
            </button>
          </header>
          <article>{text}</article>
        </section>
      </div>
      )}

    </Fragment>
}

export default DiaryComment;
