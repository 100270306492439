import { gql } from "apollo-boost";

const GET_MEMBERSHIP_USER_CART = gql`
	query Assistant($userId: Int, $status: String) {
		request {
			grassistant {
				assistantGetUser(userId: $userId, status: $status) {
					activeCourses {
	          edges {
	            node {
	              autoName
	              courseId
	              bookCode
	              coursePlace
	              courseDescription
	              userStartDate(userId: $userId)
	              userEndDate(userId: $userId)
								paymentSourceHandler(userId: $userId)
	            }
	          }
	        }
				}
			}
		}
	}
`

const GET_MEMBERSHIP_USER2 = gql`
query Assistant($userId: Int, $status: String) {
  request {
    grassistant {
      assistantGetUser: assistantGetUser2(userId: $userId, status: $status) {
        listAlternativeCourses {
          edges {
            node {
              courseId
              name
              autoName
              meetingWeekday
              meetingStartTime
              meetingEndTime
              courseTypeName
              instructorName
            }
          }
        }
      }
      assistantGetMemberships(userId: $userId, status: $status) {
        statusText
        userStartDate
        userEndDate
        membershipId
        paymentSourceHandler
        userId
        roedeCourse {
          autoName
          courseId
					coursePlace
        }
      }
    }
  }
}
`;

export {
	GET_MEMBERSHIP_USER_CART,
	GET_MEMBERSHIP_USER2
};
