import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

var _ = require('underscore');
import moment from 'moment'

const UsersList = ({
  participantsList,
  totalCount,
  hasMore,
  page,
  selectAll,
  selectUser,
  userList=[],
  ...props
}) => {

    const [sortType, setSortType] = useState('sortName');
    const [sortValue, setSortValue] = useState('asc');

    const handleSort = (type, value) => {
      if (type === sortType) {
        setSortType(type);
        setSortValue(value);
      } else {
        setSortType(type);
        setSortValue('asc');
      }
    }

    const sortParticipants = (arr) => {
      var data = [];

      if(sortType === 'sortName') {
        data = _.sortBy(arr,function (i) {
          return i.name.toLowerCase();
        })
      }

      if(sortType === 'sortCours') {
        data = _.sortBy(arr, function (i) {
          return i.bookCode ? i.bookCode.toLowerCase() : i.courseTitle.toLowerCase();
        })
      }

      if(sortType === 'sortDate') {
        data = _.sortBy(arr, 'activatedDate');
      }

      return sortValue === 'asc' ? data : data.reverse();
    }


    return <Fragment>
      <div className="totalCount">Total results: <strong>{totalCount}</strong></div>
      <div className="participantListContainer">
        <div className="participantsGrid">
          <div className="headItem firstCol">
            <input type="checkbox" name="course_type[]" className="userCheckbox regularCheckbox" onClick={(e) => selectAll(e, participantsList)}/>
          </div>
          <div className={`headItem nameCol headArrow ${sortType === 'sortName' && sortValue}`} onClick={() => handleSort('sortName', sortValue === 'asc' ? 'desc' : 'asc')}>Navn</div>
          <div className={`headItem courseCol headArrow ${sortType === 'sortCours' && sortValue}`} onClick={() => handleSort('sortCours', sortValue === 'asc' ? 'desc' : 'asc')}>Kurs</div>
          <div className={`headItem dateCol headArrow ${sortType === 'sortDate' && sortValue}`} onClick={() => handleSort('sortDate', sortValue === 'asc' ? 'desc' : 'asc')}>Dato</div>
        </div>

        <Fragment>
          {
            sortParticipants(participantsList).map((obj, index)=>

                <div key={index} className="participantsGrid">
                  <div className="bodyItem firstCol textItem">
                    {
                      <input
                        type="checkbox"
                        name="course_type[]"
                        className="userCheckbox regularCheckbox"
                        onClick={(e)=>selectUser(e, obj)}
                        data-id={obj.userId}
                        data-name={obj.name}
                        data-email={obj.email}
                        data-email-allowed={obj.emailAllowed}
                        data-sms-allowed={obj.smsAllowed}
                        data-phone={obj.cellPhone}
                        data-is-active={obj.status == 'active' ? 'active' : 'inactive'}
                        checked={userList.filter((x) => (x.userId == obj.userId)).length > 0}
                        onChange={() => function() {}}
                      />
                    }
                  </div>

                  <Link className="bodyItem nameCol textItem" to={`/${page}/${obj.userId}${obj.status !== 'active' ? '?status=inactive' : ''}`}>
                    {obj.name}
                  </Link>
                  <Link className="bodyItem courseCol textItem" to={`/${page}/${obj.userId}${obj.status !== 'active' ? '?status=inactive' : ''}`}>
                    {obj.bookCode ? obj.bookCode : obj.courseTitle}
                  </Link>
                  <Link className="bodyItem dateCol textItem" to={`/${page}/${obj.userId}${obj.status !== 'active' ? '?status=inactive' : ''}`}>
                    {moment(obj.activatedDate).format("DD.MM.YY")}
                  </Link>

                </div>

            )
          }
        </Fragment>
      </div>
    </Fragment>
}

export default UsersList;
