import React from 'react';
import NotFound from '../atom/NotFound';


const PageNotFound = ({
	match,
	...props
}) => {

  return <div className="pageContainer participantsContainer">
    <div className="participantsBody userCard">
      <NotFound
        children="Page not found"
      />
    </div>
  </div>
}

export default PageNotFound;
