import { gql } from "apollo-boost";

const GET_COURSE_CALENDAR_FILTER = gql`
query Assistant {
	request {
		grassistant {
			assistantCourseFilter
		}
	}
}
`

export default GET_COURSE_CALENDAR_FILTER;
