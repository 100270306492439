import { gql } from "apollo-boost";

const ASSISTANT_COMMISSION_INSTRUCTOR = gql`
	query Assistant($year: String) {
        request {
            grassistant {
                assistantCommissionInstructor(year: $year) {
									edges {
										node {
											commissionInstructorId
											commission
											status,
											total,
											commissionDate,
											downloadUrl,
											detailedDownloadUrl
										}
									}
								}
            }
        }
	}
`

export default ASSISTANT_COMMISSION_INSTRUCTOR;
