import React, { Fragment, useState } from 'react';
import {parseJsonListToString} from '../../utils/Constants'


const HealthProblems = ({data, ...props}) => {

    const healthProblems = parseJsonListToString(data, 'healthProblemsMap')

    return <Fragment>
      {healthProblems && (
        <li><span>Helseutfordringer</span> {healthProblems}</li>
      )}
    </Fragment>
}

export default HealthProblems;
