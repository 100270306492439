import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { gql } from 'apollo-boost'
import ChartistGraph from 'react-chartist'
import moment from 'moment'

export default function MeasurementsChart({ participant }) {
  const { userId, startDate } = participant
  const variables = { userId, startDate }
  const [timeFrame, setTimeFrame] = useState(1)
  const result = useQuery(GET_MEASUREMENTS, { variables })
  const data = parseData(result.data)
  const chartData = getChartData(data, timeFrame, startDate)

  const chartOptions = {
    axisY: {
      offset: 35,
      onlyInteger: true,
      position: 'end',
    },
    fullWidth: true,
    height: 290,
    lineSmooth: Chartist.Interpolation.cardinal({
      fillHoles: true,
    }),
  }

  return (
    <section className="wbr-stats">
      <h3>Oversikt utvikling</h3>
      <div className="wbr-chart">
        <ChartistGraph data={chartData} options={chartOptions} type="Line" />
      </div>
      <footer>
        <button onClick={() => setTimeFrame(1)} data-active={timeFrame === 1}>
          Måned
        </button>
        <button onClick={() => setTimeFrame(3)} data-active={timeFrame === 3}>
          Kvartal
        </button>
        <button onClick={() => setTimeFrame(12)} data-active={timeFrame === 12}>
          År
        </button>
        <button onClick={() => setTimeFrame(0)} data-active={timeFrame === 0}>
          Fra startdato
        </button>
      </footer>
    </section>
  )
}

const GET_MEASUREMENTS = gql`
  query Assistant($userId: Int, $startDate: String) {
    request {
      grassistant {
        assistantGetUser2(userId: $userId) {
          measurementList(dateSort: "desc", dateFrom: $startDate) {
            edges {
              node {
                registrationId
                dateTime
                data
              }
            }
          }
        }
      }
    }
  }
`

function parseData(data) {
  return data?.request?.grassistant?.assistantGetUser2?.measurementList?.edges
    .map((edge) => {
      const date = moment(edge.node.dateTime)
      const { coursleaderWeight, absence } = JSON.parse(edge.node.data)
      return {
        id: edge.node.registrationId,
        date: date.format('YYYY-MM-DD'),
        week: date.format('W'),
        weight: Number(coursleaderWeight),
        absence,
      }
    })
    .filter(({ absence, weight }) => absence === false && weight > 0)
}

function betweenDates(date, dateFrom, dateTo) {
  return date >= dateFrom && date <= dateTo
}

function getChartData(data, timeFrame, startDate) {
  const values = []
  const labels = []

  if (data) {
    let date = moment().startOf('isoWeek').add(1, 'weeks')
    let unit = 'weeks'
    let loops = 12
    let format = 'W.YY'
    let factor = 1

    if (timeFrame === 0) {
      const dateStart = moment(startDate)
      const weekDiff = moment().diff(dateStart, 'week')
      factor = Math.ceil(weekDiff / loops)
    } else if (timeFrame === 1) {
      loops = 4
    } else if (timeFrame === 12) {
      date = moment().startOf('month').add(1, 'months')
      unit = 'months'
      format = 'MM.YY'
    }

    const prevDate = date.clone().add(-loops * factor, unit).format('YYYY-MM-DD')
    const prevEntry = data.filter((m) => m.date < prevDate)[0] || {}

    for (let i = -loops; i < 0; i++) {
      const dateFrom = date.clone().add(i * factor, unit).format('YYYY-MM-DD')
      const dateTo = date.clone().add((i + 1) * factor, unit).add(-1, 'days').format('YYYY-MM-DD')
      const entries = data.filter((m) => betweenDates(m.date, dateFrom, dateTo))
      const { weight } = entries.pop() || {}
      const label = moment(dateTo).format(format)
      if (timeFrame !== 0 || dateTo >= startDate) {
        const value = (i === -loops) ? (weight || prevEntry.weight) : weight
        values.push(value)
        labels.push(label)
      }
    }
  }

  return { series: [values], labels }
}
