import React, { Fragment, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import AutocompleteParticipants from '../../../atom/AutocompleteParticipants';

const EmailForm = ({
  close,
  ...props
}) => {

    const contentEditable = React.useRef()
    const {
      emailTags,
      handleEmailTags,
      emailBody,
      handleEmailBody,
      subject,
      handleSubject,
      attachmentHandler,
      removeAttachment,
      attachment,
    } = props



    return (<div className="ModalBody">

      <div className="item-container">
        <AutocompleteParticipants
          emailTags={emailTags}
          handleEmailTags={handleEmailTags}
        />

        <div className="userNameContainer">
          <div className="userNameWrapper">
            <div className="toTextWrapper">Til:</div>
            <div className="namesContainer">
            {
              emailTags.length > 0 && emailTags.map((obj, index)=>
                <div className="nameItem"
                  key={index}
                >{obj.name ? obj.name : obj.email}</div>
              )
            }
            </div>
            <div className="clear"></div>
          </div>
        </div>

      </div>
      <div className="attachmentContainer">
        {
          attachment.length > 0 && <div className="attachmentWrapper">
            <div className="countAttachments">{attachment.length} vedlegg:</div>
            <div className="attachmentsText">
              {attachment.map((obj, index) => (
                <div className="attachmentText attachmentItems" key={index}>
                  {obj.name} <i className="icon-cancel-circle" onClick={() => removeAttachment(obj)} />
                </div>
              ))}
            </div>
            <div className="clear"></div>
          </div>
        }
      </div>
      <div className="item-container">
        <input className="subject" type="text" value={subject} onChange={(e) => handleSubject(e.target.value)} />
      </div>
      <div className="item-container message-container">
        <div className="actionButtons">
          <EditButton cmd="bold" />
          <EditButton cmd="italic" />
          <EditButton cmd="insertOrderedList"/>
          <EditButton cmd="insertUnorderedList"/>

          <div className="emailContentAction attachment">
            <div className="buttonWrapper">
              <i className="action-icons icon-plus-circle"></i>
              <div className="textWrapper">Legg til vedlegg</div>
            </div>
            <input className="fileInput uploadInpput" id="attachment" type="file" onChange={(e)=>attachmentHandler(e)}/>
          </div>
        </div>

        <ContentEditable
          className="messageText"
          innerRef={contentEditable}
          html={emailBody}
          disabled={false}
          onChange={(e) => handleEmailBody(e.target.value)}
          placeholder={"Din e-posttekst"}
        />
      </div>

    </div>)
}

function EditButton(props) {

  const command = {
    "bold": "icon-bold",
    "italic": "icon-text-italic",
    "insertOrderedList": "icon-list-numbers",
    "insertUnorderedList": "icon-list-bullets"
  }

  return (<button
    key={props.cmd}
    onMouseDown={evt => {
      console.log(props.cmd, props.arg);
      evt.preventDefault(); // Avoids loosing focus from the editable area
      document.execCommand(props.cmd, false, props.arg); // Send the command to the browser
    }}
  >
    <i className={`action-icons ${command[props.cmd]}`}></i>
  </button>);
}

export default EmailForm;
