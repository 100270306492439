import React from 'react';

import { Link } from 'react-router-dom';
import Loading from '../atom/Loading';
import moment from 'moment'
var _ = require('underscore');
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import USER_FEED from '../query/user_feed.js'


const MembershipOverview = ({
}) => {

	const {
		loading,
		error,
		data: {
			request: {
				grassistant: {
					assistantUserFeed
				} = {}
			} = {}
		} = {}

	} = useQuery(USER_FEED);

  if(loading) return <Loading/>

  const userFeed = JSON.parse(assistantUserFeed || '{}');
  let {
    new_users=[],
    paused_users=[],
    canceled_users=[],
    upgrading_users=[],
    renewed_users=[],
    restarted_users=[]
  } = userFeed;

  const showNew = new_users.length > 0;
  const showPaused = paused_users.length > 0;
  const showCanceled = canceled_users.length > 0;
  const showUpgraded = upgrading_users.length > 0;
  const showRenewed = renewed_users.length > 0;
  const showRestarted = restarted_users.length > 0;
  const showOverview = showNew || showPaused || showCanceled || showRenewed || showRestarted || showUpgraded;

  new_users = _.sortBy(new_users, function(obj){
    return obj.second_meeting;
  }).reverse();

  return <OverviewContainer>
    {showOverview && <OverviewWrapper>
      {
        showNew && <UserContainer>
          <Header>Nye medlemmer</Header>
          <GridContainerHeader>
            <GridItem>Navn</GridItem>
            <GridItem>Medlemskap</GridItem>
            <GridItem>Første Møte/Rapport</GridItem>
            <GridItem>Bestillingsdato</GridItem>
          </GridContainerHeader>
          {new_users.map((obj, key) => (
            <ShowUser obj={obj} key={key} showFirstMeeting showSecondMeeting />
          ))}
        </UserContainer>
      }
      {
        showRenewed && <UserContainer>
          <Header>Medlemmer som har fornyet</Header>
          <GridContainerHeader>
            <GridItem>Navn</GridItem>
            <GridItem>Medlemskap</GridItem>
            <GridItem>Første Møte/Rapport</GridItem>
            <GridItem>Bestillingsdato</GridItem>
          </GridContainerHeader>
          {renewed_users.map((obj, key) => (
            <ShowUser obj={obj} key={key} showFirstMeeting showSecondMeeting />
          ))}
        </UserContainer>
      }
      {
        showRestarted && <UserContainer>
          <Header>Medlemmer som har gjennopptatt medlemskapet</Header>
          <GridContainerHeader>
            <GridItem>Navn</GridItem>
            <GridItem>Medlemskap</GridItem>
            <GridItem>Første Møte/Rapport</GridItem>
            <GridItem>Bestillingsdato</GridItem>
          </GridContainerHeader>
          {restarted_users.map((obj, key) => (
            <ShowUser obj={obj} key={key} showFirstMeeting showSecondMeeting />
          ))}
        </UserContainer>
      }      
      {
        showPaused && <UserContainer>
          <Header>Kursdeltaker som pauses</Header>
          <GridContainerHeader>
            <GridItem>Navn</GridItem>
            <GridItem>Medlemskap</GridItem>
            <GridItem>Siste Møte</GridItem>
            <GridItem>Pauset dato</GridItem>
          </GridContainerHeader>
          {paused_users.map((obj, key) => (
            <ShowUser obj={obj} key={key} showFirstMeeting showSecondMeeting />
          ))}
        </UserContainer>
      }

      {
        showCanceled && <UserContainer>
          <Header>Kursdeltaker som avsluttes</Header>
          <GridContainerHeader>
            <GridItem>Navn</GridItem>
            <GridItem>Medlemskap</GridItem>
            <GridItem>Avsluttet dato</GridItem>
          </GridContainerHeader>
          {canceled_users.map((obj, key) => (
            <ShowUser obj={obj} key={key} showSecondMeeting />
          ))}
        </UserContainer>
      }
{
        showUpgraded && <UserContainer>
          <Header>Kursdeltaker som oppgraderes til medlemskap</Header>
          <GridContainerHeader>
            <GridItem>Navn</GridItem>
            <GridItem>Medlemskap</GridItem>
            <GridItem>Møter igjen</GridItem>
            <GridItem>Oppgradert dato</GridItem>
          </GridContainerHeader>
          {upgrading_users.map((obj, key) => (
            <ShowUser obj={obj} key={key} showMeetingsLeft showSecondMeeting />
          ))}
        </UserContainer>
      }      
    </OverviewWrapper>}
  </OverviewContainer>
}

const formatDate = (date) => date === 'n/a' ? date : moment(date).format("dddd D MMM");

const ShowUser = ({obj, showMeetingsLeft, showFirstMeeting, showSecondMeeting}) => {
  return <Link to={`/nye-kursdeltakere/${obj.user_id}`}>
    <GridContainer>
      <GridItem>{obj.name}</GridItem>
      <GridItem>{obj.membership_name}</GridItem>
      {showMeetingsLeft && showSecondMeeting && (
        <>
        <GridItem>{obj?.number_of_meetings}</GridItem>
        <GridItem>{formatDate(obj.second_meeting)}</GridItem>
        </>
      )}
      {showFirstMeeting && showSecondMeeting && (
        <>
        <GridItem>{formatDate(obj.first_meeting)}</GridItem>
        <GridItem>{formatDate(obj.second_meeting)}</GridItem>
        </>
      )}
      {showSecondMeeting && !showFirstMeeting && (
        <GridItem>{formatDate(obj.second_meeting)}</GridItem>
      )}
    </GridContainer>
  </Link>
}

const OverviewContainer = styled.div``;

const OverviewWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  color: #7b7b7a;
  margin-bottom: 16px;
  padding: 16px;
  transition: box-shadow 0.3s ease;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.6);
`
const UserContainer = styled.div`
  margin-bottom: 20px;
`;
const Header = styled.div`
  left: 0px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.2px;
  color: black;
`;


const GridContainerHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  border-bottom: 1px solid rgba(0,0,0, 0.15);
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  font-size: 16px;
`;

const GridItem = styled.div`
  padding: 8px 14px;
`;


export default MembershipOverview;
