import React, { Fragment } from 'react';

import Modal from '../../ui/Modal';

const SubstituteInstructor = ({meetingsList, ...props}) => {
  return <div className="button-item  email-button-container">
    <Modal
      courseMeetingId={meetingsList}
      type={'substitute_instructor_bulk'}
      from={'substitute_instructor_bulk'}
      modalHeader={"Substitute instructor bulk"}
      {...props}
    >
      <div className="buttonWrapper">
        <i className="buttonIcon icon-info"></i>
        <div className="buttonText">Substitute instructor</div>
      </div>
    </Modal>

  </div>
}

export default SubstituteInstructor;
