import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import GET_COURSE_CALENDAR_FILTER from '../../query/get_course_calendar_filter.js';

const RoedeCourseFilter = ({
  setFilter,
  haveUsers,
  courseType
}) => {

    const [showFilters, setShowFilters] = useState(false)

    const handleChange = (e, type, value) => {
        setFilter(type, value);
    }

	let {
		loading,
        data: {
            request: {
                grassistant: {
                    assistantCourseFilter
                } = {}
            } = {}
        } = {}
	} = useQuery(GET_COURSE_CALENDAR_FILTER);

    assistantCourseFilter = JSON.parse(assistantCourseFilter || '[]') 

    return (

        <div className="filterWrapper">
            <div className={`filterItemContainer filterButton ${showFilters ? 'active' : ''}`} onClick={() => setShowFilters(!showFilters)}>
                <i className="icon-config-1"></i>
                <div className="filterText">Filter</div>
            </div>

            {showFilters && <div className="filterItemContainer filterList">
                <div className="filterDetailsContainer">
                    {assistantCourseFilter.length > 0 && <div className="filterItem courseSaleContainer">
                        <div className="filterItemName">Kurstype</div>
                        <div className="filterGridContainer">
                            {
                                assistantCourseFilter.map((obj, index)=>
                                    <div className="filterGridItem" key={index}>
                                        <input
                                            type="checkbox"
                                            name="course_subtype[]"
                                            className="regularCheckbox subtypeCheckbox"
                                            data-id={obj.id ? obj.id : ""}
                                            onClick={(e) => handleChange(e, 'course_type', obj.id)}
                                            checked={courseType.indexOf(obj.id) >= 0}
                                            onChange={(e) => function() {}}
                                        /> <span className="checkboxNames">{ obj.name }</span>
                                    </div>
                                )
                            }
                        </div>
                    </div>}


                    <div className="filterItem courseSaleContainer">
                        <div className="filterItemName">Salg</div>
                        <div className="filterGridContainer">
                            <div className="filterGridItem">
                                <input
                                    type="radio"
                                    name="course_sale"
                                    data-status="have_members"
                                    className="regularCheckbox"
                                    checked={haveUsers === true}
                                    onClick={(e) => handleChange(e, 'have_members', true)}
                                    onChange={(e) => function() {}}
                                /> <span className="checkboxNames">Har deltakere</span>
                            </div>
                            <div className="filterGridItem">
                                <input
                                    type="radio"
                                    name="course_sale"
                                    data-status="dont_have_members"
                                    className="regularCheckbox"
                                    checked={haveUsers === false}
                                    onClick={(e) => handleChange(e, 'have_members', false)}
                                    onChange={(e) => function() {}}
                                /> <span className="checkboxNames">Har ikke deltakere</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div> }
        </div>
    )
}

export default RoedeCourseFilter;
