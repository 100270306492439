import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'
var _ = require('underscore');
import Loading from '../../atom/Loading';
import Modal from '../Modal';

import {
  GET_MEMBERSHIP_USER2
} from '../../query/get_membership_user_cart.js'

const MembershipUserCard2 = ({
  page,
  match,
  status,
  ...props
}) => {
  const [sortBy, setSortBy] = useState('bookCode');
  const [sortDirection, setSortDirection] = useState('asc');
  const [membershipId, setMembershipId] = useState();

  const setSortOrder = (e, type, direction) => {
    if (type === sortBy) {
      setSortDirection(direction == 'asc' ? 'desc' : 'asc')
    } else {
      setSortDirection('asc')
    }
    setSortBy(type)
  }



  const {
    loading,
    refetch,
    data: {
      request: {
        grassistant: {
          // assistantGetUser: {
          //   listAlternativeCourses={}
          // } = {},
          assistantGetMemberships = []
        } = {}
      } = {}
    } = {}
  } = useQuery( GET_MEMBERSHIP_USER2, {
    variables: {
      userId: match.params.id,
      status: status
    }
  });

  const updateMembershipId = (e, membershipId) => {
    // e.nativeEvent.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    setMembershipId(membershipId)
  }

  var meberships = _.sortBy(assistantGetMemberships, function(obj){
    if (sortBy == "bookCode") {
        return obj.roedeCourse.autoName;
    }
    if (sortBy == "memberActivation") {
      return obj.userStartDate;
    }
    if (sortBy == "coursePlace") {
      return obj.roedeCourse.coursePlace;
    }
  });

  if(sortDirection == "desc") {
    meberships = meberships.reverse();
  }

  return <div className="coursePlaceholder backgroundContainer">
      <div className={`coursesGrid headContainer extend-membership-list`}>
        <div></div>
        <div
          className={
            sortBy == "bookCode" ?
              (sortDirection == "asc" ? "coursesGridItem courseType active asc" : "coursesGridItem courseType active desc")
              : "coursesGridItem courseType"
            }
          data-direction={sortDirection}
          onClick={(e) => setSortOrder(e, "bookCode", sortDirection)}
        >
          Kurstype
        </div>
        <div
          className={
            sortBy == "memberActivation" ?
              (sortDirection == "asc" ? "coursesGridItem membershipStartStop active asc" : "coursesGridItem membershipStartStop active desc")
              : "coursesGridItem membershipStartStop"}
          data-direction={sortDirection}
          onClick={(e) => setSortOrder(e, "memberActivation", sortDirection)}
        >
          Start / Slutt
        </div>
        <div
          className="coursesGridItem coursePlace"
        >
          Variant
        </div>
        <div
          className={
            sortBy == "coursePlace" ?
              (sortDirection == "asc" ? "coursesGridItem coursePlace active asc" : "coursesGridItem coursePlace active desc")
              : "coursesGridItem coursePlace"
          }
          data-direction={sortDirection}
          onClick={(e) => setSortOrder(e, "coursePlace", sortDirection)}
        >
          Sted
        </div>
      </div>
      <Fragment>
        {loading && <Loading/>}
        {!loading && meberships.length <= 0 && <div className="coursesNotificationText">{"User have no active courses at the moment"}</div>}
        {!loading && meberships.length > 0 && <Fragment>
          {meberships.map((obj, index)=>
              <div key={index} className={`coursesGrid itemContainer extend-membership-list`}>
                <div className="radio-input">
                  <input className="radio-input" type="radio" name="membership" onChange={(e) => updateMembershipId(e, obj.membershipId)} />
                </div>
                <div className="coursesGridItem courseType">
                  <Link  to={`/course-details/${obj.roedeCourse.courseId}`}>
                    {obj.roedeCourse.autoName}
                  </Link>
                </div>
                <div className="coursesGridItem membershipStartStop">
                  <Link  to={`/course-details/${obj.roedeCourse.courseId}`}>
                    {moment(obj.userStartDate).format("DD.MM.YY")} / {obj.userEndDate ? moment(obj.userEndDate).format("DD.MM.YY") : <span className="no_date">-</span>}
                  </Link>
                </div>
                <div className="coursesGridItem membershipSubscriptions">
                  <Link  to={`/course-details/${obj.roedeCourse.courseId}`}>
                    {obj.statusText}
                  </Link>
                </div>
                <div className="coursesGridItem coursePlace">
                  <Link  to={`/course-details/${obj.roedeCourse.courseId}`}>
                    {obj.roedeCourse.coursePlace}
                  </Link>
                </div>
              </div>
          )}
        </Fragment>}

        {
          // membershipId &&
          // <Modal
          //   type={"move_membership"}
          //   from={"move_membership"}
          //   modalHeader={"Flytt medlemskap"}
          //   callback={refetch}
          //
          //   memberships={[membershipId]}
          //   listAlternativeCourses={listAlternativeCourses && listAlternativeCourses.edges ? listAlternativeCourses.edges : []}
          // >
          //   <div className="buttonItem">Flytt medlemskap</div>
          // </Modal>
        }
      </Fragment>
    </div>
}

export default MembershipUserCard2;
