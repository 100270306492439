import moment from 'moment';
import { dietScore, dietScore as dietScoreConf } from '../../../config';
var _ = require('underscore');

export const INITIAL_SORT_ORDER = {
  name: 'asc',
  totalEatenKcal: 'desc',
  dietScore: 'desc',
  trainingPoints: 'desc',
  voucherMeals: 'desc',
  waistCircumference: 'desc',
  kcalMenu: 'desc',
  weightDeltaWeek: 'desc',
  weightDeltaCourse: 'desc',
  weightDeltaTotal: 'desc',
}

export const VOUCHER_MEAL_KCAL = 25

export const DROP_IN_SLUG = 'drop-in'
export const DROP_IN_TEXT = 'Drop in'

export function getDays() {
  const yesterday = moment().add(-1, 'day')

  return Array(7).fill().map((item, i) => {
    const day = yesterday.clone().add(i*-1, 'day')

    return {
      date: day.format('YYYY-MM-DD'),
      dateFormatted: `${day.format('dddd')} ${day.format('DD.MM.YYYY')}`,
      cacheKey: `cache${day.format('YYYYMMDD')}`,
    }
  })
}

export function showKcalLimit(user) {
  if(user.kcalMenu) {
    const kcalMenu = getMenuToKcal(user);
    return "(" + kcalMenu['min'] + "-" +kcalMenu['max'] + ")";
  } else {
    return ""
  }
}

export function getMenuToKcal(user) {
  const kcalMenu = dietScore['kcal']['menus'][user.kcalMenu];
  return kcalMenu;
}

export function getKCalClass(user, kcal) {

  const kcalMenu = getMenuToKcal(user);

  const min = kcalMenu['min'];
  const max = kcalMenu['max'];

  if(kcal < min) return "regular";
  if(kcal >= min && kcal <= max) return "good";
  if(kcal > max) return "bad";
}

export function getMeasurementsData(assistant) {
  const data = getData(assistant)
  const currentSummary = JSON.parse(data.currentSummary || '{}');

  const weight = {
    current: null,
    currentDate: null,
    diffLastWeek: null,
    diffLastWeekDate: null,
    diffCourseDate: null,
    diffCourseDateDate: null,
    diffStartDate: null,
    diffStartDateDate: null,
  }

  const measurements = {
    current: null,
    diffLastWeek: null,
    diffCourseDate: null,
    diffStartDate: null,
    totalGoalDate: null,
    diffLastWeekDate: null,
    startCourseTotalGoalDate: null,
    startDayTotalGoalDate: null,
  }


  if (!_.isEmpty(currentSummary)) {
    // current weight
    if(currentSummary.currentMeasurement && currentSummary.currentMeasurement.weight) {
      const tmpWeight = currentSummary.currentMeasurement.weight;
      weight.current = tmpWeight.value;
      weight.currentDate = tmpWeight.date && fomatDate(tmpWeight.date);
    }

    // weight since last week
    if (currentSummary.computedValues && currentSummary.computedValues.lastWeek && currentSummary.computedValues.lastWeek.weight) {
      const tmpWeight = currentSummary.computedValues.lastWeek.weight;
      weight.diffLastWeek = tmpWeight.value;
      weight.diffLastWeekDate = tmpWeight.date && fomatDate(tmpWeight.date);
    }

    // weight since course started
    if (currentSummary.computedValues && currentSummary.computedValues.startCourse && currentSummary.computedValues.startCourse.weight) {
      const tmpWeight = currentSummary.computedValues.startCourse.weight;
      weight.diffCourseDate = tmpWeight.value;
      weight.diffCourseDateDate = tmpWeight.date && fomatDate(tmpWeight.date)
    }
    // change since start date
    if (currentSummary.computedValues && currentSummary.computedValues.startMeasurement && currentSummary.computedValues.startMeasurement.weight) {
      const tmpWeight = currentSummary.computedValues.startMeasurement.weight;
      weight.diffStartDate = tmpWeight.value;
      weight.diffStartDateDate = tmpWeight.date && fomatDate(tmpWeight.date);
    }


    // current total goal
    if (currentSummary.currentMeasurement && currentSummary.currentMeasurement.waistCircumference){
      const waistCircumference = currentSummary.currentMeasurement.waistCircumference
      measurements.current = waistCircumference.value
      measurements.currentDate = waistCircumference.date && fomatDate(waistCircumference.date)
    }

    // change in total goal since last week
    if (currentSummary.computedValues && currentSummary.computedValues.lastWeek && currentSummary.computedValues.lastWeek.waistCircumference) {
      const waistCircumference = currentSummary.computedValues.lastWeek.waistCircumference;
      measurements.diffLastWeek = waistCircumference.value;
      measurements.diffLastWeekDate = waistCircumference.date && fomatDate(waistCircumference.date);
    }

    // change in total goal since course start
    if (currentSummary.computedValues && currentSummary.computedValues.startCourse && currentSummary.computedValues.startCourse.waistCircumference) {
      const waistCircumference = currentSummary.computedValues.startCourse.waistCircumference;
      measurements.diffCourseDate = waistCircumference.value;
      measurements.startCourseTotalGoalDate = waistCircumference.date && fomatDate(waistCircumference.date)
    }

    // change in total target since start date
    if (currentSummary.computedValues && currentSummary.computedValues.startMeasurement && currentSummary.computedValues.startMeasurement.waistCircumference) {
      const waistCircumference = currentSummary.computedValues.startMeasurement.waistCircumference;
      measurements.diffStartDate = waistCircumference.value;
      measurements.startDayTotalGoalDate =  waistCircumference.date && fomatDate(waistCircumference.date);
    }
  }

  return { weight, measurements }
}

export function getDietAndExerciseData(assistant) {
  const data = getData(assistant)

  const days = getDays().map(day => {
    const { date, dateFormatted, cacheKey } = day
    const [cache] = data[cacheKey] || []
    const { data: cacheData } = cache || {}

    const {
      diet: { score: dietScore, totalEatenKcal: kcal } = {},
      exercise: { exercisePoints: trainingPoints, strengthPoints, numSteps, comments } = {},
    } = JSON.parse(cacheData || '{}')

    return { date, dateFormatted, kcal, dietScore, trainingPoints, strengthPoints, numSteps, comments }
  })

  const totals = getTotals(days)

  return { days, totals }
}

export function getTotals(days) {
  const totals = days.reduce((acc, curr) => {
    acc.kcal += curr.kcal
    acc.dietScore += curr.dietScore
    acc.trainingPoints += curr.trainingPoints
    acc.strengthPoints += curr.strengthPoints
    acc.numSteps += curr.numSteps
    acc.numDays += curr.kcal > 0 ? 1 : 0
    return acc
  }, { kcal: 0, dietScore: 0, trainingPoints: 0, strengthPoints: 0, numSteps: 0, numDays: 0 })

  const daysWithDietScore = days.filter(day => day.dietScore && day.kcal).length

  totals.dietScore = daysWithDietScore ? (totals.dietScore / daysWithDietScore) : 0

  totals.kcal = totals.numDays > 0 ? (totals.kcal / totals.numDays) : 0
  totals.numSteps = totals.numDays > 0 ? (totals.numSteps / totals.numDays) : 0

  return totals
}

export function getUser(assistant) {

  const data = getData(assistant);

  if (!data) return null

  return {
    id: data.userId,
    name: data.name.split(' ').shift(),
    fullName: data.name,
    levelName: data.userLevelName,
    profilePic: data.fullImageUrl || '/public/no_image_user.png',
    kcalMenu: data.kcalMenu,
    gender: data.gender,
    height: data.height || 0,
    age: data.age || 0,
    exerciseHabits: data.exerciseHabits || 0,
    activityLevel: data.activityLevel || 0,
    weight: data.weight || 0,
    activity: data.activity || 0,
    dietType: data.dietType,
    restingMetabolism: data.restingMetabolism,
    calculatedEnergyBeforeExercise: data.calculatedEnergyBeforeExercise,
    calculatedEnergyAfterExercise: data.calculatedEnergyAfterExercise,
    recommendedKcalIn: data.recommendedKcalIn,
    restingMetabolismFormated: formatNumber(data.restingMetabolism),
    calculatedEnergyBeforeExerciseFormated: formatNumber(data.calculatedEnergyBeforeExercise),
    recommendedKcalInFormated: formatNumber(data.recommendedKcalIn)
  }
}

export function getData(assistant) {
  // const { assistantGetUser } = assistant || {}
  // return assistantGetUser
  return assistant || {}
}

export function formatNumber(number) {
  if (number === null || isNaN(number)) return 'n/a'

  const options = { maximumFractionDigits: 1 }
  return Number(number).toLocaleString('nb-NO', options)
}

export function fomatDate(date_time, format=' (D.MMM.YYYY)') {
  if(date_time === null) return 'n/a'
  return new moment(date_time).format(format)
}

export function getCacheQuery() {
  return getDays().map(day => {
    return `
      ${day.cacheKey}: dailyDiaryCache(date: "${day.date}") {
        data
    }`
  })
}




export function getDuration(minutes) {
  const durations = {
    '5':   '5 minutter',
    '10':  '10 minutter',
    '15':  '15 minutter',
    '20':  '20 minutter',
    '25':  '25 minutter',
    '30':  '30 minutter',
    '40':  '40 minutter',
    '50':  '50 minutter',
    '60':  '1 time',
    '70':  '1 time og 10 minutter',
    '80':  '1 time og 20 minutter',
    '90':  '1 time og 30 minutter',
    '100': '1 time og 40 minutter',
    '110': '1 time og 50 minutter',
    '120': '2 timer',
    '150': '2 timer og 30 minutter',
    '180': '3 timer',
    '210': '3 timer og 30 minutter',
    '240': '4 timer',
  }

  return durations[minutes]
}

export function getType(type) {
  const types = {
    'recipe': 'Opskriffter',
    'custom_recipe': 'Opskriffter',
    'product': 'Matvare',
    'custom_food_product': 'Matvare',
  }

  return types[type]
}

export function getRegistrations(assistant, category) {
  const data = getData(assistant)

  return data.diaryRegistration.edges.map(e => e.node)
    .filter(registration => registration.category === category)
    .map(registration => ({
      ...registration,
      data: JSON.parse(registration.data || '{}'),
    }))
}

export function getClassName(type, value, min, max) {
  if (value >= min && value <= max) {
    return type + " inrange";
  }

  if (value > max) {
    return type + " passed_max";
  }

  return "regular"
}

export function getDietData(assistant) {
  const registrations = getRegistrations(assistant, 'food')
  const nutrition = getNutrition(assistant)
  const meals = getMeals(registrations)
  const extras = getExtras(registrations)
  const heartFriendly = getHeartFriendly(registrations)

  return { nutrition, meals, extras, heartFriendly }
}

export function getNutrition(assistant) {
  const data = getData(assistant);
  const {
    diet: {
      totalEatenKcal: kcal,
      score: dietScore,
      energyShareFromProteins: proteins,
      energyShareFromEatenCarbs: carbs,
      energyShareFromEatenFat: fat,
      totalEatenCalcium: calcium,
      totalEatenFruits: fruits,
      energyDensity,
      totalEatenFiber: fiber,
      totalEatenNuts: nuts,
      totalEatenVegetables: vegetables,
    } = {}
  } = JSON.parse(data.dailyDiaryCache[0].data || '{}')

  const calciumMin = dietScoreConf.calcium.minMilligrams;
  const calciumMax = dietScoreConf.calcium.maxMilligrams;
  const fruitsMin = dietScoreConf.fruit.minGrams;
  const fruitsMax = dietScoreConf.fruit.maxGrams;
  const vegMin = dietScoreConf.vegetables.minGrams;
  const vegMax = dietScoreConf.vegetables.maxGrams;
  const fiberMin = dietScoreConf.fiber.minGrams;
  const fiberMax = dietScoreConf.fiber.maxGrams;
  const nutsMin = dietScoreConf.nuts.minGrams;
  const nutsMax = dietScoreConf.nuts.maxGrams;

  const energyDistribution = dietScoreConf.energyDistribution;
  const proteinsMin = energyDistribution.protein.minShare;
  const proteinsMax = energyDistribution.protein.maxShare;
  const carbsMin = energyDistribution.carbohydrate.minShare;
  const carbsMax = energyDistribution.carbohydrate.maxShare;
  const fatMin = energyDistribution.fat.minShare;
  const fatMax = energyDistribution.fat.maxShare;
  const kcalMin = data.kcalMenu && data.kcalMenu in dietScoreConf.kcal.menus ? dietScoreConf.kcal.menus[data.kcalMenu].min : null;
  const kcalMax = data.kcalMenu && data.kcalMenu in dietScoreConf.kcal.menus ? dietScoreConf.kcal.menus[data.kcalMenu].max : null;
  const energyDensityMin = energyDistribution.energyDensity.minShare;
  const energyDensityMax = energyDistribution.energyDensity.maxShare;

  return {
    kcal,
    kcalMin,
    kcalMax,
    dietScore,
    calcium,
    calciumMin,
    calciumMax,
    fruits,
    fruitsMin,
    fruitsMax,
    vegetables,
    vegMin,
    vegMax,
    energyDensity,
    energyDensityMin,
    energyDensityMax,
    fiber,
    fiberMin,
    fiberMax,
    nuts,
    nutsMin,
    nutsMax,
    proteins,
    proteinsMin,
    proteinsMax,
    carbs,
    carbsMin,
    carbsMax,
    fat,
    fatMin,
    fatMax
  }
}

export function getMeals(registrations) {
  const meals = {
    breakfast: { id: 'breakfast', name: 'Frokost', kcal: 0, items: [] },
    lunch: { id: 'lunch', name: 'Lunsj', kcal: 0, items: [] },
    snackMeal: { id: 'snackMeal',name: 'Mellommåltider', kcal: 0, items: [] },
    dinner: { id: 'dinner', name: 'Middag', kcal: 0, items: [] },
    supper: { id: 'supper', name: 'Kveldsmat', kcal: 0, items: [] },
    voucherMeal: { id: 'voucherMeal', name: 'Utfordring (KM)', kcal: 0, items: [] },
  }

  registrations.forEach(registration => {
    if (meals[registration.tag]) {
      meals[registration.tag].kcal += registration.calories
      meals[registration.tag].items.push({
        id: registration.registrationId,
        quantity: registration.data.quantity,
        unit: registration.data.unit,
        type: registration.data.type,
        name: registration.data.name,
        kcal: registration.calories,
        isHeartFriendly: registration.data.heartFriendly,
      })
    }
  })

  return meals
}

export function getHeartFriendly(registrations) {

  const arr = registrations.filter(
    registration => registration.category === 'food' && registration.data.heartFriendly
  ) || []

  return arr.length >= 2
}

export function getExtras(registrations) {
  const { data } = registrations
    .find(registration => registration.tag === 'extras') || {}

  return data || {}
}

export function getExerciseData(assistant) {
  const data = getData(assistant)
  const registrations = getRegistrations(assistant, 'exercise')

  const {
    exercise: {
      calories: kcal,
      exercisePoints: trainingPoints,
      strengthPoints,
      numSteps,
    } = {}
  } = JSON.parse(data.dailyDiaryCache[0].data || '{}');

  const exercise = { numSteps, kcal, trainingPoints, strengthPoints }

  const activities = registrations.reduce((acc, curr) => {
    const { calories: kcal, data } = curr

    if (curr.tag === 'exercise') {
      const { activity: name, intensity, duration } = data
      acc.push({ name, intensity, duration, kcal })
    }

    return acc
  }, [])

  return { activities, exercise }
}

export function transformData(data) {
  const {
    computedValues = {},
    currentMeasurement = {},
    startMeasurement = {},
    courseStartMeasurement = {},
    previousMeasurement = {},
  } = JSON.parse(data.summaryMeasurementsCached || '{}')


  const {
    measurementList: {
      edges = []
    } = {}
  } = data;

  const measurements = _.map(edges, function(edge) {
    const id = edge.node.registrationId
    const date = moment(edge.node.dateTime).format('YYYY-MM-DD')
    const week = moment(edge.node.dateTime).format('W')
    const {
      node: {
        data: measurementData
      }={}
    } = edge;
    const {
      coursleaderWeight: weight,
      weeksPursuit: weekPursuit,
      comment: comment,
      absence: absence
    } = JSON.parse(measurementData || '{}')

    return { id, date, week, weight, weekPursuit, comment, absence }
  })

  const checkMeasurements = currentMeasurement.coursleaderWeight &&  currentMeasurement.coursleaderWeight.date ? moment(currentMeasurement.coursleaderWeight.date).isoWeek() === moment().isoWeek() : false;

  const user = {
    id: data.userId,
    name: data.name && data.name.split(' ')[0],
    fullName: data.name,
    levelName: data.userLevelName,
    profilePic: data.fullImageUrl || '/public/no_image_user.png',
    lastMeasuredWeight: measurements[0] && measurements[0].weight,
    lastMeasuredWeightText: checkMeasurements ? "Siste veiing:" : "Register vekt:",
    kcalMenu: data.kcalMenu,
  }


  const previousMeasurements = checkMeasurements ? previousMeasurement.coursleaderWeight : currentMeasurement.coursleaderWeight;

  const featuredWeights = {
    start: {
      text: 'Start vekt',
      weight: data.startWeight ? data.startWeight : (startMeasurement.coursleaderWeight ? startMeasurement.coursleaderWeight.value : 0.0),
      date: data.startWeight ? null : null,
    },
    previous: {
      text: 'Forrige veiing',
      weight: previousMeasurements && previousMeasurements.value,
      date: previousMeasurements && previousMeasurements.date,
    },
    intermediateGoal: {
      text: 'Delmål',
      weight: data.intermediateWeightGoal,
      date: null,
    },
    goal: {
      text: 'Mål',
      weight: data.weightGoal,
      date: null,
    },
  }

  const weightStats = [{
    previous: (computedValues.lastWeek && computedValues.lastWeek.coursleaderWeight) ? {
      text: `${moment(computedValues.lastWeek.coursleaderWeight.date).format('DD.MM.YY')} - Endring siden forrige veiing`,
      value: formatAmount(computedValues.lastWeek.coursleaderWeight.value, 'kg'),
    } : null,
    thisCourse: (computedValues.startCourse && computedValues.startCourse.coursleaderWeight) ? {
      text: `${moment(data.courseStartDate).format('DD.MM.YY')} - Endring på dette kurset`,
      value: formatAmount(computedValues.startCourse.coursleaderWeight.value, 'kg'),
    } : null,
    startDate: (computedValues.startMeasurement && computedValues.startMeasurement.coursleaderWeight) ? {
      text: `${moment(data.startDate).format('DD.MM.YY')} - Endring siden startdato`,
      value: formatAmount(computedValues.startMeasurement.coursleaderWeight.value, 'kg'),
    } : null,
  }]

  const waistStats = (!currentMeasurement.waistCircumference) ? [] : [
    {
      waistLatest: {
        text: `${moment(currentMeasurement.waistCircumference.date).format('DD.MM.YY')} - Midjemål siste måling`,
        value: formatAmount(currentMeasurement.waistCircumference.value, 'cm'),
      },
      waistStartDate: {
        text: `${moment(startMeasurement.waistCircumference.date).format('DD.MM.YY')} - Midjemål startdato`,
        value: formatAmount(startMeasurement.waistCircumference.value, 'cm'),
      },
    }, {
      previous: {
        text: `${moment(computedValues.lastWeek && computedValues.lastWeek.waistCircumference && computedValues.lastWeek.waistCircumference.date).format('DD.MM.YY')} - Endring siden forrige måling`,
        value: formatAmount(computedValues.lastWeek && computedValues.lastWeek.waistCircumference && computedValues.lastWeek.waistCircumference.value, 'cm'),
      },
      startDate: {
        text: `${moment(computedValues.startMeasurement && computedValues.startMeasurement.waistCircumference && computedValues.startMeasurement.waistCircumference.date).format('DD.MM.YY')} - Endring siden startdato`,
        value: formatAmount(computedValues.startMeasurement && computedValues.startMeasurement.waistCircumference && computedValues.startMeasurement.waistCircumference.value, 'cm'),
      },
    },
  ]

  const {
    weeklyDiaryCache: {
      data: weeklyData
    }={}
  } = data;

  const weeklyDiaryCacheData = JSON.parse(weeklyData || '{}')

  const { diet = {}, exercise = {} } = weeklyDiaryCacheData

  const diaryStats = [{
    energyConsumption: {
      text: 'Energiinntak',
      value: formatAmount(Math.ceil(diet.totalEatenKcal), 'kcal'),
    },
    dietScore: {
      text: 'Kostholdsscore',
      value: formatAmount(Math.round(diet.score * 10) / 10, 'p'),
    },
    exercisePoints: {
      text: 'Treningspoeng',
      value: formatAmount(Math.round(exercise.exercisePoints * 10) / 10, 'p'),
    },
    kcalMenu: {
      text: 'Meny',
      value: user.kcalMenu || 'n/a',
    },
    voucherMeals: {
      text: 'Utfordring (KM)',
      value: formatAmount(Math.floor(diet.voucherMealKcal), null, '0'),
    },
  }]

  return { user, featuredWeights, weightStats, waistStats, diaryStats, measurements }
}

export function formatAmount(amount, unit, fallback = 'n/a') {
  if (amount === null || amount === undefined || amount === false || isNaN(amount)) return fallback

  if (unit) return `${amount.toLocaleString('nb-NO')} ${unit}`

  return amount.toLocaleString('nb-NO')
}


export const betweenDates = (date, dateFrom, dateTo) => {
  return date > dateFrom && date <= dateTo
}

export const getChartData = (measurements, timeFrame = 1) => {
  const currentDate = moment()
  const values = []
  const labels = [];

  measurements = _.filter(measurements, function(val){
    return val.absence !== true;
  });


  if (timeFrame === 1) {
    for (let i=-3; i<=0; i++) {
      const dateFrom = currentDate.clone().add(i-1, 'weeks').format('YYYY-MM-DD')
      const dateTo = currentDate.clone().add(i, 'weeks').format('YYYY-MM-DD')

      const measurement = measurements.filter(m => betweenDates(m.date, dateFrom, dateTo)).pop() || {}
      values.push(measurement.weight)
      labels.push(measurement.date ? moment(measurement.date).format("W.YY") : moment(dateTo).format("W.YY"))
    }
    return { series: [values], labels:labels }
  }

  if (timeFrame === 3) {
    for (let i=-10; i<=0; i=i+2) {
      const dateFrom = currentDate.clone().add(i-2, 'weeks').format('YYYY-MM-DD')
      const dateTo = currentDate.clone().add(i, 'weeks').format('YYYY-MM-DD')

      const measurement = measurements.filter(m => betweenDates(m.date, dateFrom, dateTo)).pop() || {}
      values.push(measurement.weight)
      labels.push(measurement.date ? moment(measurement.date).format("W.YY") : moment(dateTo).format("W.YY"))


    }
    return { series: [values], labels: labels }
  }

  if (timeFrame === 12) {
    for (let i=-11; i<=0; i++) {
      const dateFrom = currentDate.clone().add(i-1, 'months').format('YYYY-MM-DD')
      const dateTo = currentDate.clone().add(i, 'months').format('YYYY-MM-DD')

      const measurement = measurements.filter(m => betweenDates(m.date, dateFrom, dateTo)).pop() || {}
      values.push(measurement.weight)
      labels.push(measurement.date ? moment(measurement.date).format("MM.YY") : moment(dateTo).format("MM.YY"))
    }
    return { series: [values], labels: labels }
  }
}

export const sortMeasurements = (measurements, sort) => {
  const sortFields = Object.keys(sort).map(key => {
    const sortOrder = (sort[key] === 'desc') ? '-' : ''

    return sortOrder + key
  })

  measurements.sort(dynamicSortMultiple(sortFields))

  return measurements
}

export const dynamicSort = property => {
  let sortOrder = 1

  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }

  return function (a,b) {
    let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0

    return result * sortOrder
  }
}

export const dynamicSortMultiple = fields => {
  return function (obj1, obj2) {
    let i = 0, result = 0, numberOfProperties = fields.length

    while(result === 0 && i < numberOfProperties) {
      result = dynamicSort(fields[i])(obj1, obj2)
      i++
    }

    return result
  }
}

export const formatHour = hour => {
  return (hour === DROP_IN_SLUG) ? DROP_IN_TEXT : hour
}

export function getWeeks(date) {
  return [...Array(8).keys()].map(i => {
    return moment(date).startOf('isoWeek').add((i * -1), 'weeks').format('W')
  }).reverse()
}

export function getParticipants(assistantUserListEdges=[], date) {
  return assistantUserListEdges.map(edge => {
    const {
      node: {
        weeklyDiaryCache: {
          data: weeklyData
        } = {},
        summaryMeasurementsCached: summeryData
      } = {}
    } = edge;

    const { diet, exercise } = JSON.parse(weeklyData || '{}');
    const summaryMeasurements = JSON.parse(summeryData || '{}');
    const {courseLocation} = edge.node;

    const {
      lastWeek = {},
      startCourse = {},
      startMeasurement = {}
    } = summaryMeasurements.computedValues;

    return {
      id: edge.node.userId,
      name: edge.node.name,
      totalEatenKcal: diet.totalEatenKcal,
      dietScore: diet.score,
      trainingPoints: exercise.exercisePoints,
      voucherMeals: (diet.voucherMealKcal) || null,
      waistCircumference: edge.node.currentWaistCircumference ? edge.node.currentWaistCircumference : "n/a",
      kcalMenu: edge.node.kcalMenu,
      weights: getWeights(edge.node.lastRegisteredWeights, date),
      weightDeltaWeek: courseLocation == "web" ? lastWeek.weight && lastWeek.weight.value : lastWeek.coursleaderWeight && lastWeek.coursleaderWeight.value,
      weightDeltaCourse: courseLocation == "web" ? startCourse.weight && startCourse.weight.value : startCourse.coursleaderWeight && startCourse.coursleaderWeight.value,
      weightDeltaTotal: courseLocation == "web" ? startMeasurement.weight && startMeasurement.weight.value : startMeasurement.coursleaderWeight && startMeasurement.coursleaderWeight.value,
    }
  })
}

export function getWeights(lastRegisteredWeights, date) {
  const weeks = getWeeks(date)
  const week_map = weeks.reduce((acc, curr, index) => {
    acc[curr] = null
    return acc
  }, {})

  const weights = lastRegisteredWeights.edges.reduce((acc, curr) => {
    const week = moment(curr.node.dateTime).format('W')
    const {
      node: {
        data
      } = {}
    } = curr
    const { weight: diaryWeight, coursleaderWeight } = JSON.parse(data || '{}')
    const weight = diaryWeight || coursleaderWeight

    // If there are 2 or more weighings in a week, show the most recent one
    if (acc[week] === null) {
      acc[week] = weight
    }

    return acc
  }, week_map)

  return weeks.map(week => weights[week])
}

export function sortParticipants(participants, sort = INITIAL_SORT_ORDER) {
  const sortFields = Object.keys(sort).map(key => (
    (sort[key] === 'desc') ? `-${key}` : key
  ))

  participants.sort(dynamicSortMultiple(sortFields))

  return participants
}
