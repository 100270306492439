import { gql } from 'apollo-boost'

const UPDATE_COURSE_PHASE = gql`
  mutation Assistant(
    $userId: Int
    $coursePhase: Int
  ) {
    assistant {
      assistantUpdateCoursePhase(userId: $userId, coursePhase: $coursePhase) {
        status
      }
    }
  }
`

export default UPDATE_COURSE_PHASE
