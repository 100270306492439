import { gql } from "apollo-boost";

const USER_QUERY = gql`
    query UserQuery {
	  request {
		user {
			activeInstructor		
			userData {
				userId
				name
				kcalMenu
				lastName
				firstName
				email
				asInstructor
				instructor {
					region
					webCourse
				}
			}
		}
	  }
	}
`

export default USER_QUERY;
