import { gql } from "apollo-boost";

const UPDATE_GOAL = gql`
  mutation Assistant(
    $userId: Int
    $startWeight: Float
    $weightGoal: Float
    $intermediateWeightGoal: Float
  ) {
    assistant {
      assistantWeightAndGoals(
        userId: $userId
        startWeight: $startWeight
        weightGoal: $weightGoal
        intermediateWeightGoal: $intermediateWeightGoal
      ) {
        status
      }
    }
  }
`

const UPDATE_GOAL2 = gql`
  mutation Assistant(
    $userId: Int
    $startWeight: Float
    $weightGoal: Float
    $intermediateWeightGoal: Float
  ) {
    assistant {
      assistantWeightAndGoals2(
        userId: $userId
        startWeight: $startWeight
        weightGoal: $weightGoal
        intermediateWeightGoal: $intermediateWeightGoal
      ) {
        status
      }
    }
  }
`

export {
	UPDATE_GOAL,
	UPDATE_GOAL2
};
