import React, { Fragment, useState } from 'react';
import {motivations} from '../../utils/Constants'

const GoalItem = ({motivation, ...props}) => {
    return <Fragment>
      {motivation && (
        <li><span>Mål</span> {motivations[motivation]}</li>
      )}
    </Fragment>
}

export default GoalItem;
