import { gql } from "apollo-boost";

const ASSISTANT_SEND_SMS = gql`
mutation Assistant($userId: [Int]!, $smsMessage: String!) {
	assistant {
		assistantSendSms(userId: $userId, smsMessage: $smsMessage) {
			status
			message
		}
	}
}
`

export default ASSISTANT_SEND_SMS;
