import React, { Fragment, useState } from 'react';
import RoedeCourse from  '../../atom/RoedeCourse/index.js';
import Loading from '../../atom/Loading';
import { Link } from 'react-router-dom';

const RoedeCourseWeek = ({
    loading,
    assistantCourseCalendar,
    assistantNettcourseCalendar
}) => {
  const course_week = JSON.parse(assistantCourseCalendar || '{}')
  const nettcourses = JSON.parse(assistantNettcourseCalendar || '[]')

  return(
    <div className="courseCalendarBody">
      <div className="cc-grid header">
        <div className="cc-grid-item grid-align-center">kl.</div>
        <div className="cc-grid-item">Mandag</div>
        <div className="cc-grid-item">Tirsdag</div>
        <div className="cc-grid-item">Onsdag</div>
        <div className="cc-grid-item">Torsdag</div>
        <div className="cc-grid-item">Fredag</div>
        <div className="cc-grid-item">Lørdag</div>
        <div className="cc-grid-item">Søndag</div>
      </div>
      {nettcourses.map(nettcourse => (
        <div className="cc-grid body" key="nettkurs">
          <div className="cc-grid-item grid-align-center">
            <div className="cc-center">
              <Link to={`/nettcourse-details/${nettcourse.id}/`} className="cc-link">Nettkurs</Link>
            </div>
          </div>
          {Object.values(nettcourse.number_of_spots).map((spots, key) => (
            <div className="cc-grid-item" key={key}>{spots}</div>
          ))}
        </div>
      ))}
      {loading ? <Loading/> : Object.keys(course_week).map((entry, index) => (
          <div className="cc-grid body" key={index}>
            <div className="cc-grid-item grid-align-center">
              <div className="cc-center">{entry}</div>
            </div>
            <div className="cc-grid-item">
              {
                course_week[entry][1].length > 0 && course_week[entry][1].map((obj, i) => (
                  <RoedeCourse key={i} course={obj} />
                ))
              }
            </div>
            <div className="cc-grid-item">
              {
                course_week[entry][2].length > 0 && course_week[entry][2].map((obj, i) => (
                  <RoedeCourse key={i} course={obj} />
                ))
              }
            </div>
            <div className="cc-grid-item">
              {
                course_week[entry][3].length > 0 && course_week[entry][3].map((obj, i) => (
                  <RoedeCourse key={i} course={obj} />
                ))
              }
            </div>
            <div className="cc-grid-item">
              {
                course_week[entry][4].length > 0 && course_week[entry][4].map((obj, i) => (
                  <RoedeCourse key={i} course={obj} />
                ))
              }
            </div>
            <div className="cc-grid-item">
              {
                course_week[entry][5].length > 0 && course_week[entry][5].map((obj, i) => (
                  <RoedeCourse key={i} course={obj} />
                ))
              }
            </div>
            <div className="cc-grid-item">
              {
                course_week[entry][6].length > 0 && course_week[entry][6].map((obj, i) => (
                  <RoedeCourse key={i} course={obj} />
                ))
              }
            </div>
            <div className="cc-grid-item">
              {
                course_week[entry][7].length > 0 && course_week[entry][7].map((obj, i) => (
                  <RoedeCourse key={i} course={obj} />
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default RoedeCourseWeek;
