import { gql } from "apollo-boost";

const GET_WEIGHTBOOK_USER = gql`
	query Assistant($userId: Int!, $dateTo: String) {
		request {
			grassistant {
				assistantGetWeighingsData(userId: $userId, dateTo: $dateTo)
			}
		}
	}
`

export default GET_WEIGHTBOOK_USER;
