import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import HeightItem from '../../atom/HeightItem';
import GoalItem from '../../atom/GoalItem';
import RestingMetabolismItem from '../../atom/RestingMetabolismItem';
import TrainingMetabolismItem from '../../atom/TrainingMetabolismItem';
import CalculatedRecommendedKcal from '../../atom/CalculatedRecommendedKcal';
import KcalMenu from '../../atom/KcalMenu';
import ActivityLevel from '../../atom/ActivityLevel';
import ExerciseHabits from '../../atom/ExerciseHabits';
import HealthProblems from '../../atom/HealthProblems'
import AllergiesDetails from '../../atom/AllergiesDetails'
import UserCommentNew from '../../atom/UserCommentNew'
import InitialWeight from '../../atom/InitialWeight'
import BmiItem from '../../atom/BmiItem'
import WeightItem from '../../atom/WeightItem'
import moment from 'moment'

import { Link } from 'react-router-dom'


const genders = {
  male: "Mann",
  female: "Kvinne",
}

export default function UserInfo({ user, report, callback }) {
  const [isInfoOpen, toggleInfo] = useState(false);
  const [isSticky, setSticky] = useState(false)

  let lastScrollY = 0
  let ticking = false
  const handleScroll = useCallback(() => {
    lastScrollY = window.scrollY
    if (!ticking) {
      window.requestAnimationFrame(() => {
        setSticky(lastScrollY > 40)
        ticking = false
      })
      ticking = true
    }
  }, [])

  const userInfoRef = useRef(null)
  const [userInfoHeight, setUserInfoHeight] = useState(0)

  let resizeObserver
  const handleResize = useCallback(() => {
    setUserInfoHeight(userInfoRef.current.clientHeight)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(userInfoRef.current)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      resizeObserver.unobserve(userInfoRef.current)
    }
  }, [])

  const weekYearNumber = moment(report.firstReport.week).format('YYYYWW')
  const firstReportLink = `/nettkursukerapport/${weekYearNumber}/${report.firstReport.id}/`
  const diaryLink = `/nye-dagbok/${report.userId}/`

  return (
    <div className="wcwr-user-info-wrapper" style={{height: `${userInfoHeight}px`}}>
      <div className="wcwr-user-info" data-open={isInfoOpen} data-sticky={isSticky} ref={userInfoRef}>
        <ul>
          <li className="user">
            <Link to={`/nye-kursdeltakere/${report.userId}`}>
              <strong>{user.name}</strong>
            </Link>
            
            {user.gender && <Fragment> {genders[user.gender]}, </Fragment>}
            {user.age > 0 && <Fragment>{user.age} år </Fragment>}
            Medlemskap: {report?.membership?.statusText}
            {report.id !== report.firstReport.id &&
              <Link to={firstReportLink} className="first-membership" target="_blank">Onboarding</Link>
            }
            <Link to={diaryLink} className="first-membership" target="_blank">Dagbok registreringer siste 7 dager</Link>
            <button className="toggleInfo" onClick={() => toggleInfo(!isInfoOpen)}>
              <i className="buttonIcon icon-arrow-1-left"></i>
            </button>
          </li>
          <HeightItem
            height={user.height}
          />
          <InitialWeight
            initialWeight={report.startWeight}
          />
          <GoalItem
            motivation={user.motivation}
          />
          <RestingMetabolismItem
            restingMetabolism={user.restingMetabolism}
          />
          <TrainingMetabolismItem
            trainingMetabolism={user.trainingMetabolism}
          />
          <CalculatedRecommendedKcal
            recommendedKcalIn={user.calculatedRecommendedKcalIn}
          />
          <KcalMenu
            kcalMenu={user.recommendedKcalIn}
          />
          <ActivityLevel
            activityLevel={user.activityLevel}
          />
          <ExerciseHabits
            exerciseHabits={user.exerciseHabits}
          />
          <HealthProblems
            data={user.healthProblems}
          />
          <AllergiesDetails
            data={user.allergiesList}
          />
          <BmiItem
            height={user.height}
            weight={user.weight}
          />
          <BmiItem
            height={user.height}
            weight={user.initialWeight}
            start
          />
          <WeightItem
            weight={user.weight}
          />
          <WeightItem
            weight={user.initialWeight}
            date={user.startDate}
            start
          />
          <UserCommentNew
            userId={report.userId}
            userComment={user.comment}
            callback={callback}
          />
        </ul>
      </div>
    </div>
  );
}
