import React, { Fragment, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom'

const genders = {
  male: "Mann",
  female: "Kvinne",
}

const UserName = ({
  user,
  userId,
  ...props
}) => {
    const [copied, setCopied] = useState(false);

    if (copied) {
      setTimeout(function(){
        setCopied(false);
      }.bind(this), 1600)
    }

    return <Fragment>
      <Link to={`/nye-kursdeltakere/${userId ? userId : user.userId}`}>
        <strong>{user.name}</strong>
      </Link>
      hq-id: {user.userId},
      {user.gender && <Fragment> {genders[user.gender]}, </Fragment>}
      {user.age > 0 && <Fragment>{user.age} år</Fragment>}
      <CopyToClipboard text={`${user.name}: ${userId ? userId : user.userId}`}
        onCopy={() => setCopied(true)}>
          <a href="#" className="name_copy_button">Copy</a>
      </CopyToClipboard>
      {copied ? <div className="copied-wrapper"><div className="copied-static">Kopiert</div></div> : null}
    </Fragment>

}

export default UserName;
