import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';


import GET_COURSE_MEETINGS from '../query/get_course_meetings.js';
import { GET_SCHEDULE_PLAN } from '../query/get_schedule_plan.js'

import Loading from '../atom/Loading';

import MeetingWeek from '../ui/MeetingWeek';


import moment from 'moment'

const WeekNavigation = ({ week }) => {
  const currentWeek = moment(week, 'YYYYWW')
  const previousWeek = moment(week, 'YYYYWW').subtract(1, 'week')
  const nextWeek = moment(week, 'YYYYWW').add(1, 'week')

  return (
    <nav className="wb-dateNav">
      <Link className="prev" to={`/week/${previousWeek.format('YYYYWW')}/`}>
        <i className="icon-arrow-2-left"></i>
      </Link>
      <strong>Uke {currentWeek.format('W')}</strong>
      <span>
        ({currentWeek.startOf('isoWeek').format('DD.MM.YYYY')} -
        {currentWeek.endOf('isoWeek').format('DD.MM.YYYY')})
      </span>
      <Link className="next" to={`/week/${nextWeek.format('YYYYWW')}/`}>
        <i className="icon-arrow-2-right"></i>
      </Link>
    </nav>
  )
}

const Breadcrumbs = () => (
    <div className="headWrapper">
        <div className="headText">
            Kurskalender
        </div>
        <nav className="breadcrumbs">
            <ul>
                <li><Link to="/">Hovedside</Link></li>
                <li><Link to={`/course-calendar/`}>Kurskalender</Link></li>
            </ul>
        </nav>
    </div>
)


const RoedeWeekCalendar = (props) => {
    const week = props.match.params.week || moment().format('YYYYWW')
    const date = moment(week, 'YYYYWW')
    const dateFrom = date.format('YYYY-MM-DD')
    const dateTo = date.add(6, 'days').format('YYYY-MM-DD')



    const {
        loading,
        data: {
          request: {
            grassistant:{
              assistantCourseMeeting: {
                edges  = []
              }={}
            }={}
          }={}
        }={},
        refetch
    } = useQuery(GET_COURSE_MEETINGS, {
        variables: {
            
            dateFrom: dateFrom,
            dateTo: dateTo
        },
    });
    
    const meetings = edges.map((node) => node.node )

    const year = date.format("YYYY");
    const planType = 'personal';
  
    const {

          data: {
        request: {
          grassistant: {
            assistantCourseSchedule = {}
          } = {}
        }={}
      } = {}
      } = useQuery(GET_SCHEDULE_PLAN, {
      variables: {
        year: date.format("YYYY"),
        planType: planType
      },
      fetchPolicy: "cache-and-network"
    });

    return <div id="CalendarContainer">
        <div className="courseCalendarContainer">

            <WeekNavigation week={week} />
            <MeetingWeek
                loading={loading}
                meetings={meetings}
                date={date}
                assistantCourseSchedule={assistantCourseSchedule}
            />
        </div>
    </div>

}

export default RoedeWeekCalendar
