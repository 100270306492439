import { gql } from "apollo-boost";

const ASSISTANT_MOVE_TO_COURSE = gql`
mutation Assistant($courseId: Int!, $memberships: [Int]!) {
  assistant {
    assistantMoveToCourse(courseId: $courseId, memberships: $memberships) {
      status
    }
  }
}
`

export default ASSISTANT_MOVE_TO_COURSE;
