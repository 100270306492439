import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        const currLocation = this.props.location.pathname + this.props.location.search;
        const prevLocation = prevProps.location.pathname + prevProps.location.search;

        if (currLocation !== prevLocation) {
            window.scrollTo(0, 0);
        }
    }
    
    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop);
