import { gql } from "apollo-boost";

const ASSISTANT_WEBCOURSE_WEEK_REPORT_FEEDBACK = gql`
  mutation Assistant(
    $id: Int!
    $data: JSONString!
    $nextReportDate: Date
    $feedbackAttachments: [Int]!
  ) {
    assistant {
      assistantWebcourseWeekReportFeedback(
        id: $id
        data: $data
        nextReportDate: $nextReportDate
        feedbackAttachments: $feedbackAttachments
      ) {
        status
        message
      }
    }
  }
`;

export default ASSISTANT_WEBCOURSE_WEEK_REPORT_FEEDBACK;
