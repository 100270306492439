import React from 'react';

import { useQuery } from '@apollo/client';
import Loading from '../../atom/Loading';

import {
  GET_FILTER_DATA,
  GET_FILTER_DATA2
} from '../../query/get_filter_data.js'


const UsersFilter = ({
  emailAllowed,
  status,
  courseSubtype,
  setStatus,
  setEmailAllowed,
  setCourseSubtype,
  filterOpen,
  page,
  ...props
}) => {

    const {
      loading,
      data: {
        request: {
          grassistant: {
            assistantUsersFilter
          }={}
        }={}
      } = {}
    } = useQuery(page === 'kursedeltakere' ? GET_FILTER_DATA : GET_FILTER_DATA2, {
      variables: {
        status: status
       }
    });

    var participantFilters = JSON.parse(assistantUsersFilter || '[]');

    return (
      <div className="filterDetailsContainer">

        <div className="filterItem emailAllowedContainer">
          <div className="filterItemName">Samtykket til epost</div>
          <div className="filterGridContainer">
            <div className="filterGridItem">
              <input
                type="radio"
                name="email_allowed"
                className="regularCheckbox"
                checked={emailAllowed == true}
                data-status={true}
                data-waschecked={emailAllowed == true ? true : false}
                onClick={(e) => setEmailAllowed(!emailAllowed)}
                onChange={(e) => function() {}}
              /> <span className="checkboxNames">Samtykket til epost</span>
            </div>
          </div>
        </div>

        <div className="filterItem courseStatusType">
          <div className="filterItemName">Status</div>
          <div className="filterGridContainer statusGrid">
            <div className="filterGridItem">
              <input
                type="radio"
                id="status_active"
                name="statusRadio"
                className="regularCheckbox"
                checked={status == "active"}
                data-status={"active"}
                data-waschecked={status == "active" ? true : false}
                onClick={(e) => setStatus('active')}
                onChange={(e) => function() {}}
              /> <span className="checkboxNames">Aktive deltakere</span>
            </div>
            <div className="filterGridItem">
              <input
                type="radio"
                id="status_paused"
                name="statusRadio"
                className="regularCheckbox"
                checked={status == "paused"}
                data-status={"paused"}
                data-waschecked={status == "paused" ? true : false}
                onClick={(e) => setStatus('paused')}
                onChange={(e) => function() {}}
              /> <span className="checkboxNames">Paused membership</span>
            </div>
            <div className="filterGridItem">
              <input
                type="radio"
                id="status_active"
                name="statusRadio"
                className="regularCheckbox"
                checked={status == "inactive"}
                data-status={"inactive"}
                data-waschecked={status == "inactive" ? true : false}
                onClick={(e) => setStatus('inactive')}
                onChange={(e) => function() {}}
              /> <span className="checkboxNames">Avsluttede kursdeltakere</span>
            </div>
          </div>
        </div>
        <div className="filterItem courseTypeContainer">
          {participantFilters.length > 0 && <div className="filterItemName">Kurstype</div>}
          {loading ? <Loading /> : (
            <div className="filterGridContainer">
            {
              participantFilters.map((obj, index)=>
                <div className="filterGridItem" key={index}>
                  <input
                    type="checkbox"
                    name="course_subtype[]"
                    className="regularCheckbox subtypeCheckbox"
                    data-type={obj.bookCode ? obj.bookCode : ""}
                    checked={courseSubtype.indexOf(obj.bookCode) >= 0}
                    onClick={() => setCourseSubtype(obj.bookCode)}
                    onChange={() => function() {}}
                  /> <span className="checkboxNames">{ obj.bookCode }</span>
                </div>
              )
          }
          </div>)}
        </div>


      </div>
    )
}


export default UsersFilter;
