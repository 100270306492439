import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'

import NoUsersMembership from '../../atom/NoUsersMembership';

const NextMeeting = ({
  nextMeeting,
  ...props
}) => {

    return <div className="grid-column">
      {nextMeeting.length > 0 ? <NextMeetingList nextMeeting={nextMeeting} /> : <NoUsersMembership>Det er ikke noe neste møte</NoUsersMembership>}
    </div>
}

const NextMeetingList = ({
  nextMeeting,
  ...props
}) => {


  return <div className="list-container">
    <div className="list-item">
      {
        nextMeeting.map((obj, index) =>
          <div className="url-wrapper" key={index}>
            Neste møte: <Link to={`/course-details/${obj.course_id}/course-meeting/${obj.meeting_id}`}>{obj.week}</Link>
          </div>
        )
      }
    </div>
  </div>
}

export default NextMeeting;
