import React from 'react'
import MembershipOverview from './MembershipOverview'
import MissingPayments from './MissingPayments'

export default function HomePage() {
  return (
    <div className="homePageContainer">
      <div className="homeMenuMemberOverview">
        <MembershipOverview />
      </div>
      <div className="homeMissingPayments">
        <MissingPayments />
      </div>
    </div>
  )
}
