import { gql } from "apollo-boost";

const USER_FEED = gql`
query Assistant {
  request {
    grassistant {
      assistantUserFeed
    }
  }
}
`;

export default USER_FEED;
