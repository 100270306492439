import React, { Fragment, useState } from 'react';

import {
  formatNumber,
  getClassName,
  getType,
} from '../../utils/GetCacheQuery'

const DiaryDayDiet = ({
  data: { nutrition, meals, extras, heartFriendly=false },
  ...props
}) => (
  <section className="diarySection">
    <h2 className="diarySectionTitle">Kosthold</h2>
    <div>
      <ul className="diaryBoxes">
        <li data-featured="true">Kcal
          <span className="minmax_wrapper">{nutrition.kcalMin && nutrition.kcalMax ? `${nutrition.kcalMin} - ${nutrition.kcalMax}` : '\u00A0'}</span>
          <span>
            <strong>
              {formatNumber(nutrition.kcal)}
            </strong> <em>kcal</em>
          </span>
        </li>
        <li data-featured="true">Score
          <span className="minmax_wrapper">&nbsp;</span>
          <span><strong>{formatNumber(nutrition.dietScore)}</strong></span>
        </li>
        <li data-featured="true">Utfordring (KM)
          <span className="minmax_wrapper">&nbsp;</span>
          <span><strong>{formatNumber(meals['voucherMeal'].kcal/25)}</strong></span>
        </li>
      </ul>
      <ul className="diaryBoxes">
        <li className={getClassName('proteins', nutrition.proteins, nutrition.proteinsMin, nutrition.proteinsMax)}>Proteiner
          <span className="minmax_wrapper">{nutrition.proteinsMin*100} - {nutrition.proteinsMax*100}</span>
          <span><strong>{formatNumber(nutrition.proteins*100)}</strong> <em>%</em></span>
        </li>
        <li className={getClassName('carbs', nutrition.carbs, nutrition.carbsMin, nutrition.carbsMax)}>Karbohydrater
          <span className="minmax_wrapper">{nutrition.carbsMin*100} - {nutrition.carbsMax*100}</span>
          <span><strong>{formatNumber(nutrition.carbs*100)}</strong> <em>%</em></span>
        </li>
        <li className={getClassName('fat', nutrition.fat, nutrition.fatMin, nutrition.fatMax)}>Fett
          <span className="minmax_wrapper">{nutrition.fatMin*100} - {nutrition.fatMax*100}</span>
          <span><strong>{formatNumber(nutrition.fat*100)}</strong> <em>%</em></span>
        </li>
      </ul>
      <ul className="diaryBoxes">
        <li className={getClassName('calcium', nutrition.calcium, nutrition.calciumMin, nutrition.calciumMax)}>Kalsium
          <span className="minmax_wrapper">{nutrition.calciumMin} - {nutrition.calciumMax}</span>
          <span><strong>{formatNumber(nutrition.calcium)}</strong> <em>mg</em></span>
        </li>
        <li className={getClassName('fruits', nutrition.fruits, nutrition.fruitsMin, nutrition.fruitsMax)}>Frukt
          <span className="minmax_wrapper">{nutrition.fruitsMin} - {nutrition.fruitsMax}</span>
          <span><strong>{formatNumber(nutrition.fruits)}</strong> <em>g</em></span>
        </li>
        <li className={getClassName('vegetables', nutrition.vegetables, nutrition.vegMin, nutrition.vegMax)}>Grønnsaker
          <span className="minmax_wrapper">{nutrition.vegMin} - {nutrition.vegMax}</span>
          <span><strong>{formatNumber(nutrition.vegetables)}</strong> <em>g</em></span>
        </li>
      </ul>
      <ul className="diaryBoxes">
        <li className={getClassName('energyDensity', nutrition.energyDensity, nutrition.energyDensityMin, nutrition.energyDensityMax)}>Energitetthet
          <span className="minmax_wrapper">{nutrition.energyDensityMin} - {nutrition.energyDensityMax}</span>
          <span><strong>{formatNumber(nutrition.energyDensity)}</strong> <em>kcal</em></span>
        </li>
        <li className={getClassName('fiber', nutrition.fiber, nutrition.fiberMin, nutrition.fiberMax)}>Fiber
          <span className="minmax_wrapper">{nutrition.fiberMin} - {nutrition.fiberMax}</span>
          <span><strong>{formatNumber(nutrition.fiber)}</strong> <em>g</em></span>
        </li>
        <li className={getClassName('nuts', nutrition.nuts, nutrition.nutsMin, nutrition.nutsMax)}>Nøtter
          <span className="minmax_wrapper">{nutrition.nutsMin} - {nutrition.nutsMax}</span>
          <span><strong>{formatNumber(nutrition.nuts)}</strong> <em>g</em></span>
        </li>
      </ul>
      <ul className="diaryBoxes">
        <li>Omega-3 <span>{extras.supplementPillWasTaken && <i className="icon-check" />}</span></li>
        <li>Hjertevennlig <span>{heartFriendly && <i className="icon-check" />}</span></li>
        <li>Vann <span><strong>{extras.numGlassOfWater || 0}</strong></span></li>
      </ul>
    </div>
    <ul className="diaryMeals">
      {Object.values(meals).map((meal, index) => (
      <li key={index}>
        <h3>{meal.name} <span><strong>{meal.kcal}</strong> <em>kcal</em></span></h3>
        <ul className="diaryMealItems">
          {meal.items.map(item => (
          <li key={item.id}>
            <span className="dmiRow">
              <em>{getType(item.type)}</em> {item.name}
            </span>
            <span className="dmiRow">
              <span className="dmiQuantity">{item.quantity} {item.unit}</span>
              {item.isHeartFriendly && (
              <span className="dmiFeature">Hjertevennlig</span>
              )}
            </span>
            <span className="dmiKcal">{item.kcal}</span>
          </li>
          ))}
        </ul>
      </li>
      ))}
    </ul>
  </section>
)

export default DiaryDayDiet;
