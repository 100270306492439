import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import GET_COURSE_CALENDAR from '../query/get_course_calendar.js'

import Loading from '../atom/Loading';
import CoursesMigration from '../atom/CoursesMigration';
import RoedeCourseFilter from '../ui/RoedeCourseFilter';
import RoedeCourseWeek from '../ui/RoedeCourseWeek';


const Breadcrumbs = () => (
    <div className="headWrapper">
        <div className="headText">
            Kurskalender
        </div>
        <nav className="breadcrumbs">
            <ul>
                <li><Link to="/">Hovedside</Link></li>
                <li><Link to={`/course-calendar/`}>Kurskalender</Link></li>
            </ul>
        </nav>
    </div>
)


const RoedeCourseCalendar = () => {

    const [haveUsers, setHaveUsers] = useState();
    const [courseType, setCourseType] = useState([]);

    const {
        loading,
        data: {
            request: {
                grassistant: {
                    assistantCourseCalendar,
                    assistantNettcourseCalendar,
                } = {}
            } = {}
        }={}
    } = useQuery(GET_COURSE_CALENDAR, {
        variables: {
            haveUsers: haveUsers,
            courseType: courseType
        },
    });

    const setFilter = (type, value) => {
        if(type === 'have_members') {
            setHaveUsers(value);
        }

        if(type === 'course_type') {
            if(courseType.includes(value)) {
                setCourseType(courseType.filter(function(item, index, arr){
                    return item != value;
                }));
            } else {
                setCourseType([...courseType, value]);
            }

        }
    }

    return <div id="CalendarContainer">
        <div className="courseCalendarContainer">
            <Breadcrumbs/>
            <RoedeCourseFilter
                haveUsers={haveUsers}
                courseType={courseType}
                setFilter={setFilter}
            />
            <CoursesMigration />
            <RoedeCourseWeek
                loading={loading}
                assistantCourseCalendar={assistantCourseCalendar}
                assistantNettcourseCalendar={assistantNettcourseCalendar}
            />
        </div>
    </div>

}

export default RoedeCourseCalendar
