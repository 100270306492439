import React, { Fragment } from "react";
import { calculateBmi } from "../../utils/Constants";

export default function BmiItem({ height, weight, start = false }) {
  const bmi = calculateBmi(height, weight);
  return (
    <Fragment>
      {bmi && (
        <li>
          <span>bmi {start && `start`}</span> {bmi}
        </li>
      )}
    </Fragment>
  );
}
