import { gql } from "apollo-boost";

const GET_DIARY_DAY_PARTICIPANT2 = gql`
query Assistant($userId: Int, $status: String, $date: Date) {
	request {
		grassistant {
			assistantGetUser: assistantGetUser2(userId: $userId, status: $status) {
				name
				userId
				name
				userLevelName
				kcalMenu
				fullImageUrl
				dailyDiaryCache(date: $date) {
					data
				}
				diaryRegistration(date: $date) {
					edges {
						node {
							registrationId
							category
							tag
							data
							calories
						}
					}
				}
			}
		}
	}
}
`

export default GET_DIARY_DAY_PARTICIPANT2;
