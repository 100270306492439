import React from 'react';

import {
  formatAmount
} from '../../utils/GetCacheQuery';

const ParticipantItem = ({
  participant,
  index,
  ...props
}) => {
  return (
    <tr className="ParticipantItem Body" key={index}>
      <td>{participant.name}</td>
      <td>{formatAmount(participant.totalEatenKcal, null, '0', 0)}</td>
      <td>{formatAmount(participant.dietScore, null, '0')}</td>
      <td>{formatAmount(participant.trainingPoints, null, '0')}</td>
      <td>{formatAmount(participant.voucherMeals, null, '0', 0)}</td>
      <td>{formatAmount(participant.waistCircumference, null, '0')}</td>
      <td>{participant.kcalMenu}</td>
      {participant.weights.map((weight, index) => (
        <td key={index}>{formatAmount(weight, 'kg')}</td>
      ))}
      <td>{formatAmount(participant.weightDeltaWeek, 'kg')}</td>
      <td>{formatAmount(participant.weightDeltaCourse, 'kg')}</td>
      <td>{formatAmount(participant.weightDeltaTotal, 'kg')}</td>
    </tr>
  )
}

export default ParticipantItem;
