import { gql } from "apollo-boost";

const SAVE_SCHEDULE_PLAN = gql`
mutation Assistant(
  $year: String!,
  $planType: String,
  $blacklist: JSONString
) {
  assistant {
    assistentChangeSchedulePlan(
      year: $year,
      blacklist: $blacklist,
      planType: $planType
    ) {
      status
      message
    }
  }
}
`

export default SAVE_SCHEDULE_PLAN;
