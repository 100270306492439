import React, { Fragment, useState } from 'react';
import {exerciseHabitsData} from '../../utils/Constants'


const ExerciseHabits = ({exerciseHabits, ...props}) => {
    return <Fragment>
      {exerciseHabits && (
        <li><span>Svett trening per uke</span> {exerciseHabitsData[exerciseHabits]}</li>
      )}
    </Fragment>
}

export default ExerciseHabits;
