import React from 'react';
import { Route } from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import SalesOverview from './components/pages/SalesOverview';
import RoedeCourseCalendar from './components/pages/RoedeCourseCalendar';
import RoedeWeekCalendar from './components/pages/RoedeWeekCalendar';
import RoedeCourseDetails from './components/pages/RoedeCourseDetails';
import RoedeNettcourseDetails from './components/pages/RoedeNettcourseDetails';
import CourseMeetings from './components/pages/CourseMeetings';
import CourseMeetingDetails from './components/pages/CourseMeetingDetails';
import CoursePlanner from './components/pages/CoursePlanner';
import NetCoursePlanner from './components/pages/NetCoursePlanner';
import WeightBookNew from './components/pages/WeightBookNew';

import UserList from './components/pages/UserList';
import UserListNew from './components/pages/UserListNew';
import UserCard from './components/pages/UserCard';
import UserCardNew from './components/pages/UserCardNew';

import TrainerSettings from './components/pages/TrainerSettings';
import DiaryNew from './components/pages/DiaryNew';
import DiaryDayNew from './components/pages/DiaryDayNew';
import WeightBookResultNew from './components/pages/WeightBookResultNew';
import WeightDrilldownUserNew from './components/pages/WeightDrilldownUserNew';
import WeightDrilldownCourseNew from './components/pages/WeightDrilldownCourseNew';

import CommissionInstructor from './components/pages/CommissionInstructor';
import FileManager from './components/pages/FileManager';
import WebCourseWeekReports from './components/pages/WebCourseWeekReports';
import WebCourseWeekReport from './components/pages/WebCourseWeekReport';
import SalesStats from './components/pages/SalesStats';
import NettcourseParticipants from './components/pages/NettcourseParticipants'
import PreviewReport from './components/pages/PreviewReport'
import PageNotFound from './components/pages/PageNotFound'
import CourseMeetingsCalendar from './components/pages/CourseMeetingsCalendar';

import { user } from './components/container/Auth';
import { routepath } from './config';

let redirectUrl = process.env.REACT_APP_API_URL + 'login?next='+window.location.href;



const routes = [
    { path: routepath.sales_overview, component: SalesOverview},
    { path: routepath.home, component: HomePage },
    { path: `/course-calendar`, component: RoedeCourseCalendar},
    { path: `/course-calendar/`, component: RoedeCourseCalendar},
    { path: `/week/`, component: RoedeWeekCalendar},
    { path: `/week/:week?/`, component: RoedeWeekCalendar},
    { path: `/course-details/:id`, component: RoedeCourseDetails},
    { path: `/course-details/:id/`, component: RoedeCourseDetails},
    { path: `/course-details/:id/course-meeting`, component: CourseMeetings},
    { path: `/course-details/:id/course-meeting/`, component: CourseMeetings},
    { path: `/course-details/:id/course-meeting/:meetingId`, component: CourseMeetingDetails},
    { path: `/course-details/:id/course-meeting/:meetingId/`, component: CourseMeetingDetails},
    { path: `/nettcourse-details/:id/`, component: RoedeNettcourseDetails},
    { path: `/course-planner`, component: CoursePlanner},
    { path: `/course-planner/`, component: CoursePlanner},
    { path: `/course-planner/:plan_type`, component: CoursePlanner},
    { path: `/course-planner/:plan_type/`, component: CoursePlanner},
    { path: `/nettcourse-planner`, component: NetCoursePlanner},
    { path: `/nettcourse-planner/`, component: NetCoursePlanner},
    { path: `/nye-vektbok/:date([0-9]{4}-[0-9]{2}-[0-9]{2})?/`, component: WeightBookNew },
    { path: `/kursedeltakere`, component: UserList},
    { path: `/kursedeltakere/`, component: UserList},
    { path: `/nye-kursdeltakere`, component: UserListNew},
    { path: `/nye-kursdeltakere/`, component: UserListNew},
    { path: `/nye-kursdeltakere/:id`, component: UserCardNew },
    { path: `/nye-kursdeltakere/:id/`, component: UserCardNew },
    { path: `/kurslederinnstillinger`, component: TrainerSettings},
    { path: `/kurslederinnstillinger/`, component: TrainerSettings},
    { path: '/nye-dagbok/:userId/', component: DiaryNew },
    { path: '/nye-dagbok/:userId', component: DiaryNew },
    { path: `/nye-dagbok/:userId/:date/`, component: DiaryDayNew },
    { path: `/nye-dagbok/:userId/:date`, component: DiaryDayNew },
    { path: '/vektbok/:date([0-9]{4}-[0-9]{2}-[0-9]{2})?/:userId([0-9]+)/nye-vektutvikling/', component: WeightBookResultNew },
    { path: '/nye-vektbok/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/:hour([0-9]{1}\:00|drop\-in)/:participants/', component: WeightDrilldownUserNew },
    { path: '/nye-vektbok/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/:hour([0-9]{2}\:[0-9]{2}|drop\-in)/:participants/', component: WeightDrilldownUserNew },
    { path: `/kurs/:courseId/nye-vektbok/`, component: WeightDrilldownCourseNew },
    { path: '/provisjonsrapport', component: CommissionInstructor},
    { path: '/provisjonsrapport/', component: CommissionInstructor},
    { path: '/filer-for-kursdeltakere', component: FileManager},
    { path: '/filer-for-kursdeltakere/', component: FileManager},
    { path: '/nettkursukerapport', component: WebCourseWeekReports },
    { path: '/nettkursukerapport/:week?/', component: WebCourseWeekReports },
    { path: '/nettkursukerapport/:week/:id/', component: WebCourseWeekReport },
    { path: '/nettkursukerapport/:week/:id/preview', component: PreviewReport },
    { path: '/salgsstatistikk', component: SalesStats },
    { path: '/salgsstatistikk/', component: SalesStats },
    { path: '/nettcourse-participants', component: NettcourseParticipants },
    { path: '/nettcourse-participants/', component: NettcourseParticipants },
    // o2 routes...
    { path: `/kursedeltakere/:id`, component: UserCard },
    { path: `/kursedeltakere/:id/`, component: UserCard },

    { path: `/kalender`, component: CourseMeetingsCalendar },    
    { path: `/kalender/:week?/`, component: CourseMeetingsCalendar },



    // { path: '/kurs/', component: PageNotFound},
    // { path: `/kurs/:id`, component: CourseCard},
    // { path: `/kurs/:id/`, component: CourseCard},
    // { path: `/vektbok/:date([0-9]{4}-[0-9]{2}-[0-9]{2})?/`, component: WeightBookOld },
    // { path: '/vektbok/:date([0-9]{4}-[0-9]{2}-[0-9]{2})?/:userId([0-9]+)/vektutvikling/', component: WeightBookResult },
    // { path: '/dagbok/:userId/', component: Diary },
    // { path: '/dagbok/:userId', component: Diary },
    // { path: `/dagbok/:userId/:date/`, component: DiaryDay },
    // { path: `/dagbok/:userId/:date`, component: DiaryDay },
    // { path: '/vektbok/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/:hour([0-9]{1}\:00|drop\-in)/:participants/', component: WeightDrilldownUser },
    // { path: '/vektbok/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/:hour([0-9]{2}\:[0-9]{2}|drop\-in)/:participants/', component: WeightDrilldownUser },
    // { path: `/kurs/:courseId/vektbok/`, component: WeightDrilldownCourse },
];

class Redirect extends React.Component {
  render(){
    return (
      <div className="loading_container">
        <div className="loading_wrapper">
          <div className="loading">
            <a href={redirectUrl}>Logg inn</a>
          </div>
          {/* <div className="dot dot_one"></div>
          <div className="dot dot_two"></div>
          <div className="dot dot_three"></div> */}
        </div>
      </div>
    );
  }
}

const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return React.createElement(component, finalProps);
}

const PropsRoute = ({ component, ...rest }) => {
    return (
        <Route {...rest} render={routeProps => {
          const { match, location, history } = routeProps;
          return user.isAuthenticated() ? renderMergedProps(component, routeProps, { match, location, history, user: user.user }) : <Redirect/>
        }}/>
    );
}

export { routes };

export default PropsRoute;
