import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'
var _ = require('underscore');

import Loading from '../atom/Loading';
import ActionFooter from '../atom/ActionFooter'
import UserListPagination from '../atom/UserListPagination';
import UserAutocomplete from '../ui/UserAutocomplete';
import UsersFilter from '../ui/UsersFilter';
import UsersList from '../ui/UsersList';

const PAGE = 'kursedeltakere';

import GET_USERS_LIST from '../query/get_users_list.js'
const PAGE_LIMIT = 100;

const Breadcrumbs = ({pathname}) => (
  <div className="headWrapper">
    <div className="headText">Kursdeltakere</div>
    <div className="breadcrumbsWrapper">
      <nav className="breadcrumbs">
        <ul>
          <li><Link to="/">Hovedside</Link></li>
          <li><Link to="/kursedeltakere">Kursdeltakere</Link></li>
        </ul>
      </nav>

    </div>
  </div>
)


const UserList = ({
	match,
	...props
}) => {

		const [filterOpen, setFilterOpen] = useState(false);
		const [userId, setUserId] = useState();
		const [status, setStatus] = useState('active');
		const [emailAllowed, setEmailAllowed] = useState(false);
		const [courseSubtype, setCourseSubtype] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [userList, setUserList] = useState([]);

		const setSearchUser = (e, userId, status) => {
			setUserId(userId);
			setStatusFlag(status);
		}

		const setStatusFlag = (status) => {
			setStatus(status)
			setPageNo(1)
		}

		const setCourseSubtypeList = (subtype) => {

			if (courseSubtype.includes(subtype)) {
				setCourseSubtype(courseSubtype.filter((e) => (e != subtype)))
			} else {
				setCourseSubtype([...courseSubtype, subtype]);
			}
			setPageNo(1)
	  }

		const setEmailAllowedFlag = (flag) => {
			setEmailAllowed(flag)
			setPageNo(1)
		}

		const selectAll = (e, participantsList) => {
      if(e.target.checked) {
        setUserList(participantsList)
      } else {
        setUserList([]);
      }
    }

		const selectUser = (e, obj) => {
      if(userList.filter((x) => (x.userId == obj.userId)).length > 0) {
        setUserList(userList.filter((x) => (x.userId != obj.userId)))
      } else {
        setUserList([...userList, obj])
      }
    }

		const {
	    loading,

	    data: {
	      request: {
	          grassistant: {
							assistantUsers=""
	          }={}
	      }={}
	    }={}
	  } = useQuery(GET_USERS_LIST, {
	      variables: {
					userId: userId,
					status: status,
					emailAllowed: emailAllowed,
					courseSubtype: courseSubtype,
					page: pageNo,
					limit: PAGE_LIMIT,
				}
	  });

		const result = JSON.parse(assistantUsers || '{}');

    const participantsList = result.users || [];
    const totalCount = result.count ? result.count : 0;
    const { hasMore } = result;


    return <div className="pageContainer participantsContainer">
      <div className="pageHeadWrapper participantsHead">
        <Breadcrumbs pathname={'/kursedeltakere/'}/>
				<UserAutocomplete
					setUserId={setSearchUser}
					page={PAGE}
				/>
				{
					<div className="filterWrapper">
	          <div className={filterOpen ? 'filterItemContainer filterButton active' : 'filterItemContainer filterButton'} onClick={() => setFilterOpen(!filterOpen)}>
	            <i className="icon-config-1"></i>
	            <div className="filterText">Filter</div>
	          </div>
	            {
								filterOpen && <div className="filterItemContainer filterList">
									<UsersFilter
		                emailAllowed={emailAllowed}
		                status={status}

		                courseSubtype={courseSubtype}
		                setStatus={setStatusFlag}
		                setEmailAllowed={setEmailAllowedFlag}
		                setCourseSubtype={setCourseSubtypeList}
		                filterOpen={filterOpen}
										page={PAGE}
		              />
								</div>
							}
	        </div>
				}
			</div>
			<div className="participantsBody">
				{
					loading ? <Loading/> : <UsersList
						participantsList={participantsList}
						totalCount={totalCount}
						hasMore={hasMore}
						page={PAGE}
						selectAll={selectAll}
						selectUser={selectUser}
            userList={userList}
					/>
				}
			</div>
			{
        !loading && <UserListPagination
  				pageNo={pageNo}
  				setPageNo={setPageNo}
  				hasMore={hasMore}
  			/>
      }
      {
        userList.length > 0 && <ActionFooter
          userList={userList}
          showEmail={true}
          showSms={true}
        />
      }
    </div>
}


export default UserList;
