import React, { Fragment, useState } from 'react';

import {
  formatNumber,
  getDuration
} from '../../utils/GetCacheQuery'

const DiaryDayExercise = ({ data: { activities, exercise } }) => (
  <section className="diarySection">
    <h2 className="diarySectionTitle">Mosjon</h2>
    {activities && (
    <div className="diaryActivities">
      <h3>Activitet <span><strong>{exercise.kcal}</strong> <em>kcal</em></span></h3>
      <ul>
        {activities.map((activity, index) => (
        <li key={index}>
          <span className="daRow">{activity.name}</span>
          <span className="daRow">
            {getDuration(activity.duration)}
            <span className="daIntensity">{activity.intensity}</span>
          </span>
          <span className="daKcal"><strong>{formatNumber(activity.kcal)}</strong></span>
        </li>
        ))}
      </ul>
    </div>
    )}
    <ul className="diaryBoxes">
      <li>Skritt <span><strong>{formatNumber(exercise.numSteps)}</strong></span></li>
      <li>Treningspoeng <span><strong>{formatNumber(exercise.trainingPoints)}</strong></span></li>
      <li>Styrkepoeng <span><strong>{formatNumber(exercise.strengthPoints)}</strong></span></li>
    </ul>
  </section>
)

export default DiaryDayExercise;
