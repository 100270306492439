import React, { Fragment, useState } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client';
import loadingImg from '../../SysIons/loading.svg';
import NoResults from '../NoResults';

import SUBSTITUTE_INSTRUCTOR_BULK from '../../../mutation/substitute_instructor_bulk.js';
import ASSISTENT_INSTRUCTOR_SEARCH from '../../../query/assistent_instructor_search.js';

var timeout = null;

const SubstituteInstructorBulk = ({
  close,
  ...props
}) => {

    const [substituteId, setSubstituteId] = useState();
    const [substituteInstructor, setSubstituteInstrictor] = useState();

    const {
      courseMeetingId,
      callback,
    } = props

    const [loadSearch, {
      called,
      loading: ll_loading,
      data: ll_data
    }] = useLazyQuery(ASSISTENT_INSTRUCTOR_SEARCH, {
      fetchPolicy: 'no-cache',
    });

    const [saveFunction, { data, loading }] = useMutation(SUBSTITUTE_INSTRUCTOR_BULK, {
      onCompleted() {
        close();
      }
    });

    const submit = () => {
      try {
        if(courseMeetingId.length <= 0) {
          throw Error("You didn't select any meetings");
        }

        saveFunction({ variables: {
          courseMeetingId: courseMeetingId,
          substituteId: substituteId
        }});
      } catch(err) {
        alert(err)
      }
    }

    const handleInstructorChange = (e) => {
      const search = e.target.value;
      setSubstituteInstrictor(e.target.value)

      clearTimeout(timeout);
      timeout = setTimeout(function () {
        if(search !== "") {
          loadSearch({
              variables: { search: search }
          });

        }
      }.bind(this), 500);
    }

    console.log("ll_data", substituteId);

    return (<div className="ModalBody">
      <div className="ModalBody">
        <div className="search_container">
          <label>Innbytterinstruktør</label>
          <input
            type="text"
            name="substituteInstructor"
            value={substituteInstructor ? substituteInstructor : ""}
            onChange={handleInstructorChange}
            autoComplete="off"
          />
        </div>
        <div className="substitute_list">
          {ll_data?.request?.grassistant?.assistentInstructorSearch?.edges.map((obj, index) => (
            <div className="substitute_list_item" key={index}>
              <input className="radio-input" type="radio" name="substitute" checked={obj?.node?.instructorId == substituteId} onChange={() => setSubstituteId(obj?.node?.instructorId)} /> <label>{obj?.node?.name}</label>
            </div>
          ))}
        </div>

      </div>

      <footer>
        <div className="buttonContainer pullRight">
          <div className="buttonWrapper">
            {
              loading ? <img src={loadingImg} alt="Processing" className="processing_image"/> :
                <button type="submit" className="buttonItem save" onClick={() => submit()}>Lagre</button>
            }
          </div>
        </div>
      </footer>
    </div>)
}

export default SubstituteInstructorBulk;
