import { gql } from "apollo-boost";

const USER_SCHEMA_NEW = gql`
query Assistant($userId: Int, $status: String, $showAll: Boolean) {
	request {
		grassistant {
			assistantActiveUsersMemberships(userId: $userId) {
				membershipId
				membershipType
				name
				nextMeeting
				courseId
				nettcourseId
				paymentSource
				statusText
				userId
				nettcourseInstructorId
				roedeCourseId
				customerEvents(showAll: $showAll) {
					objectId
					event
					message
					timestamp
				}
			}
			assistantGetUser2(userId: $userId, status: $status) {
				membershipId
				status
				userId
				emailAllowed
				smsAllowed
				email

				weight
				gender
				birthDate
				comment
				motivation
				restingMetabolism
				trainingMetabolism
				recommendedKcalIn
				kcalMenu
				activityLevel
				exerciseHabits
				contractTo

				cellPhone
				motivationImage

				healthProblems
				allergiesList
				name
				gender
				age
				height

				emailAllowed
				smsAllowed
				startWeight
				instructorWeight

				coursePhase
				coursePhaseStarted
			}
		}
	}
}
`

// user {
// initialWeight
// 	name
// 	gender
// 	age
// 	height
// 	initialWeight
// 	userId
//
// 	emailAllowed
// 	smsAllowed
// }

export default USER_SCHEMA_NEW;
