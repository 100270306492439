import React, { Fragment, useState } from 'react';
import ChartistGraph from 'react-chartist';
import CTLegendPlugin from '../CTLegendPlugin';

const BarChart = ({
  itemsSold=0,
  budget=0,
  deviation=0
}) => {
    const data = {
      series: [
        { "name": "Avvik", "data": [itemsSold], "classNames": "ct-bar-color-3", "sortBy": 20},
        { "name": "Budsjett", "data": [budget], "classNames": "ct-bar-color-2", "sortBy": 50 },
        { "name": "Antall solgte", "data": [deviation], "classNames": "ct-bar-color-1", "sortBy": 70 },
      ]
    };

    const options = {
      horizontalBars: true,
      low: 0,
      high: 100,
      axisY: {
        offset: 0,
      },
      axisX: {
        scaleMinSpace: 100,
      },
      seriesBarDistance: 40,
      height: 180,
      plugins: [
        CTLegendPlugin()
      ]
    };

    var listener = {
      draw: function(data) {
        if(data.type === 'bar') {
          data.element.attr({
            'class': "ct-bar " + data.series.classNames
          });
        }
      }
    }

    var type = 'Bar'

    return <div className="bar-chart-container">
      <ChartistGraph data={data} listener={listener} options={options} type={type} />
    </div>
}

export default BarChart;
