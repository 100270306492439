import React, { Fragment, useState } from 'react';
import { useMutation } from "@apollo/client";
import ASSISTENT_UPDATE_COMMENT from '../../mutation/assistent_update_comment.js'
import sanitizeHtml from "sanitize-html";
import {sanitizeConf} from '../../utils/Constants'

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";



const UserCommentNew = ({
  userId,
  userComment,
  callback,
  ...props
}) => {
    const [isCommentOpen, toggleComment] = useState(false);
    const [comment, setComment] = useState(userComment);

    const [updateComment, { loading }] = useMutation(ASSISTENT_UPDATE_COMMENT, {
      onCompleted() {
        toggleComment(false);
      }
    });

    function submitComment(event) {
      event.preventDefault();
      updateComment({
        variables: {
          userId: userId,
          userComment: sanitizeHtml(comment, sanitizeConf),
        }
      });
    }

    return <li className="comment">
      <Container className="container-wrapper">
        <Row>
          <Col><span>Kommentar</span></Col>
          <Col>{!isCommentOpen && (
            <button type="button" onClick={() => toggleComment(!isCommentOpen)}>
              Rediger <i className="buttonIcon icon-edit-1"></i>
            </button>
          )}</Col>
        </Row>
      </Container>
      {!isCommentOpen && (
        <div>
          <div className="text" dangerouslySetInnerHTML={{ __html: comment }} />
        </div>
      )}
      {isCommentOpen && (
        <form name="editcomments"
          method="post"
          data-loading={loading}
          onSubmit={submitComment}>
          <textarea
            name="comments"
            rows="3"
            value={comment}
            onChange={(e) => setComment(e.target.value)}></textarea>
          <button type="button" onClick={() => toggleComment(!isCommentOpen)}>
            Avbryt
          </button>
          <button type="submit">Lagre</button>
        </form>
      )}
    </li>
}

export default UserCommentNew;
