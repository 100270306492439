import { gql } from "apollo-boost";

const ASSISTANT_SALES_OVERVIEW = gql`
query Assistant {
  request {
          grassistant {
                    assistantSalesOverview {
                                commisionYear
                                noParticipantsTotal
							  }
                  }
        }
    }
`

export default ASSISTANT_SALES_OVERVIEW;
