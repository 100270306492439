import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'
var _ = require('underscore');

import NotFound from '../atom/NotFound';
import Loading from '../atom/Loading';
import WeekNavigation from '../atom/WeekNavigation';
import CalendarGrid from '../atom/CalendarGrid';
import CourseMeetingsItem from '../atom/CourseMeetingsItem';
import ActionFooter from '../atom/ActionFooter'

import RoedeCourseFilter from '../ui/RoedeCourseFilter';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import COURSE_MEETINGS_CALENDAR from '../query/course_meetings_calendar.js';

const Breadcrumbs = () => (
  <div className="headWrapper">
    <h1>Ukekalender</h1>

		<Breadcrumb>
		  <Breadcrumb.Item href="/">Hovedside</Breadcrumb.Item>
		  <Breadcrumb.Item href={`/kalender`} active>
		    Hovedside
		  </Breadcrumb.Item>
		</Breadcrumb>
  </div>
)

function parse_data(edges) {
  const data = edges.reduce((groups, items) => {
    const dt = moment(items.node.startTime)
    if(!groups[dt.format('H')]) {
      groups[dt.format('H')] = {};
    }

    if(!groups[dt.format('H')][dt.format('E')]) {
      groups[dt.format('H')][dt.format('E')] = []
    }

    groups[dt.format('H')][dt.format('E')].push({
      id: items?.node?.courseMeetingId,
      name: items?.node?.course?.autoNameShort,
      courseId: items?.node?.course?.courseId,
      startTime: items?.node?.startTime,
      endTime: items?.node?.endTime,
      attendance: items?.node?.attendanceCount,
      instructorName: items?.node?.instructor?.name,
      instructorId: items?.node?.instructor?.instructorId,
    })

    return groups;
  }, {});

  return data
}

function filter_data(meetingsList, edges) {
  return edges.filter(function(item){
      return meetingsList.includes(item.node.courseMeetingId);
  });
}


const CourseMeetingsCalendar = ({
	match,
	...props
}) => {
  const week = match.params.week || moment().format('YYYY-MM-DD')
  const [haveUsers, setHaveUsers] = useState();
  const [courseType, setCourseType] = useState([]);

  const [meetingsList, setMeetingList] = useState([]);

  const selectMeeting = (value) => {
    if(meetingsList.includes(value)) {
        setMeetingList(meetingsList.filter(function(item, index, arr){
            return item != value;
        }));
    } else {
        setMeetingList([...meetingsList, value]);
    }
  }

	const {
		loading,
    refetch,
		data: {
      request: {
        grassistant: {
          assistantCourseMeeting: {
            edges=[]
          }={}
        } = {}
      } = {}
    } = {}
	} = useQuery(COURSE_MEETINGS_CALENDAR, {
		variables: {
      date: week,
      courseType: courseType,
      haveUsers: haveUsers
		},
	});




  const setFilter = (type, value) => {
      if(type === 'have_members') {
          setHaveUsers(value);
      }

      if(type === 'course_type') {
          if(courseType.includes(value)) {
              setCourseType(courseType.filter(function(item, index, arr){
                  return item != value;
              }));
          } else {
              setCourseType([...courseType, value]);
          }

      }
  }



  return <div id="CalendarContainer">
		<Breadcrumbs/>
    <div className="participantsBody userCard">
      <WeekNavigation url={'kalender'} date={week}/>

      <RoedeCourseFilter
        haveUsers={haveUsers}
        courseType={courseType}
        setFilter={setFilter}
      />


      <div className="courseCalendarContainer">
        <CalendarGrid
          loading={loading}
          data={parse_data(edges)}
          Container={CourseMeetingsItem}
          selectMeeting={selectMeeting}
          meetingsList={meetingsList}
        />
      </div>

      {
        meetingsList.length > 0 && <ActionFooter
          meetingsList={meetingsList}
          showMoveMeeting={true}
          additionalClass='set-sticky'
          callback={refetch}
          listData={filter_data(meetingsList, edges)}
        />
      }

      {!loading && edges.length <= 0 && <NotFound
        children="Deltaker ikke funnet"
        />}
    </div>
  </div>
}

export default CourseMeetingsCalendar;
