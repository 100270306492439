import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'

import queryString from 'query-string';

import Loading from '../atom/Loading';
import UserDetails from '../ui/UserDetails';
import UserMembershipsDetails from '../ui/UserMembershipsDetails';
import MembershipUserCard2 from '../ui/MembershipUserCard2';
import NotFound from '../atom/NotFound';
import CustomerEvents from '../ui/CustomerEvents';




import Modal from '../ui/Modal';


import USER_SCHEMA_NEW from '../query/user_schema_new.js';
const PAGE = 'nye-kursdeltakere';

const Breadcrumbs = ({ name, userId }) => (
  <nav className="breadcrumbs">
    <ul>
      <li><Link to="/">Hovedside</Link></li>
			<li><Link to="/nye-kursdeltakere/">[NY] Kursvekt</Link></li>
      <li><Link to={`/nye-kursdeltakere/${userId}`}>{name}</Link></li>
    </ul>
  </nav>
)

const UserCardNew = ({
	match,
	...props
}) => {

  const [ showCourses, showCoursesHandler ] = useState(false);
  const parsed = queryString.parse(props.location.search);

  const [showAllEvents, setAllEvents] = useState(false);
  const [tabNumber, setTabNumber] = useState(false)

	const {
		loading,
		refetch,
		data: {
			request: {
        grassistant: {
          assistantGetUser2: user = {},
          assistantActiveUsersMemberships: memberships = []
        } = {}
      } = {}
		} = {}
	} = useQuery(USER_SCHEMA_NEW, {
		variables: {
			userId: match.params.id,
      status: parsed.status,
      showAll: showAllEvents
		}
	});


	if (loading) {
		return <div className="LoadWrapper">
			<Loading />
		</div>;
	}

  if (!user) {
    return <div className="pageContainer participantsContainer">
      <div className="participantsBody userCard">
        <NotFound
          children="Deltaker ikke funnet"
        />
      </div>
    </div>
  }

  return <div className="pageContainer participantsContainer">
		<div className="pageHeadWrapper participantsHead">
			<Breadcrumbs name={user.name} userId={user.userId}/>
		</div>
		<div className="participantsBody userCard">
			<UserDetails
				user={user}
        callback={refetch}
        match={match}
        page={PAGE}
			/>
      {memberships.length > 0 && <UserMembershipsDetails
        user={user}
        memberships={memberships}
        match={match}
        refetch={refetch}
      />}

      {
        process.env.NODE_ENV !== 'production' && memberships.length > 0 && <CustomerEvents
          showAllEvents={showAllEvents}
          setAllEvents={setAllEvents}
          refetch={refetch}
          memberships={memberships}
          tabNumber={tabNumber}
          setTabNumber={setTabNumber}
        />
      }

      <div className="participantDetailItem challengesContainer">
        <div className="userchallenges backgroundContainer">
          <div className="showDetails" onClick={() => showCoursesHandler(!showCourses)}>Inaktivt medlemskap</div>
          {showCourses && <div className="challengesWrapper">
            <MembershipUserCard2
              page={PAGE}
              match={match}
              status={'inactive'}
            />
          </div>}
        </div>
      </div>
		</div>
	</div>
}

export default UserCardNew;
