import { gql } from "apollo-boost";

const GET_REGION = gql`
	query Assistant {
    request {
        grassistant {
            assistantGetRegion
        }
    }
	}
`

export default GET_REGION;
