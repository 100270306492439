import React from "react";
import format from "./format.js";
import images from "./images.js";

export function ProgressBar({
  value,
  recommended,
  min,
  max,
  title,
  unit,
  inline = false,
}) {
  const result = value >= min && value <= max ? "good" : "bad";
  const decimals = unit === "poeng" ? 1 : 0;

  let valueText = "";
  let recommendedText = "";
  let width = (value / max) * 100;
  width = width > 100 ? 100 : width;
  width = width < 0 ? 0 : width;
  width = Math.round(width * 100) / 100;

  if (unit === "%") {
    const min = format(recommended[0] * 100, decimals);
    const max = format(recommended[1] * 100, decimals);
    recommendedText = `av anbefalt ${min}% - ${max}%`;
    value = format(value * 100, decimals);
    valueText = `${value}${unit}`;
  } else {
    recommended = format(recommended, decimals);
    recommendedText = `av anbefalt ${recommended} ${unit}`;
    value = format(value, decimals);
    valueText = `${value} ${unit}`;
  }

  recommendedText = recommended === "0" ? "" : recommendedText;

  return (
    <li>
      {!inline && <h5>{title}</h5>}
      <div data-progressbar={result}>
        <div style={{ width: `${width}%` }}></div>
      </div>
      <div data-face={result}>
        <img src={images[`face${result}`]} alt="" />
      </div>
      <strong data-recommended={recommendedText}>
        {valueText}
        {inline && <span> {title.toLowerCase()}</span>}
      </strong>
    </li>
  );
}

export function ProgressImage({ value, recommended, title, unit, image }) {
  const result = value >= recommended ? "good" : "bad";
  const full = value ? Math.round(value) : 0;
  const empty = recommended - full;
  const srcFull = `${image}full`;
  const srcEmpty = `${image}empty`;

  return (
    <li>
      <div data-images={image}>
        {Array(full)
          .fill()
          .map((_, i) => (
            <img key={`img${i}`} src={images[srcFull]} alt="" />
          ))}
        {empty &&
          empty > 0 &&
          Array(empty)
            .fill()
            .map((_, i) => (
              <img key={`img${i}`} src={images[srcEmpty]} alt="" />
            ))}
      </div>
      <div data-face={result}></div>
      <strong data-recommended={`av anbefalt ${recommended} ${unit}`}>
        {full} {unit} <span>{title.toLowerCase()}</span>
      </strong>
    </li>
  );
}

export function ProgressFlag({ value, recommended, title, image }) {
  value = format(value, 0);
  const result = value >= recommended ? "good" : "bad";
  const status = value >= recommended ? "full" : "empty";
  const flag = value >= recommended ? "Spist" : "Ingen";
  const src = `${image}${status}`;

  return (
    <li>
      <div data-images={image}>
        <img src={images[src]} alt="" />
      </div>
      <div data-face={result}></div>
      <strong>
        {flag} <span>{title.toLowerCase()}</span>
      </strong>
    </li>
  );
}
