import { gql } from "apollo-boost";

const GET_USERS_LIST = gql`
  query Assistant($userId: Int, $status: String, $emailAllowed: Boolean, $courseType: String, $courseSubtype: [String], $page: Int, $limit: Int) {
    request {
      grassistant {
        assistantUsers(userId: $userId, status: $status, emailAllowed: $emailAllowed, courseType: $courseType, courseSubtype: $courseSubtype, page: $page, limit: $limit)
      }
    }
  }
`;

export default GET_USERS_LIST;
