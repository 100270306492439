import { gql } from "apollo-boost";

const GET_COURSE_PARTICIPANTS_OLD = gql`
query Assistant(
    $courseId: Int,
) {
  request {
    grassistant {
      assistantCourseParticipants:assistantUserList(
        courseId: $courseId,
      ) {
        edges {
          node {
            name
            userId
            email
            cellPhone
            emailAllowed
            smsAllowed
            isActive
            userStartDate
            userEndDate
            statusText
            renewalStatus
            paymentSourceHandler
          }
        }
      }
    }
  }
}
`

export default GET_COURSE_PARTICIPANTS_OLD;
