import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import NotFound from '../atom/NotFound'
import Loading from '../atom/Loading'
import NettcourseListFilter from '../atom/NettcourseListFilter'
import NettcoursTable from '../ui/NettcoursTable'
import NettcourseParticipantSearch from '../ui/NettcourseParticipantSearch'
import ActionFooter from '../atom/ActionFooter'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Pagination from 'react-bootstrap/Pagination'
import NETTCOURSE_PARTICIPANT from '../query/nettcourse_participant.js'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
var _ = require('underscore')

export default function NettcourseParticipants() {
  const [page, setPage] = useState(1)
  const [userId, setUserId] = useState()
  const [weekday, setWeekday] = useState(0)
  const [status, setStatus] = useState('active')
  const [emailAllowed, setEmailAllowed] = useState(false)
  const [userList, setUserList] = useState([])
  const limit = 20



  const {
    loading,
    data: {
      request: { grassistant: { assistantUsersNettcourse } = {} } = {},
    } = {},
  } = useQuery(NETTCOURSE_PARTICIPANT, {
    variables: {
      page: page,
      limit: limit,
      userId: userId,
      weekday: weekday,
      status: status,
      emailAllowed: emailAllowed,
    },
    onCompleted() {
      setUserList([])
    },
  })



  const result = JSON.parse(assistantUsersNettcourse || '{}')
  const pages = [
    ...Array(Math.ceil((result.count ? result.count : 0) / limit)).keys(),
  ]

  const selectUser = (e, obj) => {
    if (userList.filter((x) => x.userId == obj.userId).length > 0) {
      setUserList(userList.filter((x) => x.userId != obj.userId))
    } else {
      setUserList([...userList, obj])
    }

    console.log(obj);
  }

  const selectAll = (e) => {
    if(e.target.checked) {
      setUserList(result.users)
    } else {
      setUserList([]);
    }
  }




  return (
    <div className="pageContainer participantsContainer">
      <Container fluid>
        <Row>
          <Col>
            <Breadcrumbs />
          </Col>
        </Row>
        <Row>
          <Col>
            <NettcourseParticipantSearch
              userId={userId}
              setUserId={setUserId}
              status={status}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <NettcourseListFilter
              weekday={weekday}
              setWeekday={setWeekday}
              emailAllowed={emailAllowed}
              setEmailAllowed={setEmailAllowed}
              status={status}
              setStatus={setStatus}
            />
          </Col>
        </Row>
        <Row>{
          <Col>
            <div className="participantsBody userCard">
              {loading && <Loading />}
              {!loading && _.isEmpty(result) && (
                <NotFound children="Deltaker ikke funnet" />
              )}
              {!loading && (
                <NettcoursTable
                  users={result.users}
                  total={result.count}
                  page={page - 1}
                  limit={limit}
                  selectUser={selectUser}
                  selectAll={selectAll}
                  userList={userList}
                  weekday={weekday}
                />
              )}
              <Pagination>
                {pages.map((number) => (
                  <Pagination.Item
                    key={number}
                    active={number + 1 === page}
                    onClick={() => setPage(number + 1)}
                  >
                    {number + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          </Col>
        }</Row>
      </Container>
      {userList.length > 0 && (
        <ActionFooter userList={userList} showEmail={true} showSms={true} />
      )}
    </div>
  )
}

function Breadcrumbs() {
  return (
    <div className="headWrapper">
      <h1>Dine nettkursmedlemmer</h1>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Hovedside</Breadcrumb.Item>
        <Breadcrumb.Item href={`/nettcourse-participants`} active>
          Dine nettkursmedlemmer
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  )
}
