import { gql } from "apollo-boost";

const GET_INSTRUCTOR_SETTINGS = gql`
	query Assistant {
		request {
			grassistant {
				assistentInstructor {
					instructorId
					name
					shortDescription
					introduction
				}
			}
		}
	}
`

export default GET_INSTRUCTOR_SETTINGS;
