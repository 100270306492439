import React, { Fragment, useState } from 'react';

const InstrucotWeightItem = ({
  weight,
  ...props
}) => {
  return <Fragment>
    {weight > 0 && (
      <li><span>Kursledervekt</span> {weight}</li>
    )}
  </Fragment>
}

export default InstrucotWeightItem;
