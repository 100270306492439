import { gql } from "apollo-boost";

const USERS_AUTOCOMPLETE = gql`
	query Assistant($keyword: String) {
	  request {
	    grassistant {
	      assistantUsersAutocomplete(keyword: $keyword)
	    }
	  }
	}
`;

const USERS_AUTOCOMPLETE2 = gql`
	query Assistant($keyword: String) {
	  request {
	    grassistant {
	      assistantUsersAutocomplete: assistantUsersAutocomplete2(keyword: $keyword)
	    }
	  }
	}
`;

export {
	USERS_AUTOCOMPLETE,
	USERS_AUTOCOMPLETE2
}
