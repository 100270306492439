import React from 'react'

const NoResults = ({ children }) => (
  <div className="pageContainer weightBook">
    <div className="emptyResultSet">
      {children}
    </div>
  </div>
)

export default NoResults
