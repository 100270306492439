import { gql } from "apollo-boost";

const COURSE_MEETINGS_CALENDAR = gql`
query Assistant($date: Date, $haveUsers: Boolean, $courseType: [Int]) {
	request {
		grassistant {
			assistantCourseMeeting(date: $date, haveUsers: $haveUsers,  courseType: $courseType) {
				edges {
					node {
						courseMeetingId
						startTime
						endTime
						attendanceCount
						instructor {
              instructorId
              name
            }
						course {
							courseId
							autoName
							autoNameShort
						}
					}
				}
			}
		}
	}
}
`

export default COURSE_MEETINGS_CALENDAR;
