import React, { Fragment, useState } from 'react';
import { user } from '../../container/Auth';

const CoursesMigration = ({...props}) => {
    

    const region = user.instructor?.region

    return <div className="course-action-container">
        <div className="course-action-wrapper">
            {region === "Øst" && <div className="course-action-item">
                <a className="buttonWrapper" href={`http://roede.com/kurs/import`} target="_blank">
                    <i className="buttonIcon buttonIcon icon-edit-1"></i>
                    <div className="buttonText">Kurs-Migrering</div>
                </a>
            </div>
            }
        </div>
    </div>
}

export default CoursesMigration;
