import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import NETTCOURSE_USER_SEARCH from '../../query/nettcourse_user_search.js'

const WAIT_INTERVAL = 2000
const ENTER_KEY = 13
var timeout = null

export default function NettcourseParticipantSearch({
  setUserId,
  status,
}) {
  const [search, setSearch] = useState('')
  const [keyword, setKeyward] = useState('')
  const [suggestions, setSuggestion] = useState([])

  const {
    data: {
      request: { grassistant: { assistantNettcourseUserSearch } = {} } = {},
    } = {},
  } = useQuery(NETTCOURSE_USER_SEARCH, {
    variables: {
      search: search,
      status: status,
    },
    onCompleted() {
      const users = JSON.parse(assistantNettcourseUserSearch || '[]')
      setSuggestion(users)
    },
  })

  const handleChange = (e) => {
    clearTimeout(timeout)
    const value = e.target.value
    setKeyward(value)

    timeout = setTimeout(function () {
      setSearch(value)
    }, WAIT_INTERVAL)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(timeout)
      var value = e.target.value
      setSearch(value)
    }
  }

  const searchHandler = () => {
    clearTimeout(timeout)
    setSearch(keyword)
  }

  const selectUser = (userId) => {
    setSuggestion([])
    setUserId(userId)
  }

  const cancelHandler = () => {
    setUserId()
    setKeyward('')
    setSearch('')
    setSuggestion([])
  }

  return (
    <div className="autocomplete_container">
      <div className="autocomplete_item input_wrapper">
        <InputGroup className="mb-3" size="lg">
          <FormControl
            placeholder="Søk etter medlem..."
            aria-label="Søk etter medlem..."
            aria-describedby="basic-addon2"
            value={keyword}
            onChange={(e) => handleChange(e)}
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <InputGroup.Text>
            {search !== '' ? (
              <Button variant="outline-secondary" onClick={cancelHandler}>
                Clear
              </Button>
            ) : (
              <Button variant="outline-secondary" onClick={searchHandler}>
                Search
              </Button>
            )}
          </InputGroup.Text>
        </InputGroup>
      </div>
      {suggestions.length > 0 && (
        <div className="autocomplete_item list_wrapper">
          <ListGroup>
            {suggestions.map((obj, index) => (
              <ListGroup.Item
                key={index}
                action
                variant="light"
                onClick={() => selectUser(obj['userId'])}
              >
                {obj['name']}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      )}
    </div>
  )
}
