import React, { Fragment, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'underscore';

import ASSISTANT_SEND_SMS from '../../mutation/assistant_send_sms.js'


import Modal from '../../ui/Modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import sanitizeHtml from "sanitize-html";
import SmsCounter from 'sms-counter';

const SmsButton = ({
  userList,
  children=null,
  disabled=false,
  ...props
}) => {
    const [emailTags, handleEmailTags] = useState([]);
    const [smsBody, setSmsBody] = useState("");

    const [currentLength, setCurrentLength] = useState(0);
    const [totalLength, setTotalLength] = useState(160);
    const [noMessages, setNoMessages] = useState(1);
    const [totalMessages, setTotalMessages] = useState(6);

    const [saveFunction, { data, loading }] = useMutation(ASSISTANT_SEND_SMS);

    const getUserList = () => {
      var userId = _.map(emailTags, function(obj) {
        return obj.userId;
      });

      return ['production', 'stage'].includes(process.env.NODE_ENV) ? userId : ["85832"];
    }

    const sendSms = (close) => {
      if(smsBody.length <= 0) {
        toast("Please enter text of the sms");
        throw "Please enter text of the sms";
      }

      const variables = {
        userId: getUserList(),
        smsMessage: smsBody
      }

      const res = saveFunction({ variables })

      res.then(({ data }) => {
        const {
          assistant: {
            assistantSendSms: {
              message,
              status
            } = {}
          } = {}
        } = data;

        if(status === 'error') {
          toast(message)
        } else {
          close()
        }
      }).catch(function(err) {
        toast((err && err.message) || 'An error ocurred while processing the request')
      })
    }

    const handleSmsBody = (text) => {
      const smsText = sanitizeHtml(text, {
        allowedTags: [],
        allowedAttributes: {}
      });

      const sms_count = SmsCounter.count(smsText);


      var per_message = 918;

      if(sms_count.encoding == "GSM_7BIT") per_message = 918;
      if(sms_count.encoding == "GSM_7BIT_EX") per_message = 918;
      if(sms_count.encoding == "UTF16") per_message = 402;

      if(sms_count.length <= per_message) {
        setSmsBody(smsText);

        setCurrentLength(sms_count.length)
        setTotalLength(sms_count.messages * sms_count.per_message > 0 ? sms_count.messages * sms_count.per_message : 160)
        setNoMessages(sms_count.messages > 0 ? sms_count.messages : 1)
      } else {
        toast("Sms message is too big.")
      }
    }


    useEffect(() => {
      handleEmailTags(userList)
    }, [userList])

    return <div className="button-item sms-button-container">
      <Modal
        type={"sms_form"}
        modalHeader={`Ny SMS`}
        sendHandler={sendSms}
        callback={() => function() {}}
        disabled={disabled}
        emailTags={emailTags}
        handleEmailTags={handleEmailTags}
        smsBody={smsBody}
        handleSmsBody={handleSmsBody}

        currentLength={currentLength}
        totalLength={totalLength}
        noMessages={noMessages}
        totalMessages={totalMessages}
      >
        {children ? children : <div className="buttonWrapper">
          <i className="buttonIcon icon-message-1"></i>
          <div className="buttonText">SMS</div>
        </div>}
      </Modal>
    </div>
}

export default SmsButton;
