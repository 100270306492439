import { gql } from "apollo-boost"

const ASSISTANT_WEBCOURSE_WEEK_REPORT = gql`
	query GetWebcourseWeekReport($id: Int!) {
    assistant {
      assistantWebcourseWeekReport(id: $id) {
        id: webcourseweekreportId
        userId
        week
        qa
        diaryData
        status
        feedback
        created
				startWeight
        initialWeight
        events
        reportSequence
        membership {
          membershipId
          statusText
          name
        }
        prevReport {
          id: webcourseweekreportId
          week
        }
        nextReport {
          id: webcourseweekreportId
          week
          feedback
          status
          isLastReport
          courseUpdate {
            title
            description
          }
        }
        user {
          name
          gender
          age
          height
          motivation
          restingMetabolism
          trainingMetabolism
          calculatedRecommendedKcalIn
          activityLevel
          exerciseHabits
          healthProblems
          allergiesList
          comment
					weight
          startDate
          initialWeight
        }
				attachments {
					edges {
						node {
							fileManagerId
							filename
							slug
							googleStorageFile
							downloadUrl
							slugUrl
              tags
						}
					}
				}
        firstReport {
          id: webcourseweekreportId
          membershipId
          week
        }
      }
    }
  }
`

export default ASSISTANT_WEBCOURSE_WEEK_REPORT
