import React, { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { dayOfWeek } from '../../utils/Constants'

const NettcourseParticipant = ({
  index,
  user,
  selectUser,
  userList,
  weekday,
  ...props
}) => {
    const [show, setShow] = useState(false);
    const more = user.memberships.length > 1

    const course = user.memberships.map((item, key) => (
      <ListGroup.Item
        key={key}
        onClick={() => setShow(!show)}
        className={`${!show && more ? 'more' : ''}`}
      >{item.course_title}</ListGroup.Item>
    ))

    const weekdate = user.memberships.map((item, key) => (
      <ListGroup.Item
        key={key}
        onClick={() => setShow(!show)}
        className={`${!show && more && 'more'}`}
      >{getWeekDay(key, weekday, item.weekday, show)}</ListGroup.Item>
    ))

    const status = user.memberships.map((item, key) => (
      <ListGroup.Item
        key={key}
        onClick={() => setShow(!show)}
        className={`status_container status_${item.status} ${!show && more && 'more'}`}
      >{item.statusText}</ListGroup.Item>
    ))


// className="userCheckbox regularCheckbox"
    return <tr>
      <td className="align_center">
          <Form.Check
            type="checkbox"
            onClick={(e)=>selectUser(e, user)}
            data-id={user.userId}
            data-name={user.name}
            data-email={user.email}
            data-email-allowed={user.emailAllowed}
            data-sms-allowed={user.smsAllowed}
            data-phone={user.cellPhone}
            data-is-active={user.status == 'active' ? 'active' : 'inactive'}
            checked={userList.filter((x) => (x.userId == user.userId)).length > 0}
            onChange={() => function() {}}
            disabled={!user.emailAllowed}
          />
      </td>
      <td>
        <Link to={`/nye-kursdeltakere/${user.userId}`}>
          {user.name}
        </Link>
      </td>
      <td>
        <ListGroup className={`nettcourse_wrapper ${show && 'show'}`}>
          {course}
        </ListGroup>
      </td>
      <td>
        <ListGroup className={`nettcourse_wrapper ${show && 'show'}`} data-more={!show && more}>
          {weekdate}
        </ListGroup>
      </td>
      <td>
        <ListGroup className={`nettcourse_wrapper ${show && 'show'}`} data-more={!show && more}>
          {status}
        </ListGroup>
      </td>
      <td className={`email_allowed_container`}>
        <Link to={`/nye-kursdeltakere/${user.userId}`}>
          {user.emailAllowed ? <i className="icon-check-circle"></i> : <i className="icon-cancel-circle"></i>}
        </Link>
      </td>
    </tr>
}

function getWeekDay(index, selectedWeekday, weekday, show) {
  if (selectedWeekday && index === 0 && !show) {
    return dayOfWeek[selectedWeekday]
  }
  return weekday ? dayOfWeek[weekday] : '\u00A0'
}

export default NettcourseParticipant;
