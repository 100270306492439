import React, {Fragment, useState, useEffect} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Loading from '../atom/Loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loadingImg from '../ui/SysIons/loading.svg';
import { Link } from 'react-router-dom';

import GET_INSTRUCTOR_SETTINGS from '../query/get_instructor_settings.js';
import SAVE_INSTRUCTOR_SETTINGS from '../mutation/save_instructor_settings.js';



const Breadcrumbs = () => (
  <nav className="breadcrumbs">
    <ul>
      <li><Link to="/">Hovedside</Link></li>
      <li>Kurslederinnstillinger</li>
    </ul>
  </nav>
)

const TrainerSettings = ({
  ...props
}) => {

    const {
      loading,
      refetch,
      data: {
        request: {
            grassistant: {
              assistentInstructor: {
                instructorId,
                name,
                shortDescription,
                introduction,
              }={}
            } = {}
        } = {}
      } = {}
    } = useQuery(GET_INSTRUCTOR_SETTINGS, {
      onCompleted() {
        setLength(shortDescription.length)
        setDescription(shortDescription)
        setIntroduction(introduction)
      }
    });

    const [saveFunction, { data: {
      assistant: {
        assistantSaveInstructorSettings: {
          message = "All saved"
        } = {}
      } = {}
    }={}, loading: processing }] = useMutation(SAVE_INSTRUCTOR_SETTINGS, {
      onCompleted() {
        refetch();
        toast(message)
      }
    });

    const totalLength = 80;
    const [length, setLength] = useState(0);
    const [description, setDescription] = useState("");
    const [instructorIntroduction, setIntroduction] = useState("");





    const handleDescription = (e) => {
      const { value } = e.target;

      if (value.length <= totalLength) {
        setDescription(value);
        setLength(value.length);
      }
    }

    const handleIntroduction = (e) => {
      const { value } = e.target;
      setIntroduction(value);
    }

    const saveChanges = () => {
      try {
        if ((description === '' || description === null) && (instructorIntroduction === '' || instructorIntroduction === null)) {
          throw 'You need to enter short description or introduction';
        }

        saveFunction({ variables: {
          shortDescription: description,
          introduction: instructorIntroduction
        }})
      } catch(err) {
        toast(err)
      };
    }


    return <div className="asssitant-container">
      <ToastContainer />
      <h1 className="assistant-header">Instructor {name}</h1>
      <Breadcrumbs/>
      {loading ? <Loading/> : <Fragment>

        <div className="input-container">
          <div className="label-wrapper">
            <span>Kort beskrivelse</span>   {length} / {totalLength}
          </div>
          <div className="input-wrapper">
            <textarea
              value={description ? description : ''}
              onChange={handleDescription}
            ></textarea>
          </div>
        </div>

        <div className="input-container">
          <div className="label-wrapper">
            <span>Introduksjon</span>
          </div>
          <div className="input-wrapper">
            <textarea
              value={instructorIntroduction ? instructorIntroduction : ''}
              onChange={handleIntroduction}
            ></textarea>

          </div>
        </div>

        <div className="input-container">
          <div className="button-wrapper right">
            {processing ? <div className="loadingWrapper">
              <img src={loadingImg} alt="Processing" />
            </div> : <div className="button" onClick={saveChanges}>Lagre</div>}
          </div>
        </div>
      </Fragment>}
    </div>
}


//
// const TrainerSettings = ({
//   ...props,
//   data: {
//     loading,
//     refetch,
//     request: {
//       grassistant: {
//         assistentInstructor = {}
//       } = {}
//     }={}
//   },
//   mutate
// }) => {
//
//   const {
//     name = "",
//     shortDescription = "",
//     introduction = ""
//   } = assistentInstructor;
//

//
//   const saveChanges = () => {
//
//     const variables={
//       "shortDescription": description,
//       "introduction": instructorIntroduction
//     }
//
//     setProcessing(true);
//
//     mutate({
//       variables
//     }).then(({ data }) => {
//       refetch();
//
//       const {
//         assistant: {
//           assistantSaveInstructorSettings: {
//             message = "All saved"
//           } = {}
//         } = {}
//       } = data;
//
//       toast(message);
//       setProcessing(false);
//     }).catch((errors) => {
//       const {
//         graphQLErrors = []
//       } = errors;
//
//       const {
//         message = "Something went wrong"
//       } = graphQLErrors[0];
//
//       toast(message);
//       setProcessing(false);
//     })
//   }
//
//   useEffect( () => {
//      setInit(loading)
//    }, [ loading ]
//   );
//

// }

// const GET_INSTRUCTOR_SETTINGS = gql`
// query Assistant {
//   request {
//     grassistant {
//       assistentInstructor {
//         instructorId
//         name
//         shortDescription
//         introduction
//       }
//     }
//   }
// }
// `
//
// const SAVE_INSTRUCTOR_SETTINGS = gql`
// mutation Assistant(
//   $shortDescription: String,
//   $introduction: String
// ) {
//   assistant {
//     assistantSaveInstructorSettings(
//       shortDescription: $shortDescription,
//       introduction: $introduction
//     ) {
//       status
//       message
//     }
//   }
// }
// `

// export default compose(
//   graphql(SAVE_INSTRUCTOR_SETTINGS),
//   graphql(GET_INSTRUCTOR)
// )(TrainerSettings)

export default TrainerSettings
