import React from 'react'
import Loading from '../atom/Loading'
import { Link } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import moment from 'moment'

const PAST_DUE_USERS = gql`
  query Assistant {
    request {
      grassistant {
        assistantPastDueUsersFeed
      }
    }
  }
`

export default function MissingPayments() {
  const { loading, data } = useQuery(PAST_DUE_USERS)

  if (loading) return <Loading />

  const { past_due_users: pastDueUsers } = JSON.parse(
    data.request.grassistant.assistantPastDueUsersFeed
  )

  if (pastDueUsers.length === 0) return null

  return (
    <Wrapper>
      <Title>Mangler Betaling</Title>
      <Row heading>
        <Item>Navn</Item>
        <Item>Forfallsdato</Item>
      </Row>
      {pastDueUsers.map((user) => (
        <Link to={`/nye-kursdeltakere/${user.user_id}`}>
          <Row key={user.user_id}>
            <Item>{user.name}</Item>
            <Item title={user.invoice_created_at}>
              {moment(user.invoice_created_at).format('DD.MM.YYYY')}
            </Item>
          </Row>
        </Link>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  color: #7b7b7a;
  margin-bottom: 16px;
  padding: 16px;
  transition: box-shadow 0.3s ease;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.6);
`

const Title = styled.div`
  left: 0px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.2px;
  color: black;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  font-size: 16px;
  border-bottom: ${(props) =>
    props.heading ? '1px solid rgba(0, 0, 0, 0.15)' : 'none'};
`

const Item = styled.div`
  padding: 8px 14px;
`
