import React, { Fragment, useState } from 'react';

import ItemMembershipName from '../../atom/ItemMembershipName';
import NextReport from '../NextReport';

const MembershipNettcourseItems = ({
  nettcourseItem,
  ...props
}) => {

    const nextMeeting = JSON.parse(nettcourseItem.nextMeeting || [])   

    return <Fragment>
      <ItemMembershipName
        membershipId={nettcourseItem.membershipId}
        membershipName={nettcourseItem.name}
        variant={nettcourseItem.paymentSource}
      />
      <NextReport
        nextMeeting={nextMeeting}
      />
      <div className="grid-column"></div>
    </Fragment>
}

export default MembershipNettcourseItems;
