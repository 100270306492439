import React, { Fragment, useState } from 'react';

const StatsSection = ({ title, week, data }) => (
  <section className="wbr-stats">
    <h3>
      {title}
      { week && <span>Uke: <strong>{week}</strong></span> }
    </h3>
    {data.map((stats, index) => (
      <dl key={index}>
        {Object.keys(stats).map(key => (
          <Fragment key={key}>
            {stats[key] &&
              <Fragment>
                <dt>{stats[key].text}</dt>
                <dd>{stats[key].value}</dd>
              </Fragment>
            }
          </Fragment>
        ))}
      </dl>
    ))}
  </section>
)

export default StatsSection;
