import React, { Fragment, useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import ReactDOM from 'react-dom';
import Calendar from './Calendar'
import moment from 'moment'



const DateNavigation = ({
  ...props
}) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const closeCalendar = () => {
    setShowCalendar(false)
  }

  const {
    location: { pathname },
    match: { params: { date } }
  } = props

  const [, section] = pathname.split('/')

  const currentDay = moment(date)
  const previousDay = currentDay.clone().add(-1, 'day').format('YYYY-MM-DD')
  const nextDay = currentDay.clone().add(1, 'day').format('YYYY-MM-DD')

  return (
    <Fragment>
      <nav className="DateNavigation2 shared" onClick={() => setShowCalendar(!showCalendar)}>
        <div className="DateNavigation2__content" >
          <div className="buttonWrapper">
            <div className="dateWrapper">
              {props.currentDateFormat ? moment(props.currentDate).format(props.currentDateFormat) : props.currentDate}
            </div>
            <i className="action-icons icon-calendar"></i>
          </div>
        </div>
      </nav>
      { showCalendar &&

        ReactDOM.createPortal(
          <Calendar close={closeCalendar} {...props} />,
          document.getElementById('calendar-root')
        )


      }
    </Fragment>
  )

}

export default withRouter(DateNavigation)
