import React, { Fragment, useState } from 'react';
import Modal from '../../ui/Modal';
import { Link } from 'react-router-dom'
import EmailButton from '../EmailButton';
import SmsButton from '../SmsButton';

const UserActionButtons = ({
  user,
  callback,
  match,
  page,
  ...props
}) => {

    const urls = {
      'nye-kursdeltakere': {
        'vektbok': `/vektbok/${user.userId}/nye-vektutvikling/`,
        'dagbok': `/nye-dagbok/${user.userId}/`
      },
      'kursedeltakere': {}
    }

    console.log();

    return <div className="user-action-buttons-container">
      <Fragment>
        <EmailButton
            userList={[user]}
            disabled={!user.emailAllowed}
            children={
              <div className="userActionButtonWrapper" data-enable={!user.emailAllowed}>
                <i className="action-icons icon-email-send"></i>
                <div className="textWrapper">E-post</div>
              </div>
            }
        />
        <SmsButton
          userList={[user]}
          disabled={!user.smsAllowed}
          children={
            <div className="userActionButtonWrapper" data-enable={!user.smsAllowed}>
              <i className="action-icons icon-message-1"></i>
              <div className="textWrapper">SMS</div>
            </div>
          }
        />
        <a href={"tel:"  + user.cellPhone}>
          <div className="userActionButtonWrapper">
            <i className="action-icons icon-old-telephone"></i>
            <div className="textWrapper">Phone</div>
          </div>
        </a>
        <Modal
          type={"weighing"}
          from={"user_card"}
          modalHeader={`Vektbok - ${user.name}`}
          callback={callback}
          user={{
            id: user.userId,
            name: user.name
          }}
          page={page}
          match={match}
        >
          <Link to="#" className="userCardButton arrow">Registrer vekt</Link>
        </Modal>
        {'vektbok' in urls[page] && <Link to={urls[page]['vektbok']} className="userCardButton arrow">Vektutvikling</Link>}
        {'dagbok' in urls[page] && <Link to={urls[page]['dagbok']} className="userCardButton arrow">Dagbok registreringer siste 7 dager</Link>}
      </Fragment>
    </div>
}

export default UserActionButtons;
