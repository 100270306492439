import React from 'react';
import moment from 'moment'



export function weightBookCourseUri(page, date, hour, userGroup) {
  date = date || moment().format('YYYY-MM-DD');
  const participants = userGroup.map(function (obj) {
  	return obj.userId;
  });

  return `/${page}/${date}/${hour}/${participants}/`
}
