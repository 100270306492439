import React, { Fragment, useState } from 'react';
import RoedeMeeting from  '../../atom/RoedeMeeting/index.js';
import Loading from '../../atom/Loading';

import moment from 'moment'



const MeetingWeek = ({
    loading, 
    meetings, 
    date,
    assistantCourseSchedule,
    ...props
}) => {
  const scheduleData = assistantCourseSchedule?.data && JSON.parse(assistantCourseSchedule.data) || {}
  const blacklisted = (day) => {
    const currentDate = date.add(day-1).format("YYYY-MM-DD")
    const schedule = scheduleData?.schedule
    
    if (!schedule){
      return false
    }
    const foundScheduleDay = schedule.find(scheduleDay => scheduleDay.date ===currentDate )
    
    return foundScheduleDay?.blacklisted

  }
  
  const start_times = Array.from(new Set(meetings.map((meeting) => moment(meeting.startTime).format("HH")))).sort()
  

  
  const days = [1,2,3,4,5,6,7]
  return(
    <div className="courseCalendarBody">
      <div className="cc-grid header">
        <div className="cc-grid-item grid-align-center">kl.</div>
        <div className="cc-grid-item" data-blacklisted={blacklisted(1)}>Mandag</div>
        <div className="cc-grid-item" data-blacklisted={blacklisted(1)}>Tirsdag</div>
        <div className="cc-grid-item" data-blacklisted={blacklisted(1)}>Onsdag</div>
        <div className="cc-grid-item" data-blacklisted={blacklisted(1)}>Torsdag</div>
        <div className="cc-grid-item" data-blacklisted={blacklisted(1)}>Fredag</div>
        <div className="cc-grid-item" data-blacklisted={blacklisted(1)}>Lørdag</div>
        <div className="cc-grid-item" data-blacklisted={blacklisted(1)}>Søndag</div>
      </div>
      {loading ? <Loading/> : start_times.map((hour) => (
          <div className="cc-grid body" key={hour}>
            <div className="cc-grid-item grid-align-center">
              <div className="cc-center">{hour}</div>
            </div>
            {days.map((day) => (<div className="cc-grid-item" key={day}>
              {meetings.filter((meeting) => (moment(meeting.startTime).format("HH")===hour && moment(meeting.startTime).isoWeekday() === day)).map((meeting) => (<RoedeMeeting key={meeting.courseMeetingId} meeting={meeting} />)) }
            </div>))}  

             
          </div>
        ))
      }
    </div>
  )
}

export default MeetingWeek;
