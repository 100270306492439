import React from "react";
import moment from "moment";
import Energy from "./Energy";
import Nutrition from "./Nutrition";
import { dietScore } from "../../../config";
import ReactMarkdown from 'react-markdown'
import  markdownStyles from '../../../assets/styles/Markdown.module.scss'

const weekdays = [
  "Søndag",
  "Mandag",
  "Tirsdag",
  "Onsdag",
  "Torsdag",
  "Fredag",
  "Lørdag",
];

function nFormat(number) {
  return Number(number || 0).toLocaleString("nb-NO", {
    maximumFractionDigits: 1,
  });
}

function pFormat(number) {
  return Number((number || 0) * 100).toLocaleString("nb-NO", {
    maximumFractionDigits: 1,
  });
}

function Diary({weekdates, data}) {

  const {
    days,
    week,
    measurements:{
      weight,
      waist
    }={},
    user
  } = data;

  if (!days){
    return null
  }

  return (
    <div className="wcwr-widget-wrapper">
      <div className="wcwr-widget">
        <section>
          <h4>Ukens kosthold</h4>
          <table>
            <thead>
              <tr>
                <th>Dag</th>
                <th>Kcal</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {days.map((day, index) => (
                <tr key={`day-${index}`}>
                  <td>
                    {weekdays[moment(weekdates[index]).format('e')]}&nbsp;
                    {moment(weekdates[index]).format('DD.MM.YYYY')}
                  </td>
                  <td>{nFormat(day?.diet?.totalEatenKcal)}</td>
                  <td>{nFormat(day?.diet?.score)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>Snitt</td>
                <td>{nFormat(week?.diet?.totalEatenKcal)}</td>
                <td>{nFormat(week?.diet?.score)}</td>
              </tr>
            </tfoot>
          </table>
        </section>
        <section>
          <h4>Energifordeling måltider</h4>
          <table>
            <thead>
              <tr>
                <th>Måltid</th>
                <th>Kcal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Frokost:</td>
                <td>
                  <b>{nFormat(week?.diet?.breakfastKcal)}</b>
                </td>
              </tr>
              <tr>
                <td>Lunsj:</td>
                <td>
                  <b>{nFormat(week?.diet?.lunchKcal)}</b>
                </td>
              </tr>
              <tr>
                <td>Mellommåltider:</td>
                <td>
                  <b>{nFormat(week?.diet?.snackMealKcal)}</b>
                </td>
              </tr>
              <tr>
                <td>Middag:</td>
                <td>
                  <b>{nFormat(week?.diet?.dinnerKcal)}</b>
                </td>
              </tr>
              <tr>
                <td>Kveldsmat:</td>
                <td>
                  <b>{nFormat(week?.diet?.supperKcal)}</b>
                </td>
              </tr>
              <tr>
                <td>Alkohol:</td>
                <td>{nFormat(week?.diet.totalEatenAlcohol)}</td>
              </tr>
              <tr>
                <td>Utfordring:</td>
                <td>{nFormat(week?.diet.voucherMealKcal)}</td>
              </tr>
              <tr>
                <td>
                  <b>Total kalorier:</b>
                </td>
                <td>
                  <b>{nFormat(week?.diet?.totalEatenKcal)}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
      <div className="wcwr-widget">
        <Energy week={week} dietScore={dietScore} user={user} />
        <Nutrition week={week} dietScore={dietScore} />
      </div>
      <div className="wcwr-widget">
        <section>
          <h4>Aktivitet</h4>
          <table>
            <thead>
              <tr>
                <th>Dag</th>
                <th>TP</th>
                <th>SP</th>
                <th>Skritt</th>
              </tr>
            </thead>
            <tbody>
              {days.map((day, index) => (
                <tr key={`day-${index}`}>
                  <td>
                    {weekdays[moment(weekdates[index]).format('e')]}&nbsp;
                    {moment(weekdates[index]).format('DD.MM.YYYY')}
                  </td>
                  <td>{nFormat(day?.exercise?.exercisePoints)}</td>
                  <td>{nFormat(day?.exercise?.strengthPoints)}</td>
                  <td>{nFormat(day?.exercise?.numSteps)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        <section>
          <h4>Trening</h4>
          <ul>
            <li>
              Energi: <strong>{nFormat(week?.exercise?.calories)} kcal</strong>
            </li>
            <li>
              Treningspoeng:{" "}
              <strong>{nFormat(week?.exercise?.exercisePoints)}</strong>
            </li>
            <li>
              Styrkepoeng:{" "}
              <strong>{nFormat(week?.exercise?.strengthPoints)}</strong>
            </li>
            <li>
              Skritt: <strong>{nFormat(week?.exercise?.numSteps)}</strong>
            </li>
            <li>
              Minutter: <strong>{nFormat(week?.exercise?.minutes)}</strong>
            </li>
          </ul>
        </section>
      </div>
      <div className="wcwr-widget">
        <section>
          <h4>Vekt</h4>
          <ul>
            <li>
              Vekt nå: <strong>{nFormat(weight?.current?.value)} kg</strong>
            </li>
            <li>
              Endring siden forrige veiing:{" "}
              <strong>{nFormat(weight?.diff_last_week?.value)} kg</strong>
            </li>
            <li>
              Endring i vekt siden kursstart:{" "}
              <strong>{nFormat(weight?.diff_course_start?.value)} kg</strong>
            </li>
            <li>
              Endring i vekt siden startdato:{" "}
              <strong>{nFormat(weight?.diff_start_date?.value)} kg</strong>
            </li>
          </ul>
        </section>
        <section>
          <h4>Midjemål</h4>
          <ul>
            <li>
              Midjemål nå: <strong>{nFormat(waist?.current?.value)} cm</strong>
            </li>
            <li>
              Endring i midjemål siden forrige veiing:{" "}
              <strong>{nFormat(waist?.diff_last_week?.value)} cm</strong>
            </li>
            <li>
              Endring i midjemål siden kursstart:{" "}
              <strong>{nFormat(waist?.diff_course_start?.value)} cm</strong>
            </li>
            <li>
              Endring i midjemål siden startdato:{" "}
              <strong>{nFormat(waist?.diff_start_date?.value)} cm</strong>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}

function Feedback({ feedback }) {
  return (
    <div className="wcwr-widget-wrapper">
      <div className="wcwr-widget">
      {feedback?.text && (
        <div>
          <ReactMarkdown className={markdownStyles.markdown}>
            {feedback.text}
          </ReactMarkdown>
        </div>
      )}
      </div>
    </div>
  );
}

function Focus({ feedback}) {
  return (
    <div className="wcwr-widget-wrapper">
      <div className="wcwr-widget">
        <ol>
          <li>{feedback?.focus_1}</li>
          <li>{feedback?.focus_2}</li>
          <li>{feedback?.focus_3}</li>
        </ol>
      </div>
    </div>
  );
}

export default function ReportWidget({ date, widget, data, feedback }) {



  const weekdates = Array(7)
    .fill()
    .map(
      (_, index) => moment(date).add((index + 1) * -1, 'day').format("YYYY-MM-DD")
    )
    .reverse();

  switch (widget) {
    case "diary":
      return <Diary weekdates={weekdates} data = {data}  />;
    case "focus":
      return <Focus feedback={feedback}  />;
    // case "feedback":
    //   return <Feedback feedback={feedback} />;
    default:
      return null;
  }
}
