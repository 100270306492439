import Loading from '../Loading'

const CalendarGrid = ({
  loading,
  data,
  Container,
  ...props
}) => {
    return <div className="courseCalendarBody">
      <div className="cc-grid header">
        <div className="cc-grid-item grid-align-center">kl.</div>
        <div className="cc-grid-item">Mandag</div>
        <div className="cc-grid-item">Tirsdag</div>
        <div className="cc-grid-item">Onsdag</div>
        <div className="cc-grid-item">Torsdag</div>
        <div className="cc-grid-item">Fredag</div>
        <div className="cc-grid-item">Lørdag</div>
        <div className="cc-grid-item">Søndag</div>
      </div>
      {loading ? <Loading/> : Object.keys(data).map((entry, index) => (
        <div className="cc-grid body" key={index}>
          <div className="cc-grid-item grid-align-center">
            <div className="cc-center">{entry}</div>
          </div>
          <div className="cc-grid-item">
            {
              data[entry][1] && data[entry][1].length > 0 && data[entry][1].map((obj, i) => (
                <Container key={i} obj={obj} {...props}/>
              ))
            }
          </div>
          <div className="cc-grid-item">
            {
              data[entry][2] && data[entry][2].length > 0 && data[entry][2].map((obj, i) => (
                <Container key={i} obj={obj} {...props}/>
              ))
            }
          </div>
          <div className="cc-grid-item">
            {
              data[entry][3] && data[entry][3].length > 0 && data[entry][3].map((obj, i) => (
                <Container key={i} obj={obj} {...props}/>
              ))
            }
          </div>
          <div className="cc-grid-item">
            {
              data[entry][4] && data[entry][4].length > 0 && data[entry][4].map((obj, i) => (
                <Container key={i} obj={obj} {...props}/>
              ))
            }
          </div>
          <div className="cc-grid-item">
            {
              data[entry][5] && data[entry][5].length > 0 && data[entry][5].map((obj, i) => (
                <Container key={i} obj={obj} {...props}/>
              ))
            }
          </div>
          <div className="cc-grid-item">
            {
              data[entry][6] && data[entry][6].length > 0 && data[entry][6].map((obj, i) => (
                <Container key={i} obj={obj} {...props}/>
              ))
            }
          </div>
          <div className="cc-grid-item">
            {
              data[entry][7] && data[entry][7].length > 0 && data[entry][7].map((obj, i) => (
                <Container key={i} obj={obj} {...props} />
              ))
            }
          </div>
        </div>

      ))}
    </div>
}

export default CalendarGrid;
