import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'
import DiaryComment from '../DiaryComment';


import {
  showKcalLimit,
  formatNumber,
  getKCalClass,
} from '../../utils/GetCacheQuery';

const DiaryDietAndExercise = ({ user, data, page }) => (

  <section className="diarySection">
    <h2 className="diarySectionTitle">Kosthold og trening</h2>
    <div className="diaryTables">
      <table className="diaryTableDays">
        <tbody>
          <tr>
            <th>Dag</th>
          </tr>
          {data.days.map(day => (
          <tr key={day.date}>
            <td>
              <Link to={`/${page}/${user.id}/${day.date}/`}>
                {day.dateFormatted}
              </Link>
            </td>
          </tr>
          ))}
          <tr>
            <td>Sum/snitt</td>
          </tr>
        </tbody>
      </table>
      <div className="diaryTableWrapper">
        <table className="diaryTableValues">
          <tbody>
            <tr>
              <th>
                kcal {showKcalLimit(user)}
              </th>
              <th title="Kostholdscore">KS</th>
              <th title="Treningspoeng">TP</th>
              <th>Skritt</th>
              <th>Kommentar</th>
            </tr>
            {data.days.map(day => (
            <tr key={day.date}>
              <td className={user.kcalMenu ? getKCalClass(user, day.kcal) : ""}>{formatNumber(day.kcal)}</td>
              <td>{formatNumber(day.dietScore)}</td>
              <td>{formatNumber(day.trainingPoints)} / {formatNumber(day.strengthPoints)}</td>
              <td>{formatNumber(day.numSteps)}</td>
              <td><DiaryComment text={day.comments} /></td>
            </tr>
            ))}
            <tr>
              <td>{formatNumber(data.totals.kcal)}</td>
              <td>{formatNumber(data.totals.dietScore)}</td>
              <td>{formatNumber(data.totals.trainingPoints)} / {formatNumber(data.totals.strengthPoints)}</td>
              <td>{formatNumber(data.totals.numSteps)}</td>
              <td/>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
)

export default DiaryDietAndExercise;
