import React, { Fragment, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";

function FeedbackAttachment({
  files,
  attachment,
  setFeedbackAttachments,
  changeTag,
}) {
  const selectFile = (id) => {
    if (files.includes(id)) {
      setFeedbackAttachments(files.filter((file) => file !== id));
    } else {
      setFeedbackAttachments([...files, ...[id]]);
    }
  };

  return (
    <Row className="feedback_attachment">
      <Col xs="1" className="col">
        <Form.Check
          type="checkbox"
          value={attachment.fileManagerId}
          checked={files.includes(attachment.fileManagerId)}
          onChange={() => selectFile(attachment.fileManagerId)}
        />
      </Col>
      <Col className="align_left col">
        {attachment.filename}
        <ul className="file-tags">
          {attachment.tags.map((tag, index) => (
            <li key={index}>
              <button onClick={() => changeTag(tag)}>{tag}</button>
            </li>
          ))}
        </ul>
      </Col>
      <Col xs="2" className="col">
        <a href={attachment.slugUrl} target="_blank">
          Download
        </a>
      </Col>
    </Row>
  );
}

export default function FeedbackAttachments({
  attachments,
  feedbackAttachments,
  setFeedbackAttachments,
}) {
  const PER_PAGE = 10;
  const [page, setPage] = useState(0);
  const [selectedTag, setSelectedTag] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const attachedFileNames = feedbackAttachments
    ? feedbackAttachments.map((obj) =>
        attachments.find((attachment) => attachment.node.fileManagerId === obj)
      )
    : [];

  const tags = attachments.reduce((acc, curr) => {
    curr.node.tags.forEach((tag) => !acc.includes(tag) && acc.push(tag));
    return acc;
  }, []);

  const changeTag = (tag) => {
    setSelectedTag(tag);
    setPage(0);
  };

  const start = PER_PAGE * page;
  const end = PER_PAGE * (page + 1);
  const filteredAttachments = attachments.filter((attachment) => {
    if (selectedTag === "") {
      return true;
    }
    return attachment.node.tags.includes(selectedTag);
  });
  const pagedAttachments = filteredAttachments.slice(start, end);

  const changePage = (event, page) => {
    event.preventDefault();
    setPage(page);
  };

  const pages = Math.ceil(filteredAttachments.length / PER_PAGE);

  return (
    <React.Fragment>
      {attachedFileNames && (
        <div className="attachefiles">
          <h4>Vedlagte filer:</h4>
          <ul>
            {attachedFileNames.map((attacheFile, index) => (
              <li key={index}>{attacheFile.node.filename}</li>
            ))}
          </ul>
        </div>
      )}
      <Button variant="outline-success" onClick={handleShow}>
        Legg ved filer
      </Button>
      <span className="feedback_attachment_container">
        <Modal
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Filer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="file-tags-container">
              <strong>Tags:</strong>
              <ul className="file-tags">
                <li>
                  <button
                    onClick={() => changeTag("")}
                    data-selected={"" === selectedTag}
                  >
                    Alle
                  </button>
                </li>
                {tags.map((tag, index) => (
                  <li key={index}>
                    <button
                      onClick={() => changeTag(tag)}
                      data-selected={tag === selectedTag}
                    >
                      {tag}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <Row className="feedback_attachment header">
              <Col xs="1" className="col">
                Select
              </Col>
              <Col className="align_left col">Name</Col>
              <Col xs="2" className="col">
                Download
              </Col>
            </Row>
            {pagedAttachments.map((obj, index) => (
              <FeedbackAttachment
                key={index}
                files={feedbackAttachments}
                attachment={obj.node}
                setFeedbackAttachments={setFeedbackAttachments}
                changeTag={changeTag}
              />
            ))}
            {filteredAttachments.length > PER_PAGE && (
              <React.Fragment>
                <br />
                <Pagination>
                  <Pagination.Prev
                    onClick={(e) => changePage(e, page - 1)}
                    disabled={page <= 0}
                  />
                  {Array(pages)
                    .fill()
                    .map((_, i) => (
                      <Pagination.Item
                        key={i}
                        onClick={(e) => changePage(e, i)}
                        active={page === i}
                      >
                        {i + 1}
                      </Pagination.Item>
                    ))}
                  <Pagination.Next
                    onClick={(e) => changePage(e, page + 1)}
                    disabled={page >= pages - 1}
                  />
                </Pagination>
              </React.Fragment>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Lukk
            </Button>
          </Modal.Footer>
        </Modal>
      </span>
    </React.Fragment>
  );
}
