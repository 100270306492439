import { gql } from "apollo-boost";
import {getCacheQuery} from '../utils/GetCacheQuery'

const GET_PARTICIPANT2 = gql`
query Assistant($userId: Int, $currentDate: DateTime) {
  assistant {
    assistantGetUser: assistantGetUser2(userId: $userId) {
      name
      userId
      fullImageUrl
      kcalMenu
      userLevelName
      restingMetabolism
      calculatedEnergyBeforeExercise
      calculatedEnergyAfterExercise
      recommendedKcalIn
      gender
      height
      age
      activityLevel
      exerciseHabits
      weight
      dietType
      currentSummary: summaryMeasurementsCached(dateTo: $currentDate)
      ${getCacheQuery()}
    }
  }
}
`

export default GET_PARTICIPANT2;
