import React, { Fragment, useState } from 'react';

import { formatNumber } from '../../utils/GetCacheQuery';

const DiaryMeasurements = ({ data: { weight, measurements } }) => (
  <section className="diarySection">
    <h2 className="diarySectionTitle">Vekt og mål</h2>
    <div className="diaryMeasurements">
      <ul className="diaryMeasurementsOverview">
        <li>Vekt nå{weight.currentDate}: <span><strong>{formatNumber(weight.current, 1)}</strong> kg</span></li>
        <li>Endring siden forrige veiing{weight.diffLastWeekDate}: <span><strong>{formatNumber(weight.diffLastWeek, 1)}</strong> kg</span></li>
        <li>Endring i vekt siden kursstart{weight.diffCourseDateDate}: <span><strong>{formatNumber(weight.diffCourseDate, 1)}</strong> kg</span></li>
        <li>Endring i vekt siden startdato{weight.diffStartDateDate}: <span><strong>{formatNumber(weight.diffStartDate, 1)}</strong> kg</span></li>
      </ul>
      <ul className="diaryMeasurementsOverview">
        <li>Midjemål nå{measurements.currentDate ? measurements.currentDate: ''}: <span><strong>{formatNumber(measurements.current, 1)}</strong> cm</span></li>
        <li>Endring i midjemål siden forrige veiing{measurements.diffLastWeekDate ? measurements.diffLastWeekDate : ''}: <span><strong>{formatNumber(measurements.diffLastWeek, 1)}</strong> cm</span></li>
        <li>Endring i midjemål siden kursstart{measurements.startCourseTotalGoalDate ? measurements.startCourseTotalGoalDate : ''}: <span><strong>{formatNumber(measurements.diffCourseDate, 1)}</strong> cm</span></li>
        <li>Endring i midjemål siden startdato{measurements.startDayTotalGoalDate ? measurements.startDayTotalGoalDate : ''}: <span><strong>{formatNumber(measurements.diffStartDate, 1)}</strong> cm</span></li>
      </ul>
    </div>
  </section>
)

export default DiaryMeasurements;
