import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

var _ = require('underscore');
import moment from 'moment'

const UserListNy = ({
  participantsList,
  totalCount,
  hasMore,
  page,
  selectAll,
  selectUser,
  userList=[],
  ...props
}) => {



    const [sortType, setSortType] = useState('sortName');
    const [sortValue, setSortValue] = useState('asc');



    const handleSort = (type, value) => {
      if (type === sortType) {
        setSortType(type);
        setSortValue(value);
      } else {
        setSortType(type);
        setSortValue('asc');
      }
    }

    const sortParticipants = (arr) => {
      var data = [];

      if(sortType === 'sortName') {
        data = _.sortBy(arr,function (i) {
          return i.name.toLowerCase();
        })
      }

      if(sortType === 'sortCours') {
        data = _.sortBy(arr, function (i) {
          return i.courseTitle.toLowerCase();
        })
      }

      if(sortType === 'sortDate') {
        data = _.sortBy(arr, 'activatedDate');
      }

      return sortValue === 'asc' ? data : data.reverse();
    }

    return <Fragment>
      <div className="totalCount">Total results: <strong>{totalCount}</strong></div>
      <div className="participantsGrid extended">
        <div className="headItem firstCol">
          <input type="checkbox" name="course_type[]" className="userCheckbox regularCheckbox" onClick={(e) => selectAll(e, participantsList)}/>
        </div>
        <div
          className={`headItem headArrow ${sortType === 'sortName' && sortValue} centerItem`}
          onClick={() => handleSort('sortName', sortValue === 'asc' ? 'desc' : 'asc')}
        >Navn</div>
        <div
          className={`headItem courseCol headArrow ${sortType === 'sortCours' && sortValue} centerItem`}
          onClick={() => handleSort('sortCours', sortValue === 'asc' ? 'desc' : 'asc')}
        >Kurs</div>
        <div
          className={`headItem dateCol headArrow ${sortType === 'sortDate' && sortValue} centerItem`}
          onClick={() => handleSort('sortDate', sortValue === 'asc' ? 'desc' : 'asc')}
        >Dato</div>
        <div
          className={"headItem headArrow centerItem"}
        >Telefon</div>
        <div
          className={"headItem headArrow centerItem"}
        >Contract to</div>
        <div
          className={"headItem headArrow centerItem"}
        >Status</div>        
        <div
          className={"headItem headArrow centerItem"}
        >Email</div>
      </div>
      <Fragment>
        {
          sortParticipants(participantsList).map((obj, index)=>

              <div key={index} className="participantsGrid extended">
                <div className="bodyItem firstCol textItem">
                  {
                    <input
                      type="checkbox"
                      name="course_type[]"
                      className="userCheckbox regularCheckbox"
                      onClick={(e)=>selectUser(e, obj)}
                      data-id={obj.userId}
                      data-name={obj.name}
                      data-email={obj.email}
                      data-email-allowed={obj.emailAllowed}
                      data-sms-allowed={obj.smsAllowed}
                      data-phone={obj.cellPhone}
                      data-is-active={obj.status == 'active' ? 'active' : 'inactive'}
                      checked={userList.filter((x) => (x.userId == obj.userId)).length > 0}
                      onChange={() => function() {}}
                    />
                  }
                </div>

                <Link className="bodyItem nameCol textItem leftItem" to={`/${page}/${obj.userId}${obj.status !== 'active' ? '?status=inactive' : ''}`}>
                  {obj.name}
                </Link>
                <Link className="bodyItem courseCol textItem leftItem" to={`/${page}/${obj.userId}${obj.status !== 'active' ? '?status=inactive' : ''}`}>
                  {obj.courseTitle}
                </Link>
                <Link className="bodyItem dateCol textItem" to={`/${page}/${obj.userId}${obj.status !== 'active' ? '?status=inactive' : ''}`}>
                  {moment(obj.activatedDate).format("DD.MM.YY")} / { obj.deactivatedDate ? moment(obj.deactivatedDate).format("DD.MM.YY") : '-' }
                </Link>
                <Link className="bodyItem courseCol textItem leftItem" to={`/${page}/${obj.userId}${obj.status !== 'active' ? '?status=inactive' : ''}`}>
                  {obj.cellPhone}
                </Link>
                <Link className="bodyItem courseCol textItem leftItem" to={`/${page}/${obj.userId}${obj.status !== 'active' ? '?status=inactive' : ''}`}>
                  {obj?.contractTo && moment(obj?.contractTo).format('YYYY-MM-DD')}
                </Link>
                <Link
                  className={`bodyItem courseCol textItem discountCol ${obj.renewal_status} leftItem`}
                  to={`/${page}/${obj.userId}${obj.status !== 'active' ? '?status=inactive' : ''}`}
                >
                  {obj.status_text}
                </Link>
                <Link className="bodyItem courseCol textItem emailItem centerItem" to={`/${page}/${obj.userId}${obj.status !== 'active' ? '?status=inactive' : ''}`}>
                  {obj.emailAllowed ? <i className="icon-check-circle"></i> : <i className="icon-cancel-circle"></i>}
                </Link>


              </div>

          )
        }
      </Fragment>
    </Fragment>
}

export default UserListNy;
