import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'

import queryString from 'query-string';

import Loading from '../atom/Loading';
import NotFound from '../atom/NotFound';
import UserDetails from '../ui/UserDetails';


import USER_SCHEMA from '../query/user_schema.js';
const PAGE = 'kursedeltakere';

const Breadcrumbs = ({ name, userId }) => (
  <nav className="breadcrumbs">
    <ul>
      <li><Link to="/">Hovedside</Link></li>
			<li><Link to="/kursedeltakere/">Kursdeltakere</Link></li>
      <li><Link to={`/kursedeltakere/${userId}`}>{name}</Link></li>
    </ul>
  </nav>
)

const UserCard = ({
	match,
	...props
}) => {

  const parsed = queryString.parse(props.location.search);

	const {
		loading,
		refetch,
		data: {
			request: {
        grassistant: {
          assistantGetUser: user = {}
        } = {}
      } = {}
		} = {}
	} = useQuery(USER_SCHEMA, {
		variables: {
			userId: match.params.id,
      status: parsed.status
		}
	});


	if (loading) {
		return <div className="LoadWrapper">
			<Loading />
		</div>;
	}

  if (!user) {
    return <div className="pageContainer participantsContainer">
      <div className="participantsBody userCard">
        <NotFound
          children="Deltaker ikke funnet"
        />
      </div>
    </div>
  }


  return <div className="pageContainer participantsContainer">
		<div className="pageHeadWrapper participantsHead">
			<Breadcrumbs name={user.name} userId={user.userId}/>
		</div>
		<div className="participantsBody userCard">
      <UserDetails
        user={user}
        callback={refetch}
        match={match}
        page={PAGE}
      />
		</div>
	</div>
}

export default UserCard;
