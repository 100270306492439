export function nFormat(number) {
  return Number(number || 0).toLocaleString('nb-NO', { maximumFractionDigits: 1 })
}

export function pFormat(number) {
  return Number((number || 0) * 100).toLocaleString('nb-NO', { maximumFractionDigits: 1 })
}

export default function format(number, decimals = null) {
  if (number === undefined || isNaN(number)) {
    return 'n/a'
  }

  if (number === 0) {
    return number
  }

  if (decimals === null) {
    return Intl.NumberFormat('nb-NO').format(number)
  }

  return Intl.NumberFormat('nb-NO', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number)
}