import { gql } from "apollo-boost";

const ASSISTANT_FILE_MANAGER = gql`
query Assistant {
  request {
      grassistant {
          assistantFileManager {
            edges {
              node {
                fileManagerId
                filename
                slug
                googleStorageFile
                downloadUrl
                slugUrl
              }
            }
          }
      }
  }
}
`

export default ASSISTANT_FILE_MANAGER;
