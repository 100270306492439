import React, { Fragment, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { ToastContainer, toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import REPORT_SUBMIT from '../../mutation/report_submit'
import 'react-toastify/dist/ReactToastify.css'

export default function ReportSubmit({ id, closePreview }) {
  const variables = { id }
  const [disabled, setDisabled] = useState(false)
  const [submit, { loading }] = useMutation(REPORT_SUBMIT, { variables })

  const submitReport = () => {
    const result = submit()
    result
      .then(({ data }) => {
        setDisabled(true)
        const { status, message } = data.assistant.assistantReportSubmit
        if (status !== 'error') {
          toast(`${status}: ${message}`, {
            autoClose: 15000,
            onClose: closePreview,
          })
        } else {
          alert(`${status}: ${message}`)
          setDisabled(false)
        }
      })
      .catch(function (err) {
        alert(err.message)
        setDisabled(false)
      })
  }

  return (
    <Fragment>
      <Button
        id="save-button"
        data-loading={loading || disabled}
        onClick={submitReport}
        type="submit"
      >
        Sende inn
      </Button>
      <ToastContainer />
    </Fragment>
  )
}
