import React, { Fragment, useState } from 'react';
import Modal from '../../ui/Modal';
import { Link } from 'react-router-dom'


const WeightBookParticipant = ({
  userId,
  userName,
  book_code,
  this_weeks_weight,
  courseList,
  callback,
  match,
  page,
  absence,
  comment,
  ...props
}) => {
    const is_registered = this_weeks_weight || (this_weeks_weight !== null && this_weeks_weight !== '') || absence !== null || (comment !== null && comment !== '')

    return (
      <Modal
        type={"weighing"}
        from={"all"}
        modalHeader={`Vektbok - ${userName}`}
        callback={callback}
        user={{
          id:userId,
          name:userName
        }}
        page={page}
        match={match}
      >
        <Link className={"wbp-tr  " + (is_registered ? 'checked' : '')} to="#">
          <div className="wbp-td">{userName}</div>
          <div className="wbp-td removePadding">
          {
            courseList ? <CourseList
              bookCode={book_code}
              courseList={courseList}
            /> : <CourseName courseName={book_code} />
          }
          </div>
          <div className="wbp-td arrow">
            {is_registered && <i className="icon-check" />}
          </div>
        </Link>
      </Modal>
    )
}

const CourseName = ({
  courseName,
  ...props
}) => {

  return (
    <div
      className="wrapper"
    >{ courseName }</div>
  )
}

const CourseList = ({
  bookCode,
  courseList,
  ...props
}) => {

  const [show, showCourse] = useState(false)

  const showCourses = (e) => {
    e.preventDefault();
    e.stopPropagation();
    showCourse(true)
  }

  const hideCourses = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    showCourse(false)
  }

  return (!show ? <ShowCourses
    bookCode={bookCode}
    showCourses={showCourses}
  /> : <HideCourses
    courseList={courseList}
    hideCourses={hideCourses}
  ></HideCourses>)
}

const ShowCourses = ({
  bookCode,
  showCourses,
  ...props
}) => {
  return (
    <div
      onClick={(e) => showCourses(e)}
      className="wrapper hasMore closed"
    >{ bookCode }</div>
  )
}

const HideCourses = ({
  courseList,
  hideCourses
}) => {
  return (
    <Fragment>{<div
       onClick={(e) => hideCourses(e)}
       className="wrapper hasMore open"
      >
       {
         courseList.map((cours, key) => (
           <div key={key} className="courseName">{cours}</div>
         ))
       }
      </div>}
    </Fragment>);
}



export default WeightBookParticipant;
