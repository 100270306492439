import React, { Fragment, useState } from 'react';

const CalculatedRecommendedKcal = ({recommendedKcalIn, ...props}) => {
    return <Fragment>
      {recommendedKcalIn && (
        <li><span>Anbefalt energiinntak</span> {recommendedKcalIn} kcal</li>
      )}
    </Fragment>
}

export default CalculatedRecommendedKcal;
