import React, { Fragment, useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const FileItem = ({
    obj,
    toast,
    ...props
}) => {


    return <div className="file-item-container">
        
        <div className="grid-item td_item">{obj.filename}</div>
        <div className="grid-item td_item item-center"><a className="buttonText" href={obj.downloadUrl} download>Download</a></div>
        <div className="grid-item td_item item-center">
            <CopyToClipboard text={obj.slugUrl} onCopy={() => toast('Kopiert')}>
                <div className="buttonText">Kopier</div>
    		</CopyToClipboard>
        </div>
    </div>
}

export default FileItem;
