import { gql } from "apollo-boost";

const USER_SCHEMA = gql`
query Assistant($userId: Int, $status: String) {
  request {
    grassistant {
      assistantGetUser(userId: $userId, status: $status) {
        status
        userId
        emailAllowed
        smsAllowed
        userId
        email
        height
        weight
        gender
        birthDate
        comment
        motivation

        name
        fullImageUrl
        cellPhone
        motivationImage

        healthProblems
        allergiesList
        age
        restingMetabolism
        trainingMetabolism
        recommendedKcalIn
        kcalMenu
        activityLevel
        exerciseHabits
      }
    }
  }
}
`

export default USER_SCHEMA;
