import React, { Fragment, useState } from 'react'
import { useMutation } from '@apollo/client';
import loadingImg from '../../SysIons/loading.svg';
import NoResults from '../NoResults';

import CHANGE_PARTICIPANT_NUMBER from '../../../mutation/change_participant_number.js';

const ChangeParticipantNo = ({
  close,
  ...props
}) => {

    const {
      courseId,
      callback,
    } = props

    const [saveFunction, { data, loading }] = useMutation(CHANGE_PARTICIPANT_NUMBER, {
      onCompleted() {
        close();
      }
    });
    const [maxNumParticipantsValue, setNumParticipants] = useState(props.maxNumParticipants);

    const handleChange = (e) => {
      const {
        target: {
          value
        }
      } = e;

      setNumParticipants(value);
    }

    const submit = () => {
      try {
        const maxNumParticipants = +maxNumParticipantsValue;
        const variables = { courseId, maxNumParticipants };

        saveFunction({ variables });
      } catch(err) {
        alert(err)
      }
    }

    return (<div className="ModalBody">
      <div className="ModalBody">
        <div className="Fieldset">
          <label>Maximum Number of Participants</label>
          <input type="number" value={maxNumParticipantsValue} onChange={(e) => handleChange(e)} />
        </div>
      </div>

      <footer>
        <div className="buttonContainer pullRight">
          <div className="buttonWrapper">
            {
              loading ? <img src={loadingImg} alt="Processing" className="processing_image"/> :
                <button type="submit" className="buttonItem save" onClick={() => submit()}>Lagre</button>
            }
          </div>
        </div>
      </footer>
    </div>)
}

export default ChangeParticipantNo;
