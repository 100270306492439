import { gql } from "apollo-boost";

const GET_WEIGHTBOOK_PARTICIPANT2 = gql`
query Assistant($userId: Int, $status: String, $dateFrom: String, $weeklyCacheDate: Date, $dateTo: DateTime,) {
  request {
    grassistant {
      assistantGetUser: assistantGetUser2(userId: $userId, status: $status) {
        name
        userId
        fullImageUrl
        userLevelName
        kcalMenu
        lastRegistrationDate
        startWeight
        courseStartDate
        summaryMeasurementsCached(dateTo: $dateTo)
        courseLocation
        weeklyDiaryCache(date: $weeklyCacheDate) {
          data
        }
        measurementList(dateSort: "desc", dateFrom: $dateFrom) {
          edges {
            node {
              registrationId
              dateTime
              data
            }
          }
        }
        weightGoal
        intermediateWeightGoal
        startDate
      }
    }
  }
}
`

export default GET_WEIGHTBOOK_PARTICIPANT2;
