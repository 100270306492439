import React, { Fragment, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import ASSISTANT_WEBCOURSE_WEEK_REPORT from '../query/assistant_webcourse_week_report'
import ASSISTANT_WEBCOURSE_WEEK_REPORT_FEEDBACK from '../mutation/assistant_webcourse_week_report_feedback'
import reportNavPrev from '../ui/SysIons/report-nav-prev.svg'
import reportNavNext from '../ui/SysIons/report-nav-next.svg'


import Loading from '../atom/Loading'
import FeedbackAttachments from '../atom/FeedbackAttachment';
import UserInfo from '../ui/UserInfo'
import ReportWidget from '../ui/ReportWidget'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from "react-bootstrap/Modal";
import Collapse from "react-bootstrap/Collapse";
import FormatHelpModal from '../atom/FormatHelpModal'

import moment from 'moment'
import NextReportWeek from '../ui/NextReportWeek'
import { instructorStarted, showPreview } from '../utils/report'
import { KnownTypeNamesRule } from 'graphql'

import { useHistory } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SkipModal({ isVisible, close, skipReport }) {
  return (
    <Modal
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => close()}
    >
      <Modal.Header closeButton>
        <Modal.Title>Hopp over</Modal.Title>

      </Modal.Header>
      <Modal.Body>
      Bekreft at du vil hoppe over



      </Modal.Body>
      <Modal.Footer>

    <Button variant="danger" onClick={() => {skipReport(); close(false)}}>Hopp over</Button>
    <Button variant="secondary" onClick={() => close(false)}>Lukk</Button>
  </Modal.Footer>
    </Modal>
  );
}


const WebCourseWeekReport = (props) => {
  const history = useHistory();
  const id = +props.match.params.id

  const [sendFeedback, result] = useMutation(ASSISTANT_WEBCOURSE_WEEK_REPORT_FEEDBACK)



  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackFocusOne, setFeedbackFocusOne] = useState('');
  const [feedbackFocusTwo, setFeedbackFocusTwo] = useState('');
  const [feedbackFocusThree, setFeedbackFocusThree] = useState('');


  const weekreport = useQuery(ASSISTANT_WEBCOURSE_WEEK_REPORT, {
    variables: { id },
    onCompleted() {
      const feedback = report?.nextReport?.feedback && JSON.parse(report?.nextReport?.feedback) || {'text': '', 'focus_1': '', 'focus_2': '', 'focus_3': '', files:[]}
      const arr = feedback?.files || []
      const tmp = arr.map(function(obj) { return obj.id })
      setFeedbackText(feedback.text)
      setFeedbackFocusOne(feedback.focus_1)
      setFeedbackFocusTwo(feedback.focus_2)
      setFeedbackFocusThree(feedback.focus_3)
      setCompleted(report?.status === 'closed')
      setFeedbackAttachments(tmp)
    }
  })

  const [nextReportWeek, setNextReportWeek] = useState()
  const [feedbackAttachments, setFeedbackAttachments] = useState([])
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setModalVisibility] = useState(false);



  const report = weekreport.data?.assistant?.assistantWebcourseWeekReport || {}
  const [completed, setCompleted] = useState(false)


  const requried_input = completed || ! report.status || report.status !== 'submitted'


  const reportData = JSON.parse(report.qa || '{}')
  const feedback = JSON.parse(report.feedback || '{}')
  const widgetData = JSON.parse(report.diaryData || '{}')
  const events = JSON.parse(report.events || '{}')

  const attachments = report?.attachments?.edges || []
  const nextReport = report.nextReport
  const user = report?.user || {}

  const weekNumber = moment(report.week).isoWeek()
  const weekYearNumber = moment(report.week).format('YYYYWW')

  async function handleSubmit(event) {
    try {
      event.preventDefault()
      setLoading(true)
      if (loading || (nextReport.status !== 'scheduled' && nextReport.status !== 'open'  )) return

      const data = JSON.stringify({text: feedbackText, 'focus_1': feedbackFocusOne, 'focus_2': feedbackFocusTwo, 'focus_3': feedbackFocusThree})
      const nextReportDate = nextReportWeek || report.nextReport.week
      const variables = { id, data, nextReportDate, feedbackAttachments }
      const res = await sendFeedback({ variables })
      const {
        loading: mloading,
        data: {
          assistant: {
            assistantWebcourseWeekReportFeedback: {
              status,
              message
            } = {}
          } = {}
        } = {}
      } = res;

      if (status !== 'error') {
        toast(`${status}: ${message}`, { autoClose: 15000 })
      } else {
        throw message
      }

      await weekreport.refetch()
      setLoading(false)

    } catch(err) {
      alert(err);
      setLoading(false)
      throw err;
    }
  }

  async function handlePreview(event) {
    try {
      await handleSubmit(event)
      const to = showPreview(report, feedbackText, feedbackFocusOne, feedbackFocusTwo, feedbackFocusThree) ? `/nettkursukerapport/${props.match.params.week}/${props.match.params.id}/preview` : "#"
      history.push(to);
    } catch(err) {
      setLoading(false)
    }
  }

  return (
    <Container fluid>
      <ToastContainer />
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: 'fixed',
          zIndex: '101'
        }}
      ></div>

      <FormatHelpModal
        isVisible={isModalVisible}
        close={() => setModalVisibility(false)} />
      <div className="headWrapper">
        {weekreport.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <nav className="breadcrumbs">
              <ul>
                <li><Link to="/">Hovedside</Link></li>
                <li><Link to={`/nettkursukerapport/`}>Veiledninger</Link></li>
                <li><Link to={`/nettkursukerapport/${weekYearNumber}/`}>Uke {weekNumber}</Link></li>
                <li>{user.name} ({moment(report.week).format('DD.MM.YYYY')})</li>
              </ul>
              <div className="prevAndNext">
                <Link to={`/nettkursukerapport/${weekYearNumber}/${report.prevReport?.id}/`} data-enabled={Boolean(report.prevReport?.id)}>
                  <img src={reportNavPrev} alt="Forrige" />
                </Link>
                <Link to={`/nettkursukerapport/${weekYearNumber}/${report.nextReport?.id}/`} data-enabled={Boolean(report.nextReport?.id)}>
                  <img src={reportNavNext} alt="Neste" />
                </Link>
              </div>
            </nav>
            <UserInfo user={user} report={report} callback={weekreport.refetch} />

            {['feedback', 'closed'].includes(report.status) && (
              <div className="wcwr-message">
                Tilbakemelding sendt til deltakeren
              </div>
            )}

          {
            events.auto_submit !== undefined && <div>
              <strong style={{'display': 'inline-block', 'marginTop': '1rem', 'fontWeight': '900', 'color': 'red'}}>Levert automatisk {moment(events.auto_submit.timestamp).format('DD/MM HH:MM')}  </strong>
            </div>
          }

          {
            events.auto_submit === undefined && events.submitted !== undefined &&  <div>
              <strong style={{'display': 'inline-block', 'marginTop': '1rem', 'fontWeight': '900', 'color': 'green'}}>Levert {moment(events.submitted.timestamp).format('DD/MM HH:MM')}  </strong>
            </div>
          }
          {
            events.skipped !== undefined && <div>
              <strong style={{'display': 'inline-block', 'marginTop': '1rem', 'fontWeight': '900', 'color': 'red'}}>Hoppet over {moment(events.skipped.timestamp).format('DD/MM HH:MM')}  </strong>
            </div>
          }

          <Row>
            <Col>
              {
                Object.values(reportData).map((section, i) => (
                  <section key={`section-${i}`} className="wcwr-section">
                    <h3>{section.name}</h3>
                    <p><strong>{section.description}</strong></p>
                    <ReportWidget date={report.week} data={widgetData} widget={section.widget} feedback={feedback} />
                      {
                        section.questions && Object.values(section.questions).filter(question => !!question.question.length).map((question, j) => (
                          <div key={`section-${i}-${j}`} className="wcwr-question">
                            <strong>Spørsmål: {question.question}</strong>
                            <div className="wcwr-answer">Svar: {question.answer}</div>
                          </div>
                        ))
                      }
                  </section>
                ))
              }
            </Col>
            <Col>
              {((nextReport && (report.status === 'submitted' || report.status === 'feedback' || report.status === 'closed' )) && <Form method="post" className="report_form" style={{position: 'relative'}} onSubmit={handleSubmit}>
                  {nextReport.isLastReport && (
                    <div className="wcwr-message">
                      Siste tilbakemelding!
                    </div>
                  )}

                  <Form.Group controlId="text">
                    <Form.Label>Veiledning</Form.Label>
                    <Form.Control name="text" as="textarea"  required={requried_input} rows={16} value={feedbackText?feedbackText:''} onChange={(event) => {setFeedbackText(event.target.value)}}  />
                    <button className="help-link" onClick={() => setModalVisibility(true)}>Formateringshjelp</button>
                  </Form.Group>


                  <Form.Group controlId="focus_1">
                    <Form.Label>Fokus 1</Form.Label>
                    <Form.Control name="focus_1" as="input"  required={requried_input} value={feedbackFocusOne?feedbackFocusOne:''} onChange={(event) => {setFeedbackFocusOne(event.target.value)}} />
                  </Form.Group>

                  <Form.Group controlId="focus_2">
                    <Form.Label>Fokus 2</Form.Label>
                    <Form.Control name="focus_2" as="input" value={feedbackFocusTwo?feedbackFocusTwo:''} onChange={(event) => {setFeedbackFocusTwo(event.target.value)}} />
                  </Form.Group>

                  <Form.Group controlId="focus_3">
                    <Form.Label>Fokus 3</Form.Label>
                    <Form.Control name="focus_3" as="input" value={feedbackFocusThree?feedbackFocusThree:''} onChange={(event) => {setFeedbackFocusThree(event.target.value)}} />
                  </Form.Group>

                  { instructorStarted(report) && (
                    <div className="form_item">
                      <span className="started">Påbegynt</span>
                    </div>
                  )}

                  <div className="form_item next_report_container">
                    <NextReportWeek
                      loading={loading}
                      report={report}
                      nextReportWeek={nextReportWeek}
                      setNextReportWeek={setNextReportWeek} />
                  </div>

                  <div className="form_item attachments_container">
                    <FeedbackAttachments feedbackAttachments={feedbackAttachments} attachments={attachments} report={report} setFeedbackAttachments={setFeedbackAttachments} />{` `}
                  </div>

                  <div className="form_item submit_button_container">
                    <Button
                      id="save-button"
                      disabled={loading  || (nextReport.status !== 'scheduled' && nextReport.status !== 'open' )  }
                      type="submit"
                      variant="primary"
                      data-loading={(result && result.loading) || completed}>Lagre</Button>{` `}
                    <Button
                      id="preview-button"
                      variant="primary"
                      type="button"
                      disabled={loading || !showPreview(report, feedbackText, feedbackFocusOne, feedbackFocusTwo, feedbackFocusThree)}
                      onClick={handlePreview}>Forhåndsvis og send inn</Button>
                  </div>
                </Form> )}

              </Col>
            </Row>
          </Fragment>
        )}
      </div>
    </Container>
  )
}

export default WebCourseWeekReport
