import { gql } from "apollo-boost";

const WEIGHT_DRILLDOWN_USER_NEW = gql`
query Assistant($userIdList: [Int], $cacheDate: Date, $category: String, $currentDateTimeFrom: DateTime, $currentDateTimeTo: DateTime, $end: String) {
	assistant {
	  assistantMemberTotal: assistantMemberTotal2(userIdList: $userIdList)
	  assistantUserList: assistantUserList2(userIdList: $userIdList, first: 5, after: $end) {
	    pageInfo {
	      hasNextPage
	      endCursor
	    }
	    edges {
	      node {
	        userId
	        name
	        kcalMenu
	        courseLocation
	        summaryMeasurementsCached(dateTo: $currentDateTimeTo)
	        weeklyDiaryCache(date: $cacheDate) {
	          data
	        }
	        lastRegisteredWeights: diaryRegistration(category: $category, dateFrom: $currentDateTimeFrom, dateTo: $currentDateTimeTo, orderByDate: "desc") {
	          edges {
	            node {
	              registrationId
	              dateTime
	              data
	            }
	          }
	        }
	      }
	    }
	  }
	}
}
`

export default WEIGHT_DRILLDOWN_USER_NEW;
