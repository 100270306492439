import React, { Fragment, useState } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import loadingImg from '../../SysIons/loading.svg';
import NoResults from '../NoResults';
import DateNavigation from '../../DateNavigation2';

import COURSE_MEETING_DATE from '../../../mutation/course_meeting_date.js';
import ASSISTENT_INSTRUCTOR_SEARCH from '../../../query/assistent_instructor_search.js';

var timeout = null;

const ChangeDate = ({
  close,
  ...props
}) => {

    const [courseMeetingId, setCourseMeetingId] = useState(props.courseMeetingId ? props.courseMeetingId : null);
    const [startTime, setStartTime] = useState(props.startTime ? props.startTime : null,);
    const [instructors, setInstructors] = useState([]);
    const [substituteId, setSubstituteId] = useState(props.substituteInstructorId ? props.substituteInstructorId : '');
    const [substituteInstructor, setSubstituteInstrictor] = useState(props.substituteInstructor ? props.substituteInstructor : '');

    const [loadSearch, {
      called,
      loading: ll_loading,
      data: ll_data
    }] = useLazyQuery(ASSISTENT_INSTRUCTOR_SEARCH, {
      fetchPolicy: 'no-cache',
      onCompleted() {

        const {
          request: {
            grassistant: {
              assistentInstructorSearch: {
                edges=[]
              }={}
            } = {}
          }={}
        } = ll_data

        setInstructors(edges)
      }
    });

    const [saveFunction, { data, loading }] = useMutation(COURSE_MEETING_DATE, {
      onCompleted() {
        close();
      }
    });

    const submit = () => {
      try {
        if(!courseMeetingId) {throw Error('You need to set meeting id');}

        if(!startTime) {throw Error('You need to set date and time');}

        let variables = {
          'courseMeetingId': courseMeetingId,
          'startTime': startTime,
        }

        if(substituteId) {
          variables['substituteId'] = substituteId
        }

        saveFunction({ variables: variables});
      } catch(err) {
        alert(err)
      }
    }

    const handleDateChange = (currentDate) => {
      setStartTime(currentDate)
    }

    const setInstructor = (e, obj) => {
      setInstructors([]);
      setSubstituteId(obj.instructorId);
      setSubstituteInstrictor(obj.name);
    }

    const handleInstructorChange = (e) => {
      const search = e.target.value;
      setSubstituteInstrictor(e.target.value)

      clearTimeout(timeout);
      timeout = setTimeout(function () {
        if(search !== "") {
          loadSearch({
              variables: { search: search }
          });

        }
      }.bind(this), 500);
    }


    return (<div className="ModalBody">
      <div className="ModalBody">
      <div className="wbf-field-item">
        <label>Ny dato</label>
        <DateNavigation
          setDate={handleDateChange}
          currentDate={startTime}
          currentDateFormat={"DD.MMM HH:mm"}
          showTime={true}
        />
      </div>

      <div className="wbf-field-item">
        <label>Innbytterinstruktør</label>
        <input
          type="text"
          name="substituteInstructor"
          value={substituteInstructor ? substituteInstructor : ""}
          onChange={handleInstructorChange}
          autoComplete="off"
        />
        {instructors.length > 0 && <div className="instructorAutocompleteList">
          <div className="instructorAutocompleteContainer">
            { ll_loading && <div key="0" className="autocompleteItems">
              <img src={loadingImg} alt="Processing" className="processing_image"/>
            </div>}
            {instructors.map((obj, index) => (
              <div key={index} className="autocompleteItems" onClick={(e) => setInstructor(e, obj.node)}>{obj.node.name}</div>
            ))}
          </div>
        </div>}
      </div>
      </div>

      <footer>
        <div className="buttonContainer pullRight">
          <div className="buttonWrapper">
            {
              loading ? <img src={loadingImg} alt="Processing" className="processing_image"/> :
                <button type="submit" className="buttonItem save" onClick={() => submit()}>Lagre</button>
            }
          </div>
        </div>
      </footer>
    </div>)
}

export default ChangeDate;
