import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'
var _ = require('underscore');
import NotFound from '../atom/NotFound';
import DateNavigation from '../atom/DateNavigation';
import RegionFilter from '../atom/RegionFilter';


import Loading from '../atom/Loading';

import SALES_STATS from '../query/sales_stats.js';

const Breadcrumbs = () => (
	<div className="headWrapper">
			<div className="headText">
				Salgsstatistikk
			</div>
			<nav className="breadcrumbs">
		    <ul>
		      <li><Link to="/">Hovedside</Link></li>
		      <li><Link to={`/sales-stats/`}>Salgsstatistikk</Link></li>
		    </ul>
		  </nav>
	</div>
)

const SalesStats = ({
	match,
	...props
}) => {

	const [currentDate, setCurrentDate] = useState(moment().subtract(1, 'week'))
	const [region, setRegion] = useState('all')

	const subtractWeek = () => {
		setCurrentDate(currentDate.clone().subtract(1, 'week'))
	}

	const addWeek = () => {
		setCurrentDate(currentDate.clone().add(1, 'week'))
	}

	const {
		loading,
		data: {
			request: {
				grassistant: {
					assistantSalesStats = {}
				} = {}

			} = {}
		} = {}
	} = useQuery(SALES_STATS, {
		variables: {
			region: region,
			currentDate: currentDate.format("YYYY-MM-DD")
		},
		fetchPolicy: "cache-and-network"
	});

	const {
		total,
		sales_statistics,
		week,
	} = !loading ? JSON.parse(assistantSalesStats || '{}') : {};

  return <div className="pageContainer participantsContainer">
		<Breadcrumbs/>
    <div className="participantsBody userCard">
			<div className="date-navigation">
				<DateNavigation
					currentWeek={currentDate}
					subtractDate={subtractWeek}
					addDate={addWeek}
					format="W"
				/>
			</div>

			<div className="page-description">
				Velkommen til din salgsstatistikk. Her får du oversikt over hvor mange aktive medlemmer du hadde foregående uke og hvor mange møter som ble levert til disse medlemmene i foregående uke. Alt sammen fordelt på de ulike kurstypene du har tilgjengelig. Du kan også se hvor mange møter som er booket inn hos deg de neste 4 ukene og hvor mange møter som er booket inn totalt hos deg fremover i tid inkludert de 4 neste ukene. Det er viktig å merke seg at tallene for møter frem i tid er basert på bookingene på mandag morgen i uken rapporten blir generert. Dvs at disse kan endre seg dersom du får nye deltakere eller deltakere skulle skippe kursmøter.
			</div>


			{loading && <Loading />}
      {!loading && sales_statistics.length <=0 && <NotFound
        children={`Ingen salgsrapport for uke ${week}`}
      />}

			{
				!loading && sales_statistics.length > 0 && <div className="stats-container">
					<table>
						<thead>
							<tr>
									<th>Navn</th>
									<th>Antall kursmedlemmer</th>
									<th>Gjennomførte møter uke {week}</th>
									<th>Møter uke {week + 1} </th>
									<th>Møter uke {week + 2}</th>
									<th>Møter uke {week + 3}</th>
									<th>Møter uke {week + 4}</th>
									<th>Sum gjenstående møter</th>
							</tr>
						</thead>
						<tbody>
							{sales_statistics.map((obj, key) => (
								<tr key={key}>
	                <td>{obj.name}</td>
	                <td>{obj.number_of_memberships}</td>
	                <td>{obj.booked_meetings}</td>
	                <td>{obj.booked_meetings_plus1}</td>
	                <td>{obj.booked_meetings_plus2}</td>
	                <td>{obj.booked_meetings_plus3}</td>
	                <td>{obj.booked_meetings_plus4}</td>
	                <td>{obj.upcoming_paid_meetings}</td>
	              </tr>
							))}
						</tbody>
						<tfoot>
	              <tr>
	                <td>Sum</td>
	                <td>{total.number_of_memberships}</td>
	                <td>{total.booked_meetings}</td>
	                <td>{total.booked_meetings_plus1}</td>
	                <td>{total.booked_meetings_plus2}</td>
	                <td>{total.booked_meetings_plus3}</td>
	                <td>{total.booked_meetings_plus4}</td>
	                <td>{total.upcoming_paid_meetings}</td>
	              </tr>
	          </tfoot>
					</table>

				</div>
			}
    </div>
  </div>


    return <div className="sales-stats-container"></div>
}

export default SalesStats;
