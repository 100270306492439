import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'
var _ = require('underscore');

import Loading from '../atom/Loading';
import PageTitle from '../atom/PageTitle';
import DateNav from '../atom/DateNav';

import WeightBookGroupDetails from '../ui/WeightBookGroupDetails'

import GET_WEIGHT_BOOK_GROUP_NEW from '../query/get_weight_book_group_new.js';

const DROP_IN_SLUG = 'drop-in'
const DROP_IN_TEXT = 'Drop in'
const PAGE = 'nye-vektbok'

const Breadcrumbs = () => (
  <nav className="breadcrumbs">
    <ul>
      <li><Link to="/">Hovedside</Link></li>
      <li>[NY] Vektbok</li>
    </ul>
  </nav>
)


const WeightBookNew = ({
	match,
	...props
}) => {
	const {
		loading,
    refetch,
		data: {
			request: {
				grassistant: {
					assistantGetWeightbookData2
				} = {}
			} = {}
		} = {}
	} = useQuery(GET_WEIGHT_BOOK_GROUP_NEW, {
		variables: {
			dateTo: match.params.date
		 },
		 fetchPolicy: "cache-and-network"
	});

	const usersGroup = _.groupBy(JSON.parse(assistantGetWeightbookData2 || '[]'), function(obj) {
		return obj.hour;
	})  

  return <div className="pageContainer weightBook">
		<div className="pageHeadWrapper">
			<PageTitle>Vektbok</PageTitle>
			<Breadcrumbs/>
			<DateNav
				page={PAGE}
				match={match}
			/>
		</div>
		{loading ? (
			<Loading />
		) : (
			<WeightBookGroupDetails
				usersGroup={usersGroup}
				page={PAGE}
				match={match}
        callback={refetch}
				{...props}
			></WeightBookGroupDetails>
		)}
	</div>
}

export default WeightBookNew;
