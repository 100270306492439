import { gql } from "apollo-boost";

const ASSISTANT_SEND_EMAIL = gql`
	mutation Assistant($userId: [Int]!, $subject: String!, $emailText: String!, $emailHtml: String!, $emailAttachment: [JSONString]) {
	  assistant {
	    assistantSendEmail(
				userId: $userId,
				subject: $subject,
				emailText: $emailText,
				emailHtml: $emailHtml,
				emailAttachment: $emailAttachment
			) {
	      status
	    }
	  }
	}
`





export default ASSISTANT_SEND_EMAIL;
