import { gql } from "apollo-boost";

const GET_WEIGHT_BOOK_USER2 = gql`
query Assistant($userId: Int!, $status: String) {
  request {
    grassistant {
      assistantGetUser: assistantGetUser2(userId: $userId, status: $status) {
        name
        userId
        startWeight
        weightGoal
        intermediateWeightGoal
      }
    }
  }
}
`

export {
	GET_WEIGHT_BOOK_USER2
}
