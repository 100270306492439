import { gql } from "apollo-boost";

const CHANGE_PARTICIPANT_NUMBER = gql`
mutation Assistant($courseId: Int!, $maxNumParticipants: Int!) {
	assistant {
		assistantChangeParticipantNumber(courseId: $courseId, maxNumParticipants: $maxNumParticipants) {
			status
			message
		}
	}
}
`

export default CHANGE_PARTICIPANT_NUMBER;
