import { gql } from "apollo-boost";

const SKIP_MEETING_BULK = gql`
	mutation Assistant($courseMeetingId: [Int]!) {
	  assistant {
			assistantSkipMeetingBulk(courseMeetingId: $courseMeetingId) {
	      status
	      message
	    }
	  }
	}
`

export default SKIP_MEETING_BULK;
