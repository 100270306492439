import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'
var _ = require('underscore');
import NotFound from '../atom/NotFound';

import Loading from '../atom/Loading';

import ASSISTANT_COMMISSION_INSTRUCTOR from '../query/assistant_commission_instructor.js';
import CommissionInstructorItem from '../atom/CommissionInstructorItem';

const Breadcrumbs = () => (
    <div className="headWrapper">
        <div className="headText">
            Provisjonsrapport
        </div>
        <nav className="breadcrumbs">
            <ul>
                <li><Link to="/">Hovedside</Link></li>
                <li><Link to={`/provisjonsrapport/`}>Provisjonsrapport</Link></li>
            </ul>
        </nav>
    </div>
)


const CommissionInstructor = ({
	match,
	...props
}) => {
    const [year, setYear] = useState(moment());

		const {
			loading,
			data: {
				request: {
					grassistant: {
						assistantCommissionInstructor:{
							edges=[]
						}={}
					} = {}
				} = {}
			} = {}
		} = useQuery(ASSISTANT_COMMISSION_INSTRUCTOR, {
			variables: {
        "year": year.format("YYYY")
			},
      // fetchPolicy: "cache-and-network"
		});

    return <div className="commission-instructor-container">
			<Breadcrumbs/>

			<div className="pageBody">
				{loading && <Loading />}
				{
					!loading && edges && edges.length > 0 && <div className="pageBody">
						<div className="commission-instructor-list">
							<div className="commission-instructor-item-container head">
								<div className="commission-instructor-item-details">
									Måned
								</div>
								<div className="commission-instructor-item-details">
									Beløp
								</div>
								<div className="commission-instructor-item-details">
									PDF
								</div>

							</div>
							{edges.map((obj, index) => (
								<CommissionInstructorItem
									key={index}
									obj={obj.node}
								/>

							))}
						</div>
					</div>
				}

				{!loading && edges && edges.length <= 0 && <div className="participantsBody userCard">
	        <NotFound
	          children="Provisjonsrapport ikke funnet"
	        />
	      </div>}

        <div className="year_pagination_container">
          <div className="year_pagination">
            <div className="year_pagination_item year_left" onClick={() => setYear(year.clone().subtract(1, 'years'))}>{"<"}</div>
            <div className="year_pagination_item current_year">{year.format("YYYY")}</div>
            <div className="year_pagination_item year_right" onClick={() => setYear(year.clone().add(1, 'years'))}>{">"}</div>
          </div>
        </div>

			</div>
		</div>
}

export default CommissionInstructor;
