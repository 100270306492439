import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'

const PAYMENT_SOURCE_MAP = {
  'manual': 'Manuelt' ,
  'stripe_prepaid': 'Pakke',
  'stripe': 'Abonnement'
}

const ItemMembershipName = ({
  membershipId,
  membershipName,
  courseId,
  variant,
  statusText,
  ...props
}) => {
  
    return <Fragment>
      <div className="grid-column">
        {membershipId}
      </div>
      <div className="grid-column">
        {courseId ? <Link to={`/course-details/${courseId}`}>{membershipName}</Link> : membershipName}

      </div>
      <div className="grid-column">
        {PAYMENT_SOURCE_MAP[variant]}
      </div>      
      <div className="grid-column">
        {statusText}
      </div>      

    </Fragment>
}

export default ItemMembershipName;
