import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'


const DateNavigation = ({
  currentWeek,
  subtractDate,
  addDate,
  format='YYYY-W',
  ...props
}) => {
    return <nav className="wb-dateNav">
      <Link className="prev" to="#" onClick={() => subtractDate()}>
        <i className="icon-arrow-2-left"></i>
      </Link>
      <strong>Møter uke {currentWeek.format(format)}</strong>
      <Link className="next" to="#" onClick={() => addDate()}>
        <i className="icon-arrow-2-right"></i>
      </Link>
    </nav>
}

export default DateNavigation;
