import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import moment from 'moment'
import ReportSubmit from '../atom/ReportSubmit'

import Loading from '../atom/Loading';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import UserInfo from '../ui/UserInfo'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReportWidget from '../ui/ReportWidget'
import ReactMarkdown from 'react-markdown'

import ASSISTANT_WEBCOURSE_WEEK_REPORT from '../query/assistant_webcourse_week_report'

import  markdownStyles from '../../assets/styles/Markdown.module.scss'
import { showPreview } from '../utils/report'


const Breadcrumbs = ({
  weekNumber,
  weekYearNumber,
  userName,
  reportId,
  dateTime
}) => (
  <div className="headWrapper">
		<Breadcrumb>
		  <Breadcrumb.Item href="/">Hovedside</Breadcrumb.Item>
      <Breadcrumb.Item href="/nettkursukerapport/">Veiledninger</Breadcrumb.Item>
      <Breadcrumb.Item href={`/nettkursukerapport/${weekYearNumber}/`}>Uke {weekNumber}</Breadcrumb.Item>
      <Breadcrumb.Item href={`/nettkursukerapport/${weekYearNumber}/${reportId}/`}>{userName} ({dateTime})</Breadcrumb.Item>
		  <Breadcrumb.Item href={`/nettkursukerapport/${weekYearNumber}/${reportId}/preview`} active>
		    Preview report
		  </Breadcrumb.Item>
      <li></li>
		</Breadcrumb>
  </div>
)

export default function PreviewReport({ match }) {
	const {
		loading,
		data: {
      assistant: {
        assistantWebcourseWeekReport: report
      } = {}
    } = {},
    refetch
	} = useQuery(ASSISTANT_WEBCOURSE_WEEK_REPORT, {
		variables: {
			id: match.params.id
		},
		fetchPolicy: "cache-and-network"
	});

  const history = useHistory()

	if (loading) {
		return <Loading />
	}

  if (report.status === 'closed') {
    history.push(`/nettkursukerapport/${match.params.week}/${match.params.id}/`)
    return null
  }

  const reportData = JSON.parse(report.qa || '{}')
  const feedback = JSON.parse(report.feedback || '{}')
  const widgetData = JSON.parse(report.diaryData || '{}')
  const nextReport = report.nextReport
  const nrf = JSON.parse(nextReport?.feedback || '{}') // as next report feedback
  const week = nextReport.week

  return <div className="pageContainer participantsContainer">
		<Breadcrumbs
      userName={report.user.name}
      weekNumber={moment(report.week).isoWeek()}
      dateTime={moment(report.week).format('DD.MM.YYYY')}
      weekYearNumber={match.params.week}
      reportId={match.params.id}
    />
    <div className="review_page">
      <UserInfo user={report.user} report={report} callback={refetch} />
      <Container fluid>
        <Row>
          <Col>
            {
              Object.values(reportData).map((section, i) => (
                <section key={`section-${i}`} className="wcwr-section">
                  <h3>{section.name}</h3>
                  <p><strong>{section.description}</strong></p>
                  <ReportWidget date={report.week} data={widgetData} widget={section.widget} feedback={feedback} />
                    {
                      section.questions && Object.values(section.questions).filter(question => !!question.question.length).map((question, j) => (
                        <div key={`section-${i}-${j}`} className="wcwr-question">
                          <strong>Spørsmål: {question.question}</strong>
                          <div className="wcwr-answer">Svar: {question.answer}</div>
                        </div>
                      ))
                    }
                </section>
              ))
            }
          </Col>
          <Col>
            {nextReport.isLastReport && <section className="wcwr-section">
              <div className="wcwr-message">
                Siste tilbakemelding!
              </div>
            </section>}

            {nrf.text && nrf.text !== '' && <section className="wcwr-section">
              <div className="preview_item">
                <div className="preview_label">Veiledning</div>
                <div className="feedback_wrapper">
                  <ReactMarkdown children={nrf.text} className={markdownStyles.markdown} />
                </div>
              </div>
            </section>}

            {nrf.focus_1 && nrf.focus_1 !== '' && <section className="wcwr-section">
              <div className="preview_item">
                <div className="preview_label">Fokus 1</div>
                <div className="focus_preview">{nrf.focus_1}</div>
              </div>
            </section>}

            {nrf.focus_2 && nrf.focus_2 !== '' && <section className="wcwr-section">
              <div className="preview_item">
                <div className="preview_label">Fokus 2</div>
                <div className="focus_preview">{nrf.focus_2}</div>
              </div>
            </section>}

            {nrf.focus_3 && nrf.focus_3 !== '' && <section className="wcwr-section">
              <div className="preview_item">
                <div className="preview_label">Fokus 3</div>
                <div className="focus_preview">{nrf.focus_3}</div>
              </div>
            </section>}

            {nrf.files && nrf.files.length > 0 && <section className="wcwr-section">
              <div className="preview_item">
                <div className="preview_label">Vedlagte filer:</div>
                <div className="attachefiles">
                  <ul>
                    {nrf.files.map((obj, key) => (
                      <li key={key}>{obj.filename}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>}

            {week && <section className="wcwr-section">
              <div className="preview_item">
                <div className="preview_label">Neste bookede oppfølging:</div>
                <div>{moment(week).format('DD.MM.YYYY')}</div>
              </div>
            </section>}

            <div className="form_item submit_button_container">
              <Link
                className="preview_button btn btn-danger"
                to={`/nettkursukerapport/${match.params.week}/${match.params.id}/`}
              >
                <i className="action-icons icon-cancel-circle"></i> Lukk
              </Link>{` `}
              {showPreview(report, nrf.text, nrf.focus_1, nrf.focus_2, nrf.focus_3) && <ReportSubmit
                id={match.params.id}
                closePreview={refetch}
              />}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
}
