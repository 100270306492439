import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './components/container/ScrollToTop';
import Auth from './components/container/Auth';

import PageHeader from './components/layout/PageHeader';
import PageFooter from './components/layout/PageFooter';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/global.scss'
import Container from 'react-bootstrap/Container';
import { basename } from './config';

import PropsRoute, { routes }  from './routes';

const App = () => {	
	return <Auth>
		<Router basename={basename}>			
			<PageHeader/>
			<Container fluid>
				<ScrollToTop>
					{
						routes.map((route, i) => (
							<PropsRoute exact strict key={i} path={route.path} component={route.component} />
						))
					}
					<PageFooter/>
				</ScrollToTop>
			</Container>
		</Router>
	</Auth>
  
}

export default App;
