import React from "react";
import { ProgressBar } from "./ProgressBars";

export default function Nutrition({ week, dietScore, user }) {
  const kcalMenu = user.kcal_menu || "0";
  return (
    <section className="widget-section widget-food" data-featured="true">
      <h4>
        Energi <span>(snitt)</span>
      </h4>
      <ul>
        <ProgressBar
          value={week.diet?.energyDensity}
          recommended={
            dietScore.energyDistribution.normal.energyDensity.recommended
          }
          min={dietScore.energyDistribution.normal.energyDensity.minShare}
          max={dietScore.energyDistribution.normal.energyDensity.maxShare}
          title="Energitetthet"
          unit="kcal"
        />
        <ProgressBar
          value={week.diet?.energyShareFromEatenCarbs}
          recommended={
            dietScore.energyDistribution.normal.carbohydrate.recommended
          }
          min={dietScore.energyDistribution.normal.carbohydrate.minShare}
          max={dietScore.energyDistribution.normal.carbohydrate.maxShare}
          title="Karbohydrater"
          unit="%"
        />
        <ProgressBar
          value={week.diet?.energyShareFromProteins}
          recommended={dietScore.energyDistribution.normal.protein.recommended}
          min={dietScore.energyDistribution.normal.protein.minShare}
          max={dietScore.energyDistribution.normal.protein.maxShare}
          title="Proteiner"
          unit="%"
        />
        <ProgressBar
          value={week.diet?.energyShareFromEatenFat}
          recommended={dietScore.energyDistribution.normal.fat.recommended}
          min={dietScore.energyDistribution.normal.fat.minShare}
          max={dietScore.energyDistribution.normal.fat.maxShare}
          title="Fett"
          unit="%"
        />
        <ProgressBar
          value={week.diet?.totalEatenKcal}
          recommended={dietScore.kcal.menus[kcalMenu].recommended}
          min={dietScore.kcal.menus[kcalMenu].min}
          max={dietScore.kcal.menus[kcalMenu].max}
          title="Energiinntak"
          unit="kcal"
        />
        <ProgressBar
          value={week.diet?.score}
          recommended={dietScore.score.recommended}
          min={dietScore.score.min}
          max={dietScore.score.max}
          title="Kostholdsscore"
          unit="poeng"
        />
      </ul>
    </section>
  );
}
