import React, { Fragment, useState } from 'react';

const RestingMetabolismItem = ({restingMetabolism, ...props}) => {
    return <Fragment>
      {restingMetabolism && (
        <li><span>Hvilestoffskifte</span> {restingMetabolism} kcal</li>
      )}
    </Fragment>
}

export default RestingMetabolismItem;
