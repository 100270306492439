import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react'
import { useMutation, useQuery } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom'
import loadingImg from '../../SysIons/loading.svg';
import Loading from '../../../atom/Loading';
import NoResults from '../NoResults';
import DateNavigation from '../../DateNavigation2';
import Slider from 'rc-slider/lib/Slider'
import moment from 'moment'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// const history = useHistory();
const MIN_WEEK_PURSUIT = 0
const MAX_WEEK_PURSUIT = 10
const DEFAULT_WEEK_PURSUIT = 5

import GET_WEIGHTBOOK_USER from '../../../query/get_weightbook_user.js';
import REGISTER_WEIGHT from '../../../mutation/register_weight.js';

const formatAmount = (amount, unit, fallback = 'n/a') => {
    if (amount === null || amount === undefined || amount === false || amount === "") return fallback

    if (unit) return `${amount.toLocaleString('nb-NO')} ${unit}`

    return amount.toLocaleString('nb-NO')
}

const WeightForm = forwardRef((props, ref) => {
  let history = useHistory();

  const {
    user,
    page,
    match: {
      params: {
        date
      }={}
    }={},
    from,
    initDate,
    close,
  } = props

  useImperativeHandle(ref, () => ({

    saveAndClose() {
      submit();
    }
  }));

  const [currentDate, setCurrentDate] = useState(date || initDate)

  const [saveFunction, { data, loading }] = useMutation(REGISTER_WEIGHT, {
    onCompleted() {
      close();
    }
  });

  const {
    loading: dataLoading,
    data: {
      request: {
          grassistant: {
            assistantGetWeighingsData,
          }={}
      }={}
    }={}
  } = useQuery(GET_WEIGHTBOOK_USER, {
      variables: {
        userId: user.id,
        dateTo: moment(currentDate).format("YYYY-MM-DD"),
      },
      onCompleted() {
        const {
          prevCoursleaderWeight=null,
          prevDateTime=null,
          coursleaderWeight=null,
          weeksPursuit=null,
          comment=null,
          absence=null,
          dateTime=null,
          registrationId=null,
          membershipId=null,
          userId=null,
          assistantStartWeight=0,
          courseStartCoursleaderWeight=0,
          courseStartDateTime=null,
          startCoursleaderWeight=0,
          startDateTime=null,
        } = JSON.parse(assistantGetWeighingsData || '{}')

        setClWeight(coursleaderWeight);
        setWp(weeksPursuit || DEFAULT_WEEK_PURSUIT);
        setComment(comment);
        setAbsence(absence === 'true' ? true : false);
        setPrevWeight(prevCoursleaderWeight);
        setPrevDateTime(prevDateTime);
        setDateTime(dateTime);
        setRegId(registrationId);
        setMembershipId(membershipId);
        setCourseStart(courseStartCoursleaderWeight);
        setCourseStartDate(courseStartDateTime);
        setStartWeight(assistantStartWeight > 0 ? assistantStartWeight : startCoursleaderWeight);
        setStartDate(startDateTime);
      },
      fetchPolicy: "cache-and-network"
  });

  const [prevCoursleaderWeight, setPrevWeight] = useState();
  const [prevDateTime, setPrevDateTime] = useState();
  const [coursleaderWeight, setClWeight] = useState();
  const [weeksPursuit, setWp] = useState();
  const [comment, setComment] = useState();
  const [absence, setAbsence] = useState();
  const [dateTime, setDateTime] = useState();
  const [registrationId, setRegId] = useState();
  const [membershipId, setMembershipId] = useState();
  const [courseStart, setCourseStart] = useState();
  const [courseStartDate, setCourseStartDate] = useState();
  const [startWeight, setStartWeight] = useState();
  const [startWeightDate, setStartDate] = useState();



  const formatAmount2 = (amount) => {
      const value = formatAmount(amount);

      return ['n/a', '', null].includes(value) ? value : value.replace(',', '.')
  }


  const formatDate = (date, text) => {
          const dt = moment(date)
          return dt.isValid() ?  `${dt.format('DD.MM.YY')} - ${text}` : text;
  }

  const submit = () => {
    try {
      if(coursleaderWeight || absence || comment) {
        // if (coursleaderWeight && absence) {
        //     throw "It should be either weight registered or absence checked"
        // }

        const data = {
          'coursleaderWeight': coursleaderWeight ? coursleaderWeight.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0] : "",
          'weeksPursuit': weeksPursuit,
          'absence': absence,
          'comment': comment,
        }

        const variables = {
          registrationId: registrationId,
          userId: user.id,
          dateTime: registrationId ? moment(dateTime).format('YYYY-MM-DDT00:00:00') : moment(currentDate).format('YYYY-MM-DDT00:00:00'),
          data: JSON.stringify(data),
        }

        saveFunction({ variables: variables});
      } else {
        throw Error("Please enter weight, select absence or add a comment")
      }
    } catch(err) {
      console.log(err);
      toast(err.message ? `Error: ${err.message}` : "Error, please try again")
    }
  }

  const saveAndWeightbookling = () => {
      submit()
      const path = `/vektbok/${user.id}/${getPages()}/`
      history.push(path);
  }

  const handleChange = (e) => {

    if (e.target === undefined) {
        setWp(e)
    } else if (e.target.name === 'coursleaderWeight') {
      const value = e.target.value.replace(',', '.')
      if(!isNaN(value)) {
        setClWeight(value || '')
      }
    } else if (e.target.name === 'absence') {
      setAbsence(e.target.checked ? true : false)
    } else if (e.target.name === 'comment') {
      setComment(e.target.value)
    }
  }

  const getPages = () => {    
    return "nye-vektutvikling"
  }


  if (dataLoading) {
    return <Loading/>
  }


  return (<div className="ModalBody">
    <ToastContainer />
    <div className="ModalBody">
        {membershipId ? <Form
          previousWeight={prevCoursleaderWeight}
          previousDate={prevDateTime}
          coursleaderWeight={coursleaderWeight }
          weeksPursuit={ weeksPursuit }
          comment={ comment }
          absence={ absence }
          measurementDate={dateTime}
          registrationId={registrationId}
          currentDate={currentDate}
          handleChange={handleChange}
          handleDateChange={setCurrentDate}
          {...props}
        /> : <NoResults>
          Ingen aktive kurs for denne deltaker
        </NoResults>}
    </div>

    <div className="weightbook_container">
      <div className="weightbook_item">
          <div className="left_item bold_item">Vektutvikling</div>
          <div className="right_item"></div>
      </div>
      <div className="weightbook_item">
          <div className="left_item">
              {
                  formatDate(prevDateTime, 'Endring siden forrige veiing')
              }
          </div>
          <div className="right_item bold_item">
              {
                  coursleaderWeight > 0 ? formatAmount2(coursleaderWeight - prevCoursleaderWeight) : 0
              }
          </div>
      </div>
      <div className="weightbook_item">
          <div className="left_item">
              {
                  formatDate(courseStartDate, 'Endring på dette kurset')
              }
          </div>
          <div className="right_item bold_item">
              {
                  coursleaderWeight > 0 ? formatAmount2(coursleaderWeight - courseStart) : 0
              }
          </div>
      </div>

      <div className="weightbook_item">

          <div className="left_item">
              {
                  formatDate(startWeightDate, 'Endring siden startdato')
              }
          </div>
          <div className="right_item bold_item">
              {
                  coursleaderWeight > 0 ? formatAmount2(coursleaderWeight - startWeight) : 0
              }
          </div>
      </div>
    </div>



    <footer className='display-flex'>

      <div className="buttonContainer pullLeft">
      {
        (from && ['all', 'user_card'].indexOf(from) >= 0) && <div className="buttonWrapper">
          {
            loading ? <img src={loadingImg} alt="Processing" className="processing_image"/> :
            <button className="buttonItem waightbookling" onClick={() => saveAndWeightbookling()}>
                Lagre og se Vektutvikling
            </button>
          }

        </div>
      }
      </div>
      <div className="buttonContainer pullRight">
        <div className="buttonWrapper">
          {
            loading ? <img src={loadingImg} alt="Processing" className="processing_image"/> :
              <button type="submit" className="buttonItem save" onClick={() => submit()}>Lagre</button>
          }
        </div>
      </div>
    </footer>
  </div>)
});

const Form = ({
  previousWeight,
  previousDate,
  coursleaderWeight,
  weeksPursuit,
  comment,
  absence,
  measurementDate,
  registrationId,
  handleChange,
  currentDate,
  handleDateChange,
  ...props
}) => {


  const {
    match: {
      params: {
        date
      } = {}
    } = {},
    initDate
  } = props;

  return (<Fragment>
      <div className="header">
        <div className="left-item">
          <p>Forrige veiing: <strong>{formatAmount(previousWeight, 'kg')}</strong>
            <span className="timeClean">{previousWeight !== "" && `   (${moment(previousDate).format("DD.MM.YYYY")})` }</span>
          </p>
        </div>
        <div className="right-item header_item">
          {
            (date || initDate) ? <div className="buttonWrapper">{moment(date || initDate).format("DD.MMM")}</div> : <DateNavigation
              setDate={handleDateChange}
              currentDate={currentDate ? moment(currentDate) : moment()}
              currentDateFormat={"DD.MMM"}
            />
          }
        </div>
      </div>

      <div className="wbf-field">
        <label>Dagens vekt</label>
        <input
          type="text"
          name="coursleaderWeight"
          value={coursleaderWeight ? coursleaderWeight : ""}
          onChange={handleChange}
          autoFocus
        />
        <div className="registrationDateWrapper">{measurementDate ? `(Registrert på: ${moment(measurementDate).format("D.MM.YYYY")})`: ""}</div>
      </div>
      <div className="wbf-field">
        <label>Ukens trivsel</label>
        <strong className="wbf-slider-val">{weeksPursuit}</strong>
        <Slider min={MIN_WEEK_PURSUIT} max={MAX_WEEK_PURSUIT} value={parseInt(weeksPursuit)} onChange={handleChange} />
      </div>
      <div className="wbf-field">
        <label>Kommentar</label>
        <textarea name="comment" value={comment ? comment : ""} onChange={handleChange} />
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            name="absence"
            checked={absence}
            onChange={handleChange}
            disabled={moment(currentDate).format('WWYYYY') !== moment().format('WWYYYY') ? "disabled" : ""}
          /> Fravær
        </label>
      </div>
  </Fragment>)
}



export default WeightForm;
