import { gql } from "apollo-boost";

const ASSISTANT_USERS_AUTOCOMPLETE = gql`
query Assistant($keyword: String) {
	request {
		grassistant {
			assistantUsersAutocomplete(keyword: $keyword)
		}
	}
}
`

export default ASSISTANT_USERS_AUTOCOMPLETE;
