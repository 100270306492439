"use strict"

import "core-js/stable";
import "regenerator-runtime/runtime";


import React from 'react';
import { render } from 'react-dom';
import App from './App';

import {ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
//import ApolloClient from 'apollo-boost';

import * as Sentry from '@sentry/browser';


console.log("API URL:")
console.log(process.env.REACT_APP_API_URL)
console.log("env:")
console.log(process.env)


if (process.env.NODE_ENV == 'production') {
  Sentry.init({dsn: "https://f69f969f67dc451e9a678a539fffafc5@sentry.io/251549"});
}

import _ from 'underscore';
global._ = _;

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL, 
  credentials: 'include', 
  fetchOptions: {
    mode: 'cors',
  }
})

const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache()
})

const Application = () => (
  <ApolloProvider client={client}>
    <App/>
  </ApolloProvider>
);

render(<Application />, document.getElementById('root'));
