import { gql } from "apollo-boost";

const SAVE_INSTRUCTOR_SETTINGS = gql`
	mutation Assistant(
	  $shortDescription: String,
	  $introduction: String
	) {
	  assistant {
	    assistantSaveInstructorSettings(
	      shortDescription: $shortDescription,
	      introduction: $introduction
	    ) {
	      status
	      message
	    }
	  }
	}
`

export default SAVE_INSTRUCTOR_SETTINGS;
