import React from 'react';

import { Link } from 'react-router-dom';

const CalendarTabs = ({
  planType,
  year,
  ...props
}) => {
    return <div className="tabs">
      <Link className={`tab_item ${planType == 'personal' ? 'active' : ''}`} to={`/course-planner?year=${year}`}>
        Local courses
      </Link>
      <Link className={`tab_item ${planType == 'nettcourse' ? 'active' : ''}`} to={`/nettcourse-planner?year=${year}`}>
        Net courses
      </Link>
      <Link className={`tab_item ${planType == 'videocourse' ? 'active' : ''}`} to={`/course-planner/videocourse?year=${year}`}>
        Video courses
      </Link>
    </div>
}

export default CalendarTabs;
