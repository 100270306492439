import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import ASSISTANT_SALES_OVERVIEW from '../query/assistant_sales_overview.js';

import { Link } from 'react-router-dom'

import Loading from '../atom/Loading';
import BarChart from '../ui/BarChart';
import DonutChart from '../ui/DonutChart';
import NotFound from '../atom/NotFound';

const Breadcrumbs = () => (
  <nav className="breadcrumbs">
    <ul>
      <li><Link to="/">Hovedside</Link></li>
      <li><Link to={`/salgsoversikt/`}>Min salgsoversikt</Link></li>
    </ul>
  </nav>
)

const SalesOverview = ({}) => {
	const {
		loading,
		data: {
			request: {
				grassistant: {
					assistantSalesOverview: result
				} = {}
			} = {}
		} = {}
	} = useQuery(ASSISTANT_SALES_OVERVIEW);

	const currencyConverter = (x) => {
    let tmp = x.toFixed(2).split(".");
    let decade = tmp[0] + ",";
    let decimal = parseInt(tmp[1]) > 0 ? tmp[1] : "";

    return decade.replace(/(\d)(?=(\d{3})+\,)/g, '$1.') + decimal
  }

	if (loading) {
		return <div className="sales-overview-container SalesOverviewContainer">
      <Loading />
    </div>
	}
  
  if (!result) {
    return <div className="sales-overview-container SalesOverviewContainer">
      <NotFound
        children="There is no sales overview at this moment."
      />
    </div>
  }

	const resalePercentage = 60

    return <div className="sales-overview-container SalesOverviewContainer">
			<div className="SalesOverviewWrapper">
				<div className="SalesBreadcrumbWrapper">
					<div className="headWrapper">
						<h1 className="headText">Min salgsoversikt</h1>
						<Breadcrumbs/>
					</div>
				</div>

				{result && <div className="SalesCommissionsWrapper">
					{result.commisionMonth && <div className="GridContainer CommissionsGrid">
						<div className="GridItem CommissionsGridItem">
							Provisjon hittil denne måneden
						</div>
						<div className="GridItem CommissionsGridItem ValueItem">
							kr {currencyConverter(result.commisionMonth)}-
						</div>
					</div>}

					{result.commisionYear && <div className="GridContainer CommissionsGrid">
						<div className="GridItem CommissionsGridItem">
							Provisjon hittil i år
						</div>
						<div className="GridItem CommissionsGridItem ValueItem">
							kr {currencyConverter(result.commisionYear)}-
						</div>
					</div>}

					{result && (result.noParticipantsTotal !== undefined && result.noParticipantsTotal !== 0) && <div className="SalesCommissionsWrapper ParicipantsNoWrapper">
            {result.noParticipantsTotal && <div className="GridContainer CommissionsGrid">
              <div className="GridItem CommissionsGridItem">
                Antall deltakere inneværende måned
              </div>
              <div className="GridItem CommissionsGridItem ValueItem">
                {result.noParticipantsTotal}
              </div>
            </div>}
          </div>}

					{ result && result.itemsSold && result.budget && result.deviation  && <div className="SalesBarsChartWrapper">
            <div className="HeadWrapper">
              Salgsperiode 2
            </div>
            <div className="ChartWrapper">
              <BarChart
								itemsSold={result.itemsSold}
								budget={result.budget}
								deviation={result.deviation}
							/>
            </div>
          </div> }


					{ result && result.resalePercentage && <div className="SalesDonutChartWrapper">
						<div className="HeadWrapper">
							Gjensalgsprosent
						</div>
						<div className="ChartWrapper">
							<div className="DonutChartContainer">
								<DonutChart resalePercentage={result.resalePercentage}/>
								<div className="labelWrapper">{result.resalePercentage}%</div>
							</div>
							<div className="DonutTextContainer">
								Landsgjennomsnitt
							</div>
						</div>
					</div> }


			</div>}

		</div>
	</div>
}

export default SalesOverview;
