function instructorStarted(report) {
  if (report.status !== 'submitted') {
    return false
  }

  const events = JSON.parse(report?.events)
  return Boolean(events?.instructor_started?.timestamp)
}

function showPreview(report, feedbackText, feedbackFocusOne) {
  const result = instructorStarted(report);
  const text = feedbackText !== null && feedbackText.trim()
  const one = feedbackFocusOne !== null && feedbackFocusOne.trim()

  return result && text && text !== '' && one && one !== '';
}

export {
  instructorStarted,
  showPreview
}
