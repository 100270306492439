import { gql } from "apollo-boost"

const ASSISTANT_WEBCOURSE_WEEK_REPORTS = gql`
	query GetWebcourseWeekReports($date: Date) {
    assistant {
      assistantWebcourseWeekReports(date: $date) {
        edges {
          node {
            id: webcourseweekreportId
            userId
            week
            feedbackDeadline
            status
            created
            reportType
            user {
              name
            }
            membership {
              membershipId
              status
              frequency
							activatedDate
            }
            events
          }
        }
      }
    }
  }
`

export default ASSISTANT_WEBCOURSE_WEEK_REPORTS
