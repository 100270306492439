import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import ASSISTANT_WEBCOURSE_WEEK_REPORTS from '../query/assistant_webcourse_week_reports'
import Loading from '../atom/Loading'
import moment from 'moment'
import { instructorStarted } from '../utils/report'

const weekDays = {
  1: 'Mandag',
  2: 'Tirsdag',
  3: 'Onsdag',
  4: 'Torsdag',
  5: 'Fredag',
  6: 'Lørdag',
  7: 'Søndag',
}

const statuses = {
  scheduled: 'Ikke åpnet',
  open: 'Klar',
  open_started: 'Påbegynt',
  submitted: 'Innsendt',
  closed: 'Lukket',
}

const instructrStartedstatuses = {
  scheduled: '-',
  open: '-',
  open_started: '-',
  submitted: 'Påbegynt',
  closed: 'Levert',
}
const instructrNotStartedstatuses = {
  scheduled: '-',
  open: '-',
  open_started: '-',
  submitted: 'Klar',
  closed: 'Levert',
}

const reportTypes = {
  intro: 'Onboarding',
  paid: 'Løpende',
  paid_last: 'Siste tilbakemelding (besvares ikke)',
}

const frequencies = {
  1: 'Fase 3',
  2: 'Fase 2',
  4: 'Fase 1',
}

function groupReportsByDay(reports = []) {
  const submitted = reports.filter(
    (report) => report.node.status === 'submitted'
  )
  const nonSubmitted = reports.filter(
    (report) => report.node.status !== 'submitted'
  )

  return submitted.concat(nonSubmitted).reduce(
    (acc, { node }) => {
      const weekDay = moment(node.week).format('E')
      const deadline = moment(node.week).add(1, 'days').format('YYYY-MM-DD')
      const instructorStartedData = instructorStarted(node)
        ? instructrStartedstatuses[node.status]
        : instructrNotStartedstatuses[node.status]
      Object.assign({}, node, {
        deadline,
        instructorStarted: instructorStartedData,
      })
      acc[weekDay].push(node)
      return acc
    },
    {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
    }
  )
}

const WeekNavigation = ({ week }) => {
  const currentWeek = moment(week, 'YYYYWW')
  const previousWeek = moment(week, 'YYYYWW').subtract(1, 'week')
  const nextWeek = moment(week, 'YYYYWW').add(1, 'week')

  return (
    <nav className="wb-dateNav">
      <Link
        className="prev"
        to={`/nettkursukerapport/${previousWeek.format('YYYYWW')}/`}
      >
        <i className="icon-arrow-2-left"></i>
      </Link>
      <strong>Uke {currentWeek.format('W')}</strong>
      <span>
        ({currentWeek.startOf('isoWeek').format('DD.MM.YYYY')} -
        {currentWeek.endOf('isoWeek').format('DD.MM.YYYY')})
      </span>
      <Link
        className="next"
        to={`/nettkursukerapport/${nextWeek.format('YYYYWW')}/`}
      >
        <i className="icon-arrow-2-right"></i>
      </Link>
    </nav>
  )
}

function WeekReports({ reports, week }) {
  return (
    <Fragment>
      <div className="wcwr-table">
        <div className="wcwr-tr">
          <div className="wcwr-th">Navn</div>
          <div className="wcwr-th">Start</div>
          <div className="wcwr-th">Progresjon</div>
          <div className="wcwr-th">Fase</div>
          <div className="wcwr-th">Svarfrist</div>
          <div className="wcwr-th">KL&nbsp;status</div>
          <div className="wcwr-th" data-last>
            KM&nbsp;status
          </div>
        </div>
        {Object.keys(weekDays).map((key) => (
          <Fragment key={key}>
            <div className="wcwr-tr">
              <div className="wcwr-day">
                <strong>{weekDays[key]}</strong> {reports[key].length} KM
              </div>
            </div>
            {reports[key].map((report) => (
              <WeekReport key={report.id} report={report} week={week} />
            ))}
          </Fragment>
        ))}
      </div>
    </Fragment>
  )
}

function WeekReport({ report, week }) {
  return (
    <Link
      key={report.id}
      to={`/nettkursukerapport/${week}/${report.id}/`}
      className="wcwr-tr-link"
      data-status={report.status}
    >
      <div className="wcwr-td">{report.user.name}</div>
      <div className="wcwr-td">
        {report?.membership?.activatedDate
          ? moment(report?.membership?.activatedDate).format(
              'DD-MM-YY [kl] HH:mm'
            )
          : ''}
      </div>
      <div className="wcwr-td">
        <span data-type={report.reportType}>
          {reportTypes[report.reportType]}
        </span>
      </div>
      <div className="wcwr-td">
        <span data-frequency={report?.membership?.frequency}>
          {frequencies[report?.membership?.frequency]}
        </span>
      </div>
      <div className="wcwr-td">
        <span data-deadline>
          {report.feedbackDeadline
            ? moment(report.feedbackDeadline).format('DD-MM-YY [kl] HH:mm')
            : ''}
        </span>
      </div>
      <div className="wcwr-td">
        <span data-instructor={report.instructorStarted}>
          {report.instructorStarted}
        </span>
      </div>
      <div className="wcwr-td" data-last>
        <span data-status={report.status}>{statuses[report.status]}</span>
      </div>
    </Link>
  )
}

export default function WebCourseWeekReports({ match }) {
  const week = match.params.week || moment().format('GGGGWW')
  const variables = { date: moment(week, 'GGGGWW').format('YYYY-MM-DD') }
  const { loading, data } = useQuery(ASSISTANT_WEBCOURSE_WEEK_REPORTS, {
    variables,
  })
  const reports = groupReportsByDay(
    data?.assistant?.assistantWebcourseWeekReports?.edges
  )

  return (
    <div className="pageContainer wcwr">
      <div className="headWrapper">
        <nav className="breadcrumbs">
          <ul>
            <li>
              <Link to="/">Hovedside</Link>
            </li>
            <li>
              <Link to={`/nettkursukerapport/`}>Veiledninger</Link>
            </li>
          </ul>
        </nav>
        <WeekNavigation week={week} />
        {loading ? <Loading /> : <WeekReports reports={reports} week={week} />}
      </div>
    </div>
  )
}
