import React, { Fragment, useState } from 'react';
var _ = require('underscore');
import ReactTags from 'react-tag-autocomplete'
import { useQuery } from '@apollo/client';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ASSISTANT_USERS_AUTOCOMPLETE from '../../query/assistant_users_autocomplete.js'

const AutocompleteParticipants = ({
  emailTags,
  handleEmailTags,
  type="email_form",
  ...props
}) => {
    const showTo = type === 'email_form' ? true : false;
    const placeholder = type === 'email_form' ? "epostadresse" : "SMS";


    const [suggestions, setSuggestion] = useState([]);
    const [keyword, handleInputChange] = useState();

    const handleDelete = (tag) => {
      const tags = emailTags.slice(0);
      tags.splice(tag, 1);
      handleEmailTags(tags);
    }

    const handleAddition = (tag) => {
        const is_allowed = () => {
          try {
            if(type === 'sms_form' && !tag.is_active) {
              throw 'This user do not allow sending sms';
            }

            if(type === "email_form" && !tag.emailAllowed) {
              throw 'This user do not allow sending emails';
            }

            return true;
          } catch(err) {
            toast(err);
          }
        }

        if (is_allowed()) {
          var userId = tag.userId;
          if (_.find(emailTags, function(obj){ return obj.userId == userId; }) === undefined) {
            handleEmailTags([...emailTags, tag]);
          }
        }
    }

    const {
      loading,
      data: {
        request: {
            grassistant: {
              assistantUsersAutocomplete
            } = {}
        } = {}
      } = {}
    } = useQuery(ASSISTANT_USERS_AUTOCOMPLETE, {
      variables: {
				keyword: keyword
      },
      fetchPolicy: "no-cache",
      onCompleted() {
        console.log("onCompleted");

        var users =JSON.parse(assistantUsersAutocomplete || '{}').sort((a, b) => (a.status > b.status) ? 1 : -1);

        var arr = _.map(users, function(tmp) {
          return {
            "userId": tmp.userId,
            "id": tmp.userId,
            "email": tmp.email,
            "name": tmp.name,
            "emailAllowed": tmp.status === 'active' ? true : tmp.emailAllowed,
            "is_active": tmp.status === 'active'
          }
        });

        setSuggestion(arr)
      }
    });

    return <>
      <ToastContainer />
      <div className={`email-tags ${showTo ? 'two-columns' : ''}`}>
        <div className={`to-text-container ${showTo ? '' : 'hidden'}`}>Til:</div>
        <div className="react-tags-container">
          <ReactTags
            tags={emailTags}
            suggestions={suggestions}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleInputChange={handleInputChange}
            placeholder={placeholder}
          />
        </div>
      </div>
    </>
}

export default AutocompleteParticipants;
