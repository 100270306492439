import React, { Fragment, useState } from 'react'
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import moment from 'moment'

import loadingImg from '../../SysIons/loading.svg';
import NoResults from '../NoResults';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../../atom/Loading';

import ASSISTANT_MOVE_TO_COURSE from '../../../mutation/assistant_move_to_course.js';
import USER_SCHEMA_NEW from '../../../query/list_alternative_courses.js';

import { dayOfWeek } from '../../../utils/Constants'

const MoveMembership2 = ({
  userId,
  close,
  ...props
}) => {


  const {
		loading: loadingData,
		data: {
			request: {
        grassistant: {
          assistantGetUser: {
            listAlternativeCourses: {
              edges: alternativCourses = []
            } = {}
          } = {}
        } = {}
      } = {}
		} = {}
	} = useQuery(USER_SCHEMA_NEW, {
		variables: {
			userId: userId,
      status: "active"
		}
	});

  const {
    memberships,
  } = props;

  const [saveFunction, { data, loading }] = useMutation(ASSISTANT_MOVE_TO_COURSE, {
    onCompleted() {
      close();
    }
  });

  const submit = () => {
    try {
      if(courseId === undefined) {throw 'You need to select course';}
      if(memberships.length <= 0) {throw 'You need to choose membership';}

      const variables = {
        'courseId': courseId,
        'memberships': memberships,
      }

      saveFunction({ variables: variables});
    } catch(err) {
      toast(err)
    }
  }

  const [courseId, setCourseId] = useState();


  return <div className="ModalBody">
    <ToastContainer />

    {loadingData ? <Loading/> : <div className="course-list-container">
      {
        alternativCourses.length > 0 ? <div className="list-wrapper">
        <div className="radio-grid-container radio-list-header">
          <div className="grit-item">

          </div>
          <div className="grit-item">
            Name
          </div>
          <div className="grit-item">
            Time
          </div>
          <div className="grit-item">
            Type
          </div>
        </div>
        {
          alternativCourses.map((item, key) => (
            <CourseItem course={item.node} key={key} callback={setCourseId}/>
          ))
        }

        </div> : <NoResults>There is no result found</NoResults>
      }
    </div>}

    <footer>
      <div className="buttonContainer pullRight">
        <div className="buttonWrapper">
          {
            loading ? <img src={loadingImg} alt="Processing" className="processing_image"/> :
              <button type="submit" className="buttonItem save" onClick={() => submit()}>Lagre</button>
          }
        </div>
      </div>
    </footer>
  </div>
}


const CourseItem = ({
  course,
  callback
}) => {

  return <div className="radio-grid-container">
    <div className="grit-item">
      <input className="radio-input" type="radio" id={`course-${course.courseId}`} name="course" value={course.courseId} onChange={() => callback(course.courseId)} />
    </div>
    <div className="grit-item">
      {course.autoName}
    </div>
    <div className="grit-item">
      {`${dayOfWeek[course.meetingWeekday]} ${moment(course.meetingStartTime, 'HH:mm:ss').format("HH:mm")} - ${moment(course.meetingEndTime, 'HH:mm:ss').format("HH:mm")}`}
    </div>
    <div className="grit-item">
      {course.courseTypeName}
    </div>
  </div>
}
export default MoveMembership2;
