import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { basename, routepath } from '../../config';
import { user } from '../container/Auth';
import backIcon from '../ui/SysIons/back.svg';
import roedeLogo from '../ui/SysIons/roede-logo-hvit.svg';
import { Link } from 'react-router-dom'
const PageHeader = () => {

	const asInstructor = user.user?.asInstructor ? JSON.parse(user.user?.asInstructor) : null
	return (
		<Navbar variant="dark" fixed="top" sticky="top" collapseOnSelect={true}  expand="lg" data-as-instructor={asInstructor? 'true' : 'false'}>
	
		<Navbar.Brand href={process.env.REACT_APP_ROEDE_FRONTPAGE_URL ?? '/'}> <img
        src={backIcon}
        width="20"
        height="20"
        className="d-inline-block align-top"
        alt="Rode Logo"

      />  <img
        src={roedeLogo}
        width="135"
        height="30"
        className="d-inline-block align-top"
        alt="Rode Logo"
      /> </Navbar.Brand>
	  <Nav>
		<Nav.Link as={Link} eventKey="2" to='/'>Assistent</Nav.Link>
		</Nav>

		<Navbar.Toggle aria-controls="basic-navbar-nav" />
		{user.isAuthenticated() && (
		<Navbar.Collapse id="basic-navbar-nav">

		<Nav className="me-auto" activeKey={basename}>
		<Nav.Link as={Link} eventKey="2" to={`${routepath.weight_book_new}`}>Vektbok</Nav.Link>

			<NavDropdown title="Lokale Kurs" id="basic-nav-dropdown">
				<NavDropdown.Item as={Link} eventKey="21" to={`/nye-kursdeltakere`}>Medlemmer</NavDropdown.Item>
				<NavDropdown.Item as={Link} eventKey="22" to={`${routepath.calendar}`}>Ukekalender</NavDropdown.Item>
				<NavDropdown.Item as={Link} eventKey="22" to={`${routepath.courses_calendar}`}>Kurskalender</NavDropdown.Item>
				<NavDropdown.Item as={Link} eventKey="23" to={`/course-planner`}>Planlegger</NavDropdown.Item>
				<NavDropdown.Header as="div">Gamle Kurs (o2)</NavDropdown.Header>
 				<NavDropdown.Item as={Link} eventKey="24" to={`${routepath.participants}`}>[o2] Kursdeltakere</NavDropdown.Item> 				
 				<NavDropdown.Item as={Link} eventKey="25" to={`${routepath.sales_overview}`}>[o2] Min salgsoversikt</NavDropdown.Item>
			</NavDropdown>
			<NavDropdown title="Nettkurs" id="basic-nav-dropdown">
				<NavDropdown.Item as={Link} eventKey="31" to={`/nettkursukerapport/`}>Veiledninger</NavDropdown.Item>
				<NavDropdown.Item as={Link} eventKey="32" to={`/nettcourse-participants`}>Medlemmer</NavDropdown.Item>
			</NavDropdown>
			<NavDropdown title="Verktøy" id="basic-nav-dropdown">
			<NavDropdown.Header>Økonomi</NavDropdown.Header>
				<NavDropdown.Item as={Link} eventKey="41" to={`/provisjonsrapport`}>Provisjonsrapport</NavDropdown.Item>
				<NavDropdown.Item as={Link} eventKey="42" to={`/salgsstatistikk`}>Salgsstatistikk</NavDropdown.Item>
				<NavDropdown.Divider />
				<NavDropdown.Item as={Link} eventKey="43" to={`/filer-for-kursdeltakere`}>Filer</NavDropdown.Item>
			</NavDropdown>



		</Nav>
		{user.isAuthenticated() &&
		<>
		<Nav>
			 
			
			
			<NavDropdown  title={asInstructor? `Logget inn som ${asInstructor.instructor_name}` : user.user.firstName} id="user-dropdown" className="user-dropdown-menu">
				<NavDropdown.Item  href="/u">Dashboard</NavDropdown.Item>
				<NavDropdown.Divider />
				<NavDropdown.Item  href="https://www.roede.com/u/verktoy/" target="_blank">Verktøykassen</NavDropdown.Item>
				<NavDropdown.Divider />
				{asInstructor?
				<NavDropdown.Item  href={`https://hq.roede.com/hq/instructors/${asInstructor.instructor_id}`}><i className="icon-outside"></i> Tilbake til hq</NavDropdown.Item>:

				<NavDropdown.Item  href="/api/logout"><i className="icon-outside"></i> Logg ut</NavDropdown.Item>
				}

			</NavDropdown>

</Nav></>	}
		</Navbar.Collapse>
		)}
	</Navbar> );

}

export default PageHeader;
