const sanitizeConf = {
  allowedTags: ["b", "i", "em", "strong", "a", "p", "h1", "br"],
  allowedAttributes: { a: ["href"] }
};

const motivations = {
  health: "Overskudd - Føl deg bedre, kvikkere, friskere",
  keep: "Hold vekten - For deg som nylig har gått ned i vekt",
  lose: "Ned i vekt - Få nye vaner som varer",
};

const activityLevels = {
  1.3: "Lav: Inaktiv hverdag. Under 5000 skritt per dag.",
  1.4: "Moderat: Noe aktivitet. 5000-8000 skritt per dag.",
  1.5: "Høy: Aktiv hverdag. Over 8000 skritt per dag.",
};

const exerciseHabitsData = {
  2: "Svært lav (0-1 t/uke)",
  5: "Lavt (2-3 t/uke)",
  10: "Moderat (3-5 t/uke)",
  15: "Høyt (6-8 t/uke)",
  20: "Svært høyt (mer enn 9 t/uke)",
};

const genders = {
  male: "Mann",
  female: "Kvinne",
}

const allergiesMap = {
  isAllergicToEgg: "Egg",
  isAllergicToFish: "Fisk",
  isAllergicToGluten: "Gluten",
  isAllergicToMilk: "Melk",
  isAllergicToLactose: "Laktoseintolerant",
  isAllergicToNut: "Nøtter",
  isAllergicToShellFish: "Skalldyr",
};

const healthProblemsMap = {
  hasDiabetes: "Diabetes",
  hasHighCholesterol: "Høyt kolesterol",
  hasHypertension: "Høyt blodtrykk",
  hasHypothyroidism: "Lavt stoffskifte",
  isBreastFeeding: "Ammende",
  isPregnant: "Gravid",
};

const mapSpecialCase = {
  'allergiesMap': {
    'isAllergicToOther': 'otherAllergies'
  },
  'healthProblemsMap': {
    'hasOtherChallenges': 'otherChallenges'
  }
}

const dataMap = {
  'allergiesMap': allergiesMap,
  'healthProblemsMap': healthProblemsMap
}

function parseJsonListToString(value, type) {
  const data = JSON.parse(value) || {};
  const parseMap = type in dataMap ? dataMap[type] : {}
  const parseSpecialMap = type in mapSpecialCase ? mapSpecialCase[type] : {}

  const dataList = Object.keys(parseMap).filter(key => data[key]).map(key => parseMap[key]);
  const dataList2 = Object.keys(parseSpecialMap).filter(key => data[key]).map(key => data[parseSpecialMap[key]]);
  const final = [...dataList, ...dataList2]

  return final.join(", ");
}

function calculateBmi(height, weight) {
  return Math.pow(height, 2) > 0 && parseFloat(weight / Math.pow(height/100, 2)).toFixed(1);
}

const dayOfWeek = {
  1: "Mandag",
  2: "Tirsdag",
  3: "Onsdag",
  4: "Torsdag",
  5: "Fredag",
  6: "Lørdag",
  7: "Søndag",
};

export {
  sanitizeConf,
  motivations,
  activityLevels,
  exerciseHabitsData,
  genders,
  parseJsonListToString,
  calculateBmi,
  dayOfWeek
}
