import React from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment'

const WeekNavigation = ({ url, date, ...props }) => {

  const currentWeek = moment(date)
  const previousWeek = moment(date).subtract(1, 'week')
  const nextWeek = moment(date).add(1, 'week')

  return (
    <nav className="wb-dateNav">
      <Link className="prev" to={`/${url}/${previousWeek.format('YYYY-MM-DD')}/`}>
        <i className="icon-arrow-2-left"></i>
      </Link>
      <strong>Uke {currentWeek.format('W')}</strong>
      <span>
        ({currentWeek.startOf('isoWeek').format('DD.MM.YYYY')} -
        {currentWeek.endOf('isoWeek').format('DD.MM.YYYY')})
      </span>
      <Link className="next" to={`/${url}/${nextWeek.format('YYYY-MM-DD')}/`}>
        <i className="icon-arrow-2-right"></i>
      </Link>
    </nav>
  )
}

export default WeekNavigation;
