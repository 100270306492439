import { gql } from "apollo-boost";

const GET_COURSE_MEETINGS_BY_ID = gql`
query Assistant($courseId: Int!, $meetingId: Int!) {
  request {
    grassistant {
      assistantCourseMeeting(courseId: $courseId, meetingId: $meetingId) {
        edges {
          node {
            courseMeetingId

            startTime
            endTime
            instructor {
              name
              instructorId
            }
            roedeCourse {
              name
              schedulingAlgorithm
              numberOfSpots
              maxNumParticipants
              courseId
            }
            attendanceList {
              id
              name
              membershipId
              status
              userId
              phone
              membership {
                paymentSource
              }              
            }
          }
        }
      }
    }
  }
}
`

export default GET_COURSE_MEETINGS_BY_ID;
