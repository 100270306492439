import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment'

const CourseMeetingsItem = ({
  obj,
  meetingsList,
  selectMeeting,
  ...props
}) => {
  return <div className="roede-course-item course-meeting-item">
      {
        <input
          type="checkbox"
          className="userCheckbox regularCheckbox"
          onClick={(e)=>selectMeeting(obj.id)}
          data-id={obj.id}
          data-course-id={obj.courseId}
          checked={meetingsList.filter((x) => (x == obj.id)).length > 0}
          onChange={() => function() {}}
        />
      }
      <Link to={`/course-details/${obj.courseId}/course-meeting/${obj.id}`}>
        <div className="roede-course-name">{obj.name}</div>
      </Link>
      <div className="roede-course-location">
        <div className="roede-course-calendar-item roede-course-instructor">{obj.instructorName}</div>
        <div className="roede-course-calendar-item roede-course-time">{`${moment(obj.startTime).format('HH:mm')}-${moment(obj.endTime).format('HH:mm')}`}</div>
        <div className="roede-course-calendar-item roede-course-free-spots">Attendance: {obj.attendance}</div>
      </div>
  </div>
}


export default CourseMeetingsItem;
