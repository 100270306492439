import React, { Fragment, useState } from 'react';

import {
  getWeeks
} from '../../utils/GetCacheQuery'

const WeightDrilldownHeader = ({
  sortOrder,
  setSort,
  match,
  ...props
}) => {
    const weeks = getWeeks(match.params.date)

    return <thead>
      <tr>
        <th>Navn</th>
        <th><span data-name="totalEatenKcal" data-sort={sortOrder.totalEatenKcal} data-active={Object.keys(sortOrder)[0] === 'totalEatenKcal'} onClick={() => setSort('totalEatenKcal')}>Kcal</span></th>
        <th><span data-name="dietScore" data-sort={sortOrder.dietScore} data-active={Object.keys(sortOrder)[0] === 'dietScore'} onClick={() => setSort('dietScore')}>KSC</span></th>
        <th><span data-name="trainingPoints" data-sort={sortOrder.trainingPoints} data-active={Object.keys(sortOrder)[0] === 'trainingPoints'} onClick={() => setSort('trainingPoints')}>TP</span></th>
        <th><span data-name="voucherMeals" data-sort={sortOrder.voucherMeals} data-active={Object.keys(sortOrder)[0] === 'voucherMeals'} onClick={() => setSort('voucherMeals')}>KM</span></th>
        <th><span data-name="waistCircumference" data-sort={sortOrder.waistCircumference} data-active={Object.keys(sortOrder)[0] === 'waistCircumference'} onClick={() => setSort('waistCircumference')}>cm</span></th>
        <th><span data-name="kcalMenu" data-sort={sortOrder.kcalMenu} data-active={Object.keys(sortOrder)[0] === 'kcalMenu'} onClick={() => setSort('kcalMenu')}>Meny</span></th>
        <th colSpan="8">Siste veiinger</th>
        <th><span data-name="weightDeltaWeek" data-sort={sortOrder.weightDeltaWeek} data-active={Object.keys(sortOrder)[0] === 'weightDeltaWeek'} onClick={() => setSort('weightDeltaWeek')}>Uke</span></th>
        <th><span data-name="weightDeltaCourse" data-sort={sortOrder.weightDeltaCourse} data-active={Object.keys(sortOrder)[0] === 'weightDeltaCourse'} onClick={() => setSort('weightDeltaCourse')}>Kurs</span></th>
        <th><span data-name="weightDeltaTotal" data-sort={sortOrder.weightDeltaTotal} data-active={Object.keys(sortOrder)[0] === 'weightDeltaTotal'} onClick={() => setSort('weightDeltaTotal')}>Totalt</span></th>
      </tr>
      <tr>
        <th>&nbsp;</th>
        <th colSpan="6">&nbsp;</th>
        {weeks.map(week => (
          <th key={week}>Uke {week}</th>
        ))}
        <th colSpan="3">&nbsp;</th>
      </tr>
    </thead>

}

export default WeightDrilldownHeader;
