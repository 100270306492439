import { gql } from "apollo-boost";

const NETTCOURSE_USER_SEARCH = gql`
	query Assistant($search: String, $status: String) {
        request {
            grassistant {
                assistantNettcourseUserSearch(search: $search, status: $status)
            }
        }
	}
`

export default NETTCOURSE_USER_SEARCH;
