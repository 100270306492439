import { useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
var _ = require('underscore');

import Loading from '../atom/Loading';
import ParticipantItem from '../atom/ParticipantItem';
import WeightDrilldownHeader from '../atom/WeightDrilldownHeader';
import WeightStatsParticipant from '../ui/WeightStatsParticipant';

import WEIGHT_DRILLDOWN_COURSE_NEW from '../query/weight_drilldown_course_new.js';


import {
	getParticipants,
	sortParticipants,
} from '../utils/GetCacheQuery';

import {
	INITIAL_SORT_ORDER
} from '../utils/GetCacheQuery';

const PAGE="nye-vektbok";


const Breadcrumbs = ({
	courseId,
	bookCode,
	match,

}) => (
	<div className="headWrapper">
		<div className="headText">
				[NY] Detaljoversikt
		</div>
	  <nav className="breadcrumbs">
	    <ul>
	      <li><Link to="/">Hovedside</Link></li>
				<li><Link to="/course-calendar/">Mine kurs</Link></li>
				<li><Link to={`/course-details/${courseId}`}>{courseId && bookCode}</Link></li>
	      <li>[NY] Vektbok</li>
	    </ul>
	  </nav>
	</div>
)

const WeightDrilldownCourseNew = ({
	match,
	...props
}) => {

		const {
			date=moment().format('YYYY-MM-DD'),
			courseId
		} = match.params

		const [sortOrder, setSortOrder] = useState(INITIAL_SORT_ORDER)

		const setSort = field => {
			const sortMe = {
				[field]: null,
				...sortOrder,
				[field]: (sortOrder[field] === 'asc') ? 'desc' : 'asc',
			}

			setSortOrder(sortMe);
		}

		var variables = {
			courseId: courseId,
      cacheDate: moment(date).format('YYYY-MM-DD'),
      summaryDateTime: moment(date).format('YYYY-MM-DDT23:59:59'),
      category: "courseType",
      currentDateTimeFrom: moment(date).startOf('week').add(-7, 'weeks').format('YYYY-MM-DDT00:00:00'),
      currentDateTimeTo: moment(date).format('YYYY-MM-DDT23:59:59'),
		}

		const {
			loading,
			fetchMore,
			data: {
				assistant: {
					assistantCourseItem: {
						courseId: course_id,
						bookCode,
						memberTotal: total=0
					}={},
					assistantUserList: {
						pageInfo: {
							hasNextPage: hasMore,
							endCursor
						}={},
						edges
					}={}
				}={}
			} = {}
		} = useQuery(WEIGHT_DRILLDOWN_COURSE_NEW, {
			variables: variables
		});

		const loadMoreEntries = () => {
			variables['end'] = endCursor

			fetchMore({
				query: WEIGHT_DRILLDOWN_COURSE_NEW,
				variables: variables,
				updateQuery: (previousResult, { fetchMoreResult }) => {
					if (!fetchMoreResult) { return previousResult; }

					const prevRequest = previousResult.assistant;
					const prevAssistantUserList = previousResult.assistant.assistantUserList;
					const prevEdge = previousResult.assistant.assistantUserList.edges;
					const nextEdge = fetchMoreResult.assistant.assistantUserList.edges;
					const pageInfo = fetchMoreResult.assistant.assistantUserList.pageInfo;

					return {
						assistant: {
							...prevRequest,
							assistantUserList: {
								__typename: fetchMoreResult.assistant.assistantUserList.__typename,
								edges: [...prevEdge, ...nextEdge],
								pageInfo,
							}
						}
					}
				}
			})
		}


    return <div className="pageContainer weightBook">
			<div className="pageHeadWrapper">
				<Breadcrumbs courseId={courseId} bookCode={bookCode ? bookCode : "Kurs"}/>
			</div>
			<div className="wbm">
				<div className="wbm-count">{total} deltakare</div>
				<div id="scrollableDiv" className="participantsListWrapper participantsItem">
					<table>
						<WeightDrilldownHeader sortOrder={sortOrder} setSort={setSort} match={match}/>
						<InfiniteScroll
							loadMore={() => loadMoreEntries()}
							hasMore={hasMore}
							loader={<tr key="0"><td colSpan="7"><Loading/></td><td colSpan="9"></td></tr>}
							element="tbody"
						>
							{loading ? <tr key="0"><td colSpan="7"><Loading/></td><td colSpan="9"></td></tr> : sortParticipants(getParticipants(edges), sortOrder).map((participant, index) =>
									<ParticipantItem participant={participant} key={index} index={index}/>
							)}
						</InfiniteScroll>
					</table>
				</div>
				<WeightStatsParticipant match={match} page={PAGE}/>
			</div>
		</div>
}


export default WeightDrilldownCourseNew;
